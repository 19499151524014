import { Radio } from "antd";
import {
  useCurrentLocale,
  useCurrentUser,
  useImportCreate,
  useImportKinds,
  useImportModules,
  useLocaleOptions,
} from "../../../lib/hooks";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { Form, Rules } from "../../form";
import { SidebarHeader } from "../Sidebar/Header";
import { ItemForm } from "../ListView/ItemForm";
import { ImportKindType, ImportModulesType } from "../../../lib/graphql";
import routes, { routerPush } from "../../../lib/routes";
import { useCallback } from "react";
import { LocalityFormSelect } from "../../agro";

interface ImportFormValues {
  moduleName?: ImportModulesType;
  kind: ImportKindType;
  localeCode: string;
  localityIds?: string[];
}

export interface ImportSidebarFormProps {
  moduleName?: ImportModulesType;
  onClose(): void;
}

const messages = defineMessages({
  newSuccess: { id: "new.success", defaultMessage: "success" },
  import: { id: "import", defaultMessage: "import" },
  title: { id: "imports.new", defaultMessage: "imports.new" },
  moduleName: { id: "imports.moduleName", defaultMessage: "moduleName" },
  moduleNameHint: {
    id: "imports.moduleNameHint",
    defaultMessage: "imports.moduleNameHint",
  },
  importKindHint: {
    id: "imports.kindHint",
    defaultMessage: "imports.kindHint",
  },
});

export function ImportSidebarForm({
  moduleName,
  onClose,
}: ImportSidebarFormProps) {
  const [create] = useImportCreate({ refetchQueries: ["Imports"] });
  const { moduleOptions } = useImportModules();
  const { kindOptions } = useImportKinds();
  const intl = useIntl();
  const { currentLocale } = useCurrentLocale();
  const { currentLocalityIds } = useCurrentUser();

  const [form] = Form.useForm();

  const initialValues: ImportFormValues = {
    moduleName,
    kind: ImportKindType.Create,
    localeCode: currentLocale,
    localityIds: currentLocalityIds,
  };

  const onSave = useCallback(
    (fields: ImportFormValues) =>
      create({
        variables: {
          // @ts-expect-error mismatch between initial fields and required
          fields,
        },
      }).then((result) => {
        const importId = result?.data?.importCreate?.result?.id;

        if (!importId) return null;

        onClose();

        routerPush(routes.imports.details(importId));
      }),
    [create, onClose]
  );

  return (
    <>
      <SidebarHeader
        title={intl.formatMessage(messages.title)}
        onClose={onClose}
      />

      <ItemForm
        formProps={{ form }}
        initialValues={initialValues}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        onSave={onSave}
        fields={[
          {
            label: intl.formatMessage(messages.moduleName),
            name: "moduleName",
            type: "select",
            options: moduleOptions,
            hidden: !!moduleName,
            rules: [Rules.required],
            showGroups: true,
            formItemProps: {
              extra: intl.formatMessage(messages.moduleNameHint),
            },
          },
          {
            label: <FormattedMessage id="language" defaultMessage="language" />,
            rules: [Rules.required],
            name: "localeCode",
            type: "select",
            optionsHook: useLocaleOptions,
            inputProps: { allowClear: false },
          },
          {
            type: "custom",
            rules: [Rules.required],
            render: () => (
              <Form.Item
                name="kind"
                key="kind"
                label={<FormattedMessage id="imports.kind" />}
                extra={intl.formatMessage(messages.importKindHint)}
                required
              >
                <Radio.Group
                  options={kindOptions.map(({ key, label }) => ({
                    label,
                    value: key,
                  }))}
                />
              </Form.Item>
            ),
          },
          {
            type: "custom",
            render: () => (
              <Form.Item noStyle dependencies={["kind", "moduleName"]}>
                {({ getFieldValue }) => {
                  if (getFieldValue("kind") === ImportKindType.Create)
                    return null;

                  const module = moduleOptions.find(
                    (m) => m.key === getFieldValue("moduleName")
                  );

                  if (!module?.locality) return null;

                  return (
                    <LocalityFormSelect
                      formItemProps={{
                        name: "localityIds",
                        rules: [Rules.required],
                      }}
                      placeholder={undefined}
                    />
                  );
                }}
              </Form.Item>
            ),
          },
        ]}
      />
    </>
  );
}
