import { sumBy } from "lodash";
import dayjs from "dayjs";
import { roundUnit } from "../../../../lib/formats";
import {
  EmployeeGroupShortFragment,
  LeaveTypeFragment,
  PositionType,
  WorkOrderCostCenterFragment,
  WorkOrderEmployeeFragment,
  DestroyableEntityType,
} from "../../../../lib/graphql";
import { WorkOrderBaseBuilder } from "./base";
import { EmployeeProgress } from "./progress/employeeProgress";
import { WorkOrderBuilder } from ".";
import { EmployeeMetricBuilder } from "./metrics/employeeMetrics";

export interface WorkOrderEmployee
  extends DestroyableEntityType<WorkOrderEmployeeFragment> {
  totalProgress?: number;
  unlocked?: boolean;
  originalEmployeeGroup?: EmployeeGroupShortFragment | null;
  assignedCount?: number;
}

export class WorkOrderEmployeeBuilder extends WorkOrderBaseBuilder<WorkOrderEmployee> {
  progress: EmployeeProgress;
  metrics: EmployeeMetricBuilder;
  defaultStartTime = dayjs(this.workOrder.documentDate, "H");

  constructor(builder: WorkOrderBuilder) {
    super(builder);

    this.progress = new EmployeeProgress(builder);
    this.metrics = new EmployeeMetricBuilder(builder);
  }

  protected get field() {
    return "employees";
  }

  sorter(a: WorkOrderEmployee, b: WorkOrderEmployee) {
    return this.nameSorter(a, b) + this.sortIndex(b) - this.sortIndex(a);
  }

  sortIndex(employee: WorkOrderEmployeeFragment) {
    return this.isAbsent(employee) ? -2 : 0;
  }

  nameSorter(a: WorkOrderEmployee, b: WorkOrderEmployee) {
    return (a.employee.firstName + a.employee.lastName).localeCompare(
      b.employee.firstName + b.employee.lastName
    );
  }

  internalIdSorter(a: WorkOrderEmployee, b: WorkOrderEmployee) {
    return (a.employee.internalId || "").localeCompare(
      b.employee.internalId || ""
    );
  }

  get hasInternalIds() {
    return this.get().some((e) => e.employee.internalId);
  }

  getByEmployeeId(employeeId: string) {
    return this.get().find((e) => e.employee.id === employeeId);
  }

  getCostCenterEmployee(
    costCenter: WorkOrderCostCenterFragment,
    employee: WorkOrderEmployeeFragment
  ) {
    return costCenter.employees.find(
      (cce) => cce.employeeId === employee.employee.id
    );
  }

  getCostCenterEmployeeIndex(
    costCenter: WorkOrderCostCenterFragment,
    employee: WorkOrderEmployeeFragment
  ): number {
    return costCenter.employees.findIndex(
      (cce) => cce.employeeId === employee.employee.id
    );
  }

  getHours() {
    const employees = this.get();
    return sumBy(employees, (e) => e.hours);
  }

  getOvertimeHours() {
    const employees = this.get();
    return sumBy(employees, (e) => e.overtimeHours);
  }

  getTotalHours() {
    const employees = this.get();
    return roundUnit(sumBy(employees, (e) => e.hours + e.overtimeHours));
  }

  getEmployeeTotalHours(index: number) {
    const employee = this.getBy(index);
    return employee.hours + employee.overtimeHours;
  }

  getTotalProgress() {
    const employees = this.get();
    return sumBy(employees, (e) => e.totalProgress || 0);
  }

  get shouldHaveProgress() {
    return (
      !!this.workOrder.employees.find((e) => !this.isLocked(e)) ||
      (this.builder.workerCountMode && !this.builder.isHarvest)
    );
  }

  init() {
    if (!this.builder.isReadonly) {
      this.initEmployeeAttendance();
    }

    this.initEmployeeMetrics();
    this.initAssignments();
  }

  initEmployeeAttendance() {
    const employees = this.get();

    employees.forEach((e) => {
      if (e.originalEmployeeGroup === undefined) {
        e.originalEmployeeGroup = e.employeeGroup;
      }

      // for uninitialized employees
      if (!e.leaveType) {
        this.initLeaveType(e);
        if (!e.endTime) this.resetAttendance(e);
      }

      if (this.isAbsent(e)) {
        e.hours = e.overtimeHours = 0;
        e.endTime = null;
      }

      if (!this.allowProgress(e) && this.hasProgress(e)) e.unlocked = true;
    });
  }

  initEmployeeMetrics() {
    this.metrics.initMetricFields();

    if (this.builder.isMachinery) return;

    this.progress.initTotalProgress(this.get());
  }

  initAssignments() {
    const employees = this.get(false);
    const costCenters = this.getCostCenters();

    employees.forEach((e, index) => {
      const count = costCenters.filter((cc) =>
        this.builder.employees.progress.isAssigned(cc, e)
      ).length;

      this.form.setFieldValue(["employees", index, "assignedCount"], count);
    });
  }

  onDocumentDateChanged() {
    const employees = this.get();

    employees.forEach((e) => {
      e.startTime = this.form.getFieldValue("documentDate");
    });
  }

  onRemove(employee: WorkOrderEmployeeFragment) {
    super.onRemove(employee);

    this.resetAttendance(employee, true);

    const costCenters = this.getCostCenters();
    costCenters.forEach(({ employees }) => {
      const index = employees.findIndex(
        (e) => e.employeeId === employee.employee.id
      );
      if (index >= 0) {
        if (employees[index].id) employees[index]._destroy = true;
        else employees.splice(index, 1);
      }
    });

    this.progress.deboucedOnWorkerCountChanged();
  }

  initLeaveType(employee: WorkOrderEmployee) {
    employee.leaveType = { id: "" } as LeaveTypeFragment;
  }

  resetAttendance(employee: WorkOrderEmployee, updateState?: boolean) {
    employee.hours = this.workOrder.activity.workdayHours;
    employee.overtimeHours = 0;
    employee.startTime =
      employee.startTime || dayjs(this.workOrder.documentDate);
    employee.employeeGroup = employee.originalEmployeeGroup;
    this.initLeaveType(employee);
    this.calculateEmployeeEndTime(employee);

    if (updateState) {
      const index = this.get(false).findIndex(
        (e) => e.employee.id === employee.employee.id
      );
      this.form.setFields([{ name: [this.field, index], value: employee }]);
    }
  }

  isAbsent(employee: WorkOrderEmployeeFragment) {
    return !employee.attended;
  }

  isRelocked(employee: WorkOrderEmployeeFragment & { unlocked?: boolean }) {
    return employee.unlocked === false;
  }

  allowProgress(employee: WorkOrderEmployeeFragment) {
    return employee.employee.position.positionType === PositionType.Labor;
  }

  hasProgress(
    employee: WorkOrderEmployeeFragment & { totalProgress?: number }
  ) {
    return employee.totalProgress && employee.totalProgress > 0;
  }

  isUnlocked(employee: WorkOrderEmployee) {
    return employee.unlocked === true;
  }

  isLocked(employee: WorkOrderEmployeeFragment) {
    return (
      !this.isUnlocked(employee) &&
      !this.allowProgress(employee) &&
      !this.hasProgress(employee)
    );
  }

  canSetProgress(employee: WorkOrderEmployee) {
    return !this.isAbsent(employee) && !this.isLocked(employee);
  }

  onEmployeeAttendanceChanged(index: number) {
    const employee = this.get(false)[index];
    const nonWorker = this.isAbsent(employee) || this.isRelocked(employee);

    if (nonWorker) {
      this.progress.resetProgress(employee);
    } else {
      this.resetAttendance(employee, true);
    }

    this.progress.deboucedOnWorkerCountChanged();
  }

  recalculateEndTime(index: number) {
    const employee = this.get(false)[index];
    const value = this.calculateEmployeeEndTime(employee);

    this.form.setFields([{ name: [this.field, index, "endTime"], value }]);
  }

  recalculateEndTimes() {
    const employees = this.get();
    employees.forEach((employee, index) =>
      this.form.setFields([
        {
          name: [this.field, index, "endTime"],
          value: this.calculateEmployeeEndTime(employee),
        },
      ])
    );
  }

  get workerCount() {
    return this.form.getFieldValue("workerCount");
  }

  get isPiecework() {
    return this.form.getFieldValue("isPiecework");
  }

  get useCostCenterProgress() {
    if (this.builder.isMachinery) return false;
    if (this.builder.tokens.hasItems) return false;

    return !this.get().find((e) => !this.isLocked(e));
  }

  calculateWorkerCost() {
    const wage = this.form.getFieldValue("workerWage");

    if (!this.workerCount || !wage) return;

    const workerCost = this.isPiecework
      ? wage * this.builder.costCenters.getTotalProgress()
      : this.workerCount * wage;

    this.form.setFieldValue("workerCost", roundUnit(workerCost));
  }

  calculateWage() {
    const workerCost = this.form.getFieldValue("workerCost");

    if (!this.workerCount || !workerCost) return;

    const wage = workerCost / this.workerCount;
    this.form.setFieldValue("workerWage", roundUnit(wage));
  }

  resetWorkerCount() {
    this.form.setFields([
      {
        name: "workerCost",
        value: null,
      },
      {
        name: "workerWage",
        value: null,
      },
      {
        name: "workerCount",
        value: null,
      },
    ]);
  }

  private calculateEmployeeEndTime(employee: WorkOrderEmployee) {
    return (employee.endTime = this.builder.isOpen
      ? null
      : dayjs(employee.startTime).add(
          employee.hours + employee.overtimeHours,
          "h"
        ));
  }
}
