import enMessages from "./en.json";
import esMessages from "./es.json";

import dynamicEnMessages from "./dynamic_en.json";
import dynamicEsMessages from "./dynamic_es.json";

require("dayjs/locale/es");

import enUS from "antd/lib/locale-provider/en_US";
import esES from "antd/lib/locale-provider/es_ES";

const messages = {
  en: { messages: { ...enMessages, ...dynamicEnMessages }, ant: enUS },
  es: { messages: { ...esMessages, ...dynamicEsMessages }, ant: esES },
};

export type AvailableLocales = keyof typeof messages;

const Config = (locale: keyof typeof messages) => {
  return {
    locale,
    ...messages[locale],
  };
};

export default Config;
