import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmSidebar } from ".";
import { SaveIDFunction, WithIDType } from "../../../lib/graphql";

interface RevertSidebarProps {
  entity: WithIDType;
  entityName: ReactNode;
  closeSidebar: () => void;
  revert: () => SaveIDFunction;
}

export function RevertSidebar({
  entity,
  entityName,
  closeSidebar,
  revert,
}: RevertSidebarProps) {
  const intl = useIntl();

  return (
    <ConfirmSidebar
      title={
        <FormattedMessage
          id="revert.header"
          defaultMessage="revert {entityName}"
          values={{ entityName }}
        />
      }
      onCancel={closeSidebar}
      okButtonProps={{ danger: true }}
      okText={<FormattedMessage id="revert" defaultMessage="revert" />}
      onOk={({ showSuccess }) =>
        revert()?.then((result) => {
          if (result && result.result) {
            closeSidebar();
            showSuccess(
              intl.formatMessage(
                { id: "revert.success" },
                {
                  entityName,
                  id: entity.id,
                }
              )
            );
          }
        })
      }
    >
      <FormattedMessage id="revert.confirm" defaultMessage="confirm" />
    </ConfirmSidebar>
  );
}
