import { CSSProperties, ReactNode } from "react";
import { Space, Tooltip } from "antd";
import Link from "next/link";
import Breadcrumb, { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { useCurrentUser } from "../../../lib/hooks";
import { useIntl, defineMessages } from "react-intl";
import { formatTenantName } from "../../../lib/formats";
import { QuestionCircleOutlined } from "@ant-design/icons";

export interface ContentHeaderProps {
  style?: CSSProperties;
  title?: ReactNode;
  tooltip?: React.ReactNode;
  breadcrumbs?: Route[];
  headerContent?: ReactNode;
  afterTooltip?: ReactNode;
}

const messages = defineMessages({
  home: { id: "homeTenant", defaultMessage: "homeTenant" },
});

function breadcrumbRender(route: Route, _params: any, routes: Route[]) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span style={{ fontWeight: 600 }}>{route.breadcrumbName}</span>
  ) : (
    <Link href={route.path}>{route.breadcrumbName}</Link>
  );
}

export function ContentHeader({
  tooltip,
  breadcrumbs,
  headerContent,
  afterTooltip,
  style,
}: ContentHeaderProps) {
  const intl = useIntl();
  const { user } = useCurrentUser();

  // otherwise it renders blank white div with paddings
  if (!tooltip && !breadcrumbs && !headerContent) return null;

  return (
    <div className="page-header" style={style}>
      {breadcrumbs && (
        <Space>
          <Breadcrumb
            style={{ fontSize: 16 }}
            itemRender={breadcrumbRender}
            routes={[
              {
                path: "/",
                breadcrumbName: intl.formatMessage(messages.home, {
                  tenantName: user && formatTenantName(user.currentTenant),
                }),
              },
              ...breadcrumbs,
            ]}
          />

          {tooltip && (
            <Tooltip title={tooltip}>
              <QuestionCircleOutlined style={{ opacity: 0.5 }} />
            </Tooltip>
          )}

          {afterTooltip && (
            <div style={{ marginLeft: 8, width: 300, maxWidth: 300 }}>
              {afterTooltip}
            </div>
          )}
        </Space>
      )}

      {headerContent && <div style={{ marginTop: 8 }}>{headerContent}</div>}
    </div>
  );
}
