import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";

import {
  UnitsDocument,
  UnitsQuery,
  UnitsQueryVariables,
  UnitUpdateMutation,
  UnitUpdateMutationVariables,
  UnitUpdateDocument,
  UnitCreateMutation,
  UnitCreateMutationVariables,
  UnitCreateDocument,
  UnitDiscardMutation,
  UnitDiscardMutationVariables,
  UnitDiscardDocument,
  UnitTypeDocument,
  UnitTypeQuery,
  UnitType,
  UnitUnlockMutation,
  UnitUnlockMutationVariables,
  UnitUnlockDocument,
  UnitQuery,
  UnitQueryVariables,
  UnitDocument,
  UnitFragment,
} from "../../graphql";
import { useIntl } from "react-intl";
import { useEffectOnce } from "react-use";

export function useUnitTypeFormat() {
  const intl = useIntl();
  return (type: string) => intl.formatMessage({ id: `units.types.${type}` });
}

export function allowedUnitType(type: UnitType) {
  return ![UnitType.Time, UnitType.Speed, UnitType.Temperature].includes(type);
}

export function useUnitTypeOptions(preload?: boolean) {
  const formatUnitType = useUnitTypeFormat();

  const [load, { data, loading }] =
    useLazyQuery<UnitTypeQuery>(UnitTypeDocument);

  const options =
    data?.__type?.enumValues?.map((ut) => ({
      key: ut.name,
      label: formatUnitType(ut.name),
    })) || [];

  useEffectOnce(() => {
    if (preload) load();
  });

  return { load, loading, options };
}

// Default units used for identifying commonly used units
const DEFAULT_UNITS = ["L", "kg", "ha", "km", "unit"];
const ALLOWED_UNITS = ["km", "mi", "h"];

// TODO: lazy load
export function useUnitOptions({
  types,
  primary,
  abbrLabel = false,
}: {
  types?: UnitType[];
  primary?: boolean;
  abbrLabel?: boolean;
} = {}) {
  const { data, loading } = useQuery<UnitsQuery, UnitsQueryVariables>(
    UnitsDocument,
    {
      variables: { pageSize: 100, filter: { unitType: types, primary } },
    }
  );

  const intl = useIntl();
  const units = data?.units?.items || [];

  const unitToOption = (u: UnitFragment) => ({
    key: u.id,
    label: abbrLabel ? u.abbr : `${u.name} (${u.abbr})`,
    group: intl.formatMessage({ id: `units.types.${u.unitType}` }),
    primary: u.primary,
    default: DEFAULT_UNITS.some((du) => u.abbr === du),
    type: u.unitType,
    unit: u,
  });

  const unitOptions = units.map(unitToOption);

  const unitAllowedOptions = units
    .filter((o) => ALLOWED_UNITS.includes(o.abbr))
    .map(unitToOption)
    .sort((a, b) => a.label.localeCompare(b.label));

  return { unitOptions, loadingUnitOptions: loading, unitAllowedOptions };
}

const AREA_UNIT_KEYS = ["ha", "mz", "ac", "m²", "km²", "vr"];

export function useHardcodedAreaOptions() {
  const intl = useIntl();

  return AREA_UNIT_KEYS.map((key) => {
    return {
      key,
      label: intl.formatMessage({
        id: `units.values.${key}`,
        defaultMessage: key,
      }),
    };
  });
}

export function useUnits(
  options?: QueryHookOptions<UnitsQuery, UnitsQueryVariables>
) {
  const { loading, data } = useQuery(UnitsDocument, options);

  if (!data || !data.units || !data.units.items) {
    return { loading };
  }

  const items = data.units.items;
  const totalCount = data.units.totalCount;

  return { loading, items, totalCount };
}

export function useUnit(id: string) {
  const { data } = useQuery<UnitQuery, UnitQueryVariables>(UnitDocument, {
    variables: { id },
  });

  return data && data.unit;
}

export function useUnitUpdate(
  options?: MutationHookOptions<UnitUpdateMutation, UnitUpdateMutationVariables>
) {
  return useMutation(UnitUpdateDocument, options);
}

export function useUnitCreate(
  options?: MutationHookOptions<UnitCreateMutation, UnitCreateMutationVariables>
) {
  return useMutation(UnitCreateDocument, options);
}

export function useUnitDiscard(
  options?: MutationHookOptions<
    UnitDiscardMutation,
    UnitDiscardMutationVariables
  >
) {
  return useMutation(UnitDiscardDocument, options);
}

export function useUnitUnlock(
  options?: MutationHookOptions<UnitUnlockMutation, UnitUnlockMutationVariables>
) {
  return useMutation(UnitUnlockDocument, options);
}
