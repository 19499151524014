import { Maybe } from "../graphql";
import { AvailableLocales } from "./config";

export type { AvailableLocales };

export function localizedFieldName(
  name: string,
  locale: AvailableLocales
): any {
  return `${name}${locale.charAt(0).toUpperCase() + locale.slice(1)}`;
}

export function localeNameField(locale: AvailableLocales) {
  return locale === "es" ? "nameEs" : "nameEn";
}

export function localeCommonNamesField(locale: AvailableLocales) {
  return locale === "es" ? "commonNamesEs" : "commonNamesEn";
}

export function localeAbbrField(locale: AvailableLocales) {
  return locale === "es" ? "abbrEs" : "abbrEn";
}

export function localeNames({
  nameEn,
  nameEs,
}: {
  nameEn?: Maybe<string>;
  nameEs?: Maybe<string>;
}) {
  return { nameEn, nameEs };
}

export function localeAbbrs({
  abbrEn,
  abbrEs,
}: {
  abbrEn?: Maybe<string>;
  abbrEs?: Maybe<string>;
}) {
  return { abbrEn, abbrEs };
}

export function localeCommonNames({
  commonNamesEn,
  commonNamesEs,
}: {
  commonNamesEn?: Maybe<string[]>;
  commonNamesEs?: Maybe<string[]>;
}) {
  return { commonNamesEn, commonNamesEs };
}

export function localeDescriptions({
  descriptionEn,
  descriptionEs,
}: {
  descriptionEn?: Maybe<string>;
  descriptionEs?: Maybe<string>;
}) {
  return { descriptionEn, descriptionEs };
}
