import {
  useEffect,
  useState,
  ReactNode,
  useContext,
  useCallback,
  Key,
} from "react";
import { useCurrentLocale, useCurrentUser } from "../../../lib/hooks";
import { WithIDType } from "../../../lib/graphql";
import { Tree } from "antd";
import { TreeNodeNormal, TreeProps } from "antd/lib/tree/Tree";
import {
  ItemSidebarProps,
  ItemSidebarContext,
  ItemSidebar,
} from "../ItemSidebar";
import { EventDataNode } from "antd/lib/tree";

interface TreeViewProps<T> extends Omit<TreeProps, "onDrop" | "draggable"> {
  items?: Array<T>;
  refetch(
    nodeKey: string | null
  ): Promise<Array<T> | null | undefined> | undefined;
  treeViewItem(item: T): TreeViewItem<T>;
  onDrop?(id: string, parentId: string | null): Promise<any>;
  sidebarActions: Record<string, (props: ItemSidebarProps<T>) => ReactNode>;
  expandAllParents?: boolean;
  draggable?(node: TreeViewItem<T>): boolean;
  reloadKey?: string;
}

interface TreeViewItem<T> extends TreeNodeNormal {
  key: Key;
  item?: T;
  parentId?: string | null;
  className?: string;
  treeViewItem?: TreeViewItem<T>;
}

export function TreeView<T extends WithIDType>({
  items,
  refetch,
  treeViewItem,
  sidebarActions,
  onDrop,
  expandAllParents,
  draggable,
  reloadKey,
  ...treeProps
}: TreeViewProps<T>) {
  const [treeItems, setTreeItems] = useState<TreeViewItem<T>[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Array<string>>([]);
  const [loadedKeys, setLoadedKeys] = useState<Array<string>>([]);
  const { currentTenant } = useCurrentUser();
  const { currentLocale } = useCurrentLocale();

  const [currentItem, setCurrentItem] = useState<T>({} as T);
  const { setCurrentAction } = useContext(ItemSidebarContext);

  const treeViewItemSelf = useCallback(
    (item: T) => {
      const tvi = treeViewItem(item);
      tvi.treeViewItem = tvi;
      return tvi;
    },
    [treeViewItem]
  );

  if (items && !treeItems) {
    setTreeItems(items.map(treeViewItemSelf));
    if (expandAllParents) {
      setExpandedKeys(items.map((i) => i.id));
    }
  }

  useEffect(() => {
    refetch(null)?.then((newItems) => {
      if (newItems) setTreeItems(newItems.map(treeViewItemSelf));
      setExpandedKeys(
        expandAllParents && newItems ? newItems.map((i) => i.id) : []
      );
      setLoadedKeys([]);
    });
  }, [
    currentTenant,
    currentLocale,
    expandAllParents,
    refetch,
    treeViewItemSelf,
  ]);

  useEffect(() => {
    setLoadedKeys((keys) => keys.filter((k) => k != reloadKey));
  }, [reloadKey]);

  const loadTreeItems = useCallback(
    (node: EventDataNode<TreeViewItem<T>>) =>
      new Promise<void>((resolve) => {
        const parentKey = node.key.toString();
        if (node.isLeaf || (parentKey && loadedKeys.includes(parentKey))) {
          resolve();
          return;
        }

        if (parentKey) {
          refetch(parentKey)?.then((newItems) => {
            if (newItems && node.treeViewItem)
              node.treeViewItem.children = newItems.map(treeViewItemSelf);

            setLoadedKeys([...loadedKeys, parentKey]);
            if (treeItems) setTreeItems([...treeItems]);
            resolve();
          });
        }
      }),
    [loadedKeys, refetch, treeItems, treeViewItemSelf]
  );

  const refreshNode = useCallback(
    (
      treeItem: TreeViewItem<T> | null
    ): Promise<TreeViewItem<T>[] | null> | undefined =>
      refetch(treeItem ? treeItem.key.toString() : null)?.then((newItems) => {
        if (newItems) {
          const newTreeItems = newItems.map(treeViewItemSelf);
          if (treeItem) treeItem.children = newTreeItems;

          return Promise.all(
            newTreeItems
              .filter((i) => expandedKeys.includes(i.key.toString()))
              .map(refreshNode)
          ).then(() => newTreeItems);
        }

        return null;
      }),
    [expandedKeys, refetch, treeViewItemSelf]
  );

  const refreshAll = useCallback(
    () =>
      refreshNode(null)?.then((newItems) => {
        if (newItems) setTreeItems(newItems);
      }),
    [refreshNode]
  );

  const onExpand = useCallback((keys: any) => {
    setExpandedKeys(keys.map((k: Key) => k.toString()));
  }, []);

  const onDragEnter = useCallback(
    (info: any) => {
      loadTreeItems(info.node).then(() => {
        setExpandedKeys(info.expandedKeys.map((k: Key) => k.toString()));
      });
    },
    [loadTreeItems]
  );

  const onDropCallback = useCallback(
    (opts: any) => {
      if (onDrop) {
        const dragNode = opts.dragNode as TreeViewItem<T>;
        const node = opts.node as TreeViewItem<T>;

        const what = dragNode.key.toString();
        const where =
          opts.dropToGap && node.parentId ? node.parentId : node.key.toString();
        if (what) onDrop(what, where).then(refreshAll);
      }
    },
    [onDrop, refreshAll]
  );

  const onSelect = useCallback(
    (_: any, { node }: any) => {
      const treeViewItem = node as TreeViewItem<T>;
      if (treeViewItem.item) {
        setCurrentItem(treeViewItem.item);
        setCurrentAction("details");
      }
    },
    [setCurrentAction]
  );

  return (
    <>
      <Tree
        loadedKeys={loadedKeys}
        expandedKeys={expandedKeys}
        showLine={{ showLeafIcon: true }}
        onExpand={onExpand}
        onDragEnter={onDragEnter}
        onDrop={onDropCallback}
        loadData={loadTreeItems}
        onSelect={onSelect}
        treeData={treeItems}
        draggable={{
          icon: false,
          nodeDraggable: (node: any) =>
            draggable ? draggable(node) : !!onDrop,
        }}
        {...treeProps}
      />

      <ItemSidebar
        item={currentItem}
        sidebarActions={sidebarActions}
        onClose={refreshAll}
      />
    </>
  );
}
