import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Link from "next/link";
import Icon from "@ant-design/icons";
import { Menu, Row, Col, Button } from "antd";
import { Logo } from "../Logo";
import { useRouter } from "next/router";
import { PageConfig, visibleModules } from "../../../lib/modules";
import { useCurrentUser, useMediaXs } from "../../../lib/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { MenuIcons } from "../../../lib/images";
import BlockedTooltip from "../OnboardingProgress/BlockedTooltip";
import routes from "../../../lib/routes";
import { MenuItemType } from "antd/lib/menu/hooks/useItems";

export const LeftMenu = memo(() => {
  const router = useRouter();
  const {
    user,
    forceOnboarding,
    menuCollapsed,
    currentTenant,
    setMenuCollapsed,
  } = useCurrentUser();
  const intl = useIntl();
  const isXs = useMediaXs();
  const modules = useMemo(() => {
    if (!user) return [];

    return Object.entries(visibleModules(user, "left"));
  }, [user]);

  const currentPage = useMemo(
    () =>
      modules
        .reduce((v: PageConfig[], c) => v.concat(c[1]), [])
        .find(({ route }) => route !== "/" && router.route.startsWith(route)),
    [modules, router.route]
  );

  const [openModule, setOpenModule] = useState(
    menuCollapsed ? undefined : currentPage?.module.key
  );

  const onOpenChange = useCallback((openKeys: any) => {
    setOpenModule(openKeys[openKeys.length - 1]);
  }, []);

  const hideMenu = useCallback(() => {
    setMenuCollapsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isXs) return;
    router.events.on("routeChangeStart", hideMenu);
    return () => router.events.off("routeChangeStart", hideMenu);
  }, [hideMenu, isXs, router.events]);

  if (!user) return null;

  const items: Array<MenuItemType & { "data-left-menu-item": string }> = [
    {
      key: "dashboard",
      icon: <Icon component={MenuIcons.DashboardIcon} />,
      label: forceOnboarding ? (
        <FormattedMessage id="dashboard" />
      ) : (
        <Link href="/">
          <FormattedMessage id="dashboard" />
        </Link>
      ),
      "data-left-menu-item": "dashboard",
    },
    ...modules.map(([module, pages]) =>
      pages.length > 1
        ? {
            key: module,
            label: intl.formatMessage(pages[0].module.title),
            icon: <Icon component={pages[0].module.icon} />,
            "data-left-menu-item": module,
            children: pages.map((page) => ({
              key: page.route,
              label: (
                <Link href={page.route} as={page.route}>
                  {intl.formatMessage(page.title)}
                </Link>
              ),
              "data-left-menu-item": page.title.id,
            })),
          }
        : {
            key: module,
            title: intl.formatMessage(pages[0].module.title),
            icon: <Icon component={pages[0].module.icon} />,
            "data-left-menu-item": module,
            label: forceOnboarding ? (
              intl.formatMessage(pages[0].title)
            ) : (
              <Link href={pages[0].route} as={pages[0].route}>
                {intl.formatMessage(pages[0].title)}
              </Link>
            ),
          }
    ),
  ];

  if (currentTenant.woLimit && user.permissions.settings?.billing) {
    items.unshift({
      key: "upgrade",
      "data-left-menu-item": "upgrade",
      className: "left-menu-upgrade-item",
      title: intl.formatMessage({
        id: "subscriptions.upgrade",
        defaultMessage: "Upgrade Plan",
      }),
      label: (
        <Link href={routes.tenants.settings.subscription}>
          <Button block type="primary">
            {menuCollapsed ? (
              <Icon component={MenuIcons.RocketIcon} />
            ) : (
              intl.formatMessage({
                id: "subscriptions.upgrade",
                defaultMessage: "Upgrade Plan",
              })
            )}
          </Button>
        </Link>
      ),
    });
  }

  return (
    <div>
      <Link href="/" data-action="home-link">
        <Row align="middle" justify="center" style={{ margin: "16px 8px" }}>
          <Col>
            <Logo style="white" size="medium" />
          </Col>
        </Row>
      </Link>

      <BlockedTooltip enabled={forceOnboarding} placement="right">
        <Menu
          disabled={forceOnboarding}
          triggerSubMenuAction="click"
          theme="dark"
          mode="inline"
          openKeys={openModule ? [openModule] : []}
          defaultOpenKeys={currentPage ? [currentPage.module.key] : []}
          defaultSelectedKeys={currentPage ? [currentPage?.route] : []}
          onOpenChange={onOpenChange}
          items={items}
          style={{ paddingBottom: 120 }}
        />
      </BlockedTooltip>
    </div>
  );
});
