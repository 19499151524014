import React from "react";
import Icon, {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  ExperimentTwoTone,
} from "@ant-design/icons";
import {
  ProfileIcon,
  CogIcon,
  BillingIcon,
  BookIcon,
  TestModeIcon,
  SignoutIcon,
  MenuIcons,
} from "../../../lib/images";
import { Row, Col, Space, Typography, Input, Button } from "antd";
import { ModuleList } from "./ModuleList";
import {
  useCurrentUser,
  useMediaLarge,
  useMediaTablet,
  useMediaXs,
  useSignOutUser,
} from "../../../lib/hooks";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import Link from "next/link";
import { SelectField } from "../../form";
import routes, { routerPush, routerReload } from "../../../lib/routes";
import { Avatar, Dropdown } from "..";
import { useApolloClient } from "@apollo/client";
import LanguageMenu from "./LanguageMenu";
import { formatUsername } from "../../../lib/formats/users";
import Text from "antd/lib/typography/Text";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import OnboardingProgress from "../OnboardingProgress";
import {
  formatCropCycleCrops,
  formatTenantName,
  formatTestDataResetAt,
} from "../../../lib/formats";
import BlockedTooltip from "../OnboardingProgress/BlockedTooltip";
import { QuickAddButton } from "./QuickAddButton";
import { filterFalse } from "../../../lib/utils";

const messages = defineMessages({
  selectLocality: {
    id: "localities.select",
    defaultMessage: "Select Locality",
  },
});

const EMPTY_PROPS = {
  className: "ant-empty-small",
  imageStyle: {
    margin: "auto",
    height: "30px",
  },
  description: (
    <small>
      <FormattedMessage id="noData" defaultMessage="No Data" />
    </small>
  ),
};

export const TopMenu = () => {
  const intl = useIntl();

  const {
    user,
    currentLocalityId,
    localities,
    currentTenant,
    menuCollapsed,
    currentCropCycleId,
    cropCycles,
    forceOnboarding,
    setCurrentTenant,
    setCurrentCropCycle,
    setCurrentLocality,
    setMenuCollapsed,
  } = useCurrentUser();

  const handleOnNewLocality = () => routerPush(routes.localities.index);
  const handleOnNewCropCycle = () => routerPush(routes.agro.cropCycles.index);

  const [signout] = useSignOutUser({ refetchQueries: ["Me"] });
  const apolloClient = useApolloClient();
  const isXs = useMediaXs();
  const isTablet = useMediaTablet();
  const isLargeScreen = useMediaLarge();

  if (!user) return null;

  const localityOptions = localities.map((l) => ({ key: l.id, label: l.name }));

  const cropCycleOptions =
    cropCycles
      ?.map((l) => ({
        key: l.id,
        label: l.name,
        closed: !!l.closedAt,
        group: formatCropCycleCrops(l),
      }))
      ?.sort((a, b) => a.group.localeCompare(b.group)) || [];

  const showCropCycleGroups =
    new Set(cropCycleOptions.map((option) => option.group)).size > 1;

  const signoutUser = () => {
    signout().then(() => {
      localStorage.clear();

      apolloClient.clearStore().then(() => {
        routerReload();
      });
    });
  };

  const menuItems: ItemType[] = filterFalse([
    user.permissions.settings?.company && {
      key: "company-settings",
      disabled: forceOnboarding,
      icon: <Icon component={CogIcon} />,
      label: (
        <Link href={routes.tenants.settings.basic.index} prefetch={false}>
          <FormattedMessage
            id="companies.settings"
            defaultMessage="companiesSettings"
          />
        </Link>
      ),
    },
    {
      key: "profile",
      disabled: forceOnboarding,
      icon: <Icon component={ProfileIcon} />,
      label: (
        <Link href={routes.profile.basic.index}>
          <FormattedMessage id="profile.my" defaultMessage="my.Profile" />
        </Link>
      ),
    },
    currentTenant.canBilling.value
      ? {
          key: "billing",
          disabled: forceOnboarding,
          icon: <Icon component={BillingIcon} />,
          label: (
            <Link href={routes.tenants.settings.billing} target="_blank">
              <FormattedMessage id="billing" />
            </Link>
          ),
        }
      : undefined,
    {
      key: "resources",
      disabled: forceOnboarding,
      icon: <Icon component={BookIcon} />,
      label: (
        <Link
          href="https://resources.aragro.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="resources" defaultMessage="Resources" />
        </Link>
      ),
    },
    currentTenant.features.testMode &&
      user.permissions.settings?.testMode &&
      !currentTenant.liveTenantId &&
      (currentTenant.testTenantId
        ? {
            key: "test-mode",
            icon: <Icon component={TestModeIcon} />,
            onClick: () =>
              currentTenant.testTenantId &&
              setCurrentTenant(currentTenant.testTenantId),
            label: (
              <FormattedMessage
                id="tenants.testMode.switchToTest"
                defaultMessage="Switch to Test Mode"
              />
            ),
          }
        : {
            key: "test-mode",
            icon: <Icon component={TestModeIcon} />,
            label: (
              <Link href={routes.tenants.settings.testMode} prefetch={false}>
                <FormattedMessage
                  id="tenants.testMode.switchToTest"
                  defaultMessage="Switch to Test Mode"
                />
              </Link>
            ),
          }),
    !!currentTenant.liveTenantId && {
      key: "live-mode",
      icon: <ExperimentTwoTone />,
      onClick: () =>
        currentTenant.liveTenantId &&
        setCurrentTenant(currentTenant.liveTenantId),
      label: (
        <FormattedMessage
          id="tenants.testMode.switchToLive"
          defaultMessage="Switch To Live Mode"
        />
      ),
    },
    {
      key: "signout",
      onClick: signoutUser,
      icon: <Icon component={SignoutIcon} />,
      label: <FormattedMessage id="menu.signout" defaultMessage="signout" />,
    },
  ]);

  const tenantOptions = user.tenants
    .filter((t) => !t.liveTenantId)
    .map((t) => ({
      key: t.id,
      label: formatTenantName(t),
      children: (
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: "8px",
            }}
          >
            {t.fullName}
          </div>
          {user.permissions.tenant?.write && (
            <span style={{ opacity: 0.7 }}>{t.name}</span>
          )}
        </div>
      ),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  if (!currentTenant.liveTenantId && tenantOptions.length > 1) {
    menuItems.unshift({
      key: "tenant-name",
      className: "inactive-dropdown-menu-item",
      label: (
        <SelectField
          defaultValue={currentTenant.id}
          options={tenantOptions}
          style={{ width: "100%" }}
          allowClear={false}
          optionFilterProp="children"
          dropdownMatchSelectWidth={false}
          onChange={(id) => setCurrentTenant(id)}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    });
  }

  if (currentTenant.woLimit && user.permissions.settings?.billing) {
    menuItems.push(
      {
        type: "divider",
      },
      {
        key: "upgrade",
        className: "inactive-dropdown-menu-item",
        style: {
          textAlign: "center",
          paddingBottom: "4px",
        },
        label: (
          <Link href={routes.tenants.settings.subscription}>
            <Button
              icon={
                <Icon
                  component={MenuIcons.RocketIcon}
                  style={{ fontSize: "24px" }}
                />
              }
              style={{
                display: "inline-flex",
                gap: "8px",
                margin: "0 auto",
                borderRadius: "3px",
              }}
              type="primary"
            >
              <FormattedMessage
                id="subscriptions.upgrade"
                defaultMessage="Upgrade"
              />
            </Button>
          </Link>
        ),
      }
    );
  }

  const localitySelectorStyle = {
    minWidth: "10vw",
    maxWidth: isTablet ? "17vw" : "25vw",
  };

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{ width: "100%" }}
      wrap={false}
    >
      {!isXs && (
        <Col>
          <Space align="center" size="large">
            <span
              style={{ fontSize: "18px", cursor: "pointer" }}
              onClick={() => setMenuCollapsed(!menuCollapsed)}
              data-action="left-menu-toggle"
            >
              {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </span>

            <OnboardingProgress />

            {isLargeScreen &&
              currentTenant.liveTenantId &&
              formatTestDataResetAt(currentTenant)}
          </Space>
        </Col>
      )}

      <Col className="header-right-menu">
        <span className="menu-item">
          <BlockedTooltip enabled={forceOnboarding}>
            {!forceOnboarding && <QuickAddButton />}

            <Input.Group compact>
              {(!currentTenant.farmLimit || currentTenant.farmLimit > 1) && (
                <SelectField
                  disabled={forceOnboarding}
                  allowClear={false}
                  showSearch={false}
                  value={currentLocalityId}
                  onChange={setCurrentLocality}
                  style={localitySelectorStyle}
                  options={localityOptions}
                  placeholder={intl.formatMessage(messages.selectLocality)}
                  emptyProps={EMPTY_PROPS}
                  newItemProps={
                    !localityOptions.length && user.permissions.locality?.write
                      ? {
                          entityName: (
                            <FormattedMessage id="localities.entityName" />
                          ),
                          onClick: handleOnNewLocality,
                        }
                      : undefined
                  }
                  data-action="locality-selector"
                />
              )}

              <SelectField
                showGroups={showCropCycleGroups}
                disabled={forceOnboarding}
                allowClear={false}
                showSearch={false}
                value={currentCropCycleId}
                onChange={setCurrentCropCycle}
                style={localitySelectorStyle}
                options={cropCycleOptions}
                placeholder={
                  <FormattedMessage
                    id="cropCycles.select"
                    defaultMessage="Select Crop Cycle"
                  />
                }
                formatOption={(label, option) =>
                  option.closed ? <Text type="secondary">{label}</Text> : label
                }
                emptyProps={EMPTY_PROPS}
                newItemProps={
                  !cropCycleOptions.length && user.permissions.cropCycle?.write
                    ? {
                        entityName: (
                          <FormattedMessage id="cropCycles.entityName" />
                        ),
                        onClick: handleOnNewCropCycle,
                      }
                    : undefined
                }
                data-action="crop-cycle-selector"
              />
            </Input.Group>
          </BlockedTooltip>
        </span>

        <Space>
          <div className="menu-item hover" data-action="modules-menu">
            <ModuleList />
          </div>

          <Dropdown
            menu={{
              triggerSubMenuAction: "click",
              mode: "vertical",
              items: menuItems,
              className: "rounder",
            }}
            placement="bottomLeft"
            className="menu-item hover"
          >
            <Space className="menu-item cursor-pointer">
              <div style={{ display: "flex" }}>
                <Avatar user={user} size={30} />
              </div>

              {!isTablet && (
                <Typography.Text
                  ellipsis
                  style={{ maxWidth: 80 }}
                  className="notranslate"
                  id="top-menu-username"
                >
                  {formatUsername(user)}
                </Typography.Text>
              )}
              <DownOutlined style={{ fontSize: "0.9em" }} />
            </Space>
          </Dropdown>

          <LanguageMenu />
        </Space>
      </Col>
    </Row>
  );
};
