import { defineMessages, useIntl } from "react-intl";
import { BLANK_SYMBOL, formatEmployeeName, formatTags } from ".";
import { UserFragment } from "../graphql";
import { useCurrentUser } from "../hooks";

const messages = defineMessages({
  tenantAdmin: {
    id: "roles.tenantAdmin",
    defaultMessage: "Tenant Administrator",
  },
});

export function formatUsername(
  user?: Pick<
    UserFragment,
    "username" | "email" | "firstName" | "lastName"
  > | null
) {
  if (!user) return BLANK_SYMBOL;

  return (
    user.username ||
    (user.firstName && user.lastName ? formatEmployeeName(user) : user.email)
  );
}

export function formatFullname(
  user?: Pick<
    UserFragment,
    "username" | "email" | "firstName" | "lastName"
  > | null
) {
  if (!user) return BLANK_SYMBOL;

  return user.firstName && user.lastName
    ? formatEmployeeName(user)
    : user.username || user.email;
}

export function useFormatRoleTags() {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();

  return (user: UserFragment) => {
    const roles = user.roles.map((r) => r.name);

    if (currentTenant?.owner?.id === user.id) {
      roles.unshift(intl.formatMessage(messages.tenantAdmin));
    }

    if (!roles.length) return BLANK_SYMBOL;

    return formatTags(roles, "green");
  };
}

export function useFormatLocalityTags() {
  const { currentTenant } = useCurrentUser();

  return (user: UserFragment) => {
    const tenantOwner = user.id === currentTenant.owner?.id;
    const localities = tenantOwner ? currentTenant.localities : user.localities;

    return formatTags(
      localities.map((l) => l.name),
      "blue"
    );
  };
}

export function formatTenants(user: UserFragment) {
  return formatTags(
    user.tenants.map((t) => t.fullName),
    "cyan"
  );
}
