import { useEffect } from "react";
import { CurrentUserFragment } from "../graphql";
import { captureException } from "@sentry/nextjs";
import { useCookies } from "react-cookie";
import {
  CLARITY_USER_ROLE_COOKIE,
  DefaultCookieOptions,
} from "../../components/users/ContextProvider";

export function useIdentifyMicrosoftClarity(user?: CurrentUserFragment | null) {
  const [, setCookie] = useCookies([CLARITY_USER_ROLE_COOKIE]);

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_MICROSOFT_CLARITY || !user) {
      return;
    }

    if (!window.clarity) {
      captureException(new Error("Clarity is not loaded"));
      return;
    }

    try {
      window.clarity("identify", user.email);

      const clarityRole = user.email.endsWith("@aragro.com") ? "team" : "user";
      window.clarity("set", "role", clarityRole);

      // this is used to identify user role on landing page
      setCookie(CLARITY_USER_ROLE_COOKIE, clarityRole, DefaultCookieOptions);
    } catch (error) {
      captureException(error);
    }
  }, [user, setCookie]);
}
