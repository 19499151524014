import {
  EditOutlined,
  LeftOutlined,
  FilePdfOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Divider, Row, Col, Button } from "antd";
import { ReactNode, useState } from "react";
import Router from "next/router";
import { FormattedMessage } from "react-intl";
import { SubmitButton } from "../../form";
import { ButtonProps } from "antd/lib/button";
import Link from "next/link";

interface BottomPanelProps {
  sticky?: boolean;
  buttons: Array<ReactNode>;
}

export function BottomPanel({ buttons, sticky = true }: BottomPanelProps) {
  return (
    <div className={sticky ? "sticky-bottom-panel" : undefined}>
      {!sticky && <Divider dashed />}
      <Row justify="end" gutter={8} wrap={false}>
        {buttons.map((b, index) => (
          <Col key={`btn-${index}`}>{b}</Col>
        ))}
      </Row>
    </div>
  );
}

interface ButtonRouteProps extends ButtonProps {
  route: string;
  anchor?: string;
}

BottomPanel.CancelButton = ({ route, ...props }: ButtonRouteProps) => (
  <Button onClick={() => Router.push(route)} {...props}>
    <span>
      <FormattedMessage id="cancel" defaultMessage="cancel" />
    </span>
  </Button>
);

BottomPanel.SubmitButton = (props?: ButtonProps) => (
  <SubmitButton type="primary" {...props}>
    <span>
      <FormattedMessage id="save" defaultMessage="save" />
    </span>
  </SubmitButton>
);

BottomPanel.CompleteButton = (props?: ButtonProps) => (
  <SubmitButton
    type="primary"
    icon={<CheckOutlined />}
    className="btn-complete"
    {...props}
  />
);

BottomPanel.ReturnButton = ({ route }: ButtonRouteProps) => (
  <Link href={route} legacyBehavior>
    <Button type="default" icon={<LeftOutlined />} href={route}>
      <span>
        <FormattedMessage id="returnToIndex" defaultMessage="returnToIndex" />
      </span>
    </Button>
  </Link>
);

BottomPanel.EditButton = ({ route, anchor }: ButtonRouteProps) => {
  const href = [route, anchor].filter((p) => p).join("#");

  return (
    <Link href={href} legacyBehavior>
      <Button type="default" icon={<EditOutlined />} href={href}>
        <span>
          <FormattedMessage id="edit" defaultMessage="edit" />
        </span>
      </Button>
    </Link>
  );
};

BottomPanel.DownloadPDFButton = ({
  route,
  children,
  ...props
}: { route?: string } & ButtonProps) => (
  <Button
    type="default"
    icon={<FilePdfOutlined />}
    href={route}
    target="_blank"
    {...props}
  >
    <span className="hide-xs">
      {children || (
        <FormattedMessage id="downloadPdf" defaultMessage="downloadPdf" />
      )}
    </span>
  </Button>
);

const ActionButton = ({
  content,
  onClick,
  disable = true,
  ...props
}: Omit<ButtonProps, "content"> & {
  content: ReactNode;
  disable?: boolean;
}) => {
  const [disabled, setDisabled] = useState(false);

  return (
    <Button
      type="primary"
      icon={<CheckOutlined />}
      className="btn-complete"
      disabled={disabled}
      onClick={(event) => {
        if (!onClick) return;

        if (disable) setDisabled(true);
        onClick(event);
      }}
      {...props}
    >
      <span>{content}</span>
    </Button>
  );
};
BottomPanel.ActionButton = ActionButton;
