import {
  CommentableType,
  FinanceOrderDetailsFragment,
  FinanceOrderLineItemCostCenterFragment,
  FinanceOrderLineItemFragment,
  FinanceOrderStatus,
  PaymentMethodType,
  SaveIDFunction,
  DestroyableEntityType,
} from "../../lib/graphql";
import {
  usePaymentMethodOptions,
  useCurrentUser,
  useCropCycleOptions,
  useCurrencyOptions,
  useActivityOptions,
  useCycleNumberOptions,
} from "../../lib/hooks";
import {
  ExchangeRateInput,
  OrderSummary,
  BottomPanel,
  CommentList,
} from "../shared";
import { Form, Rules, SelectField } from "../form";
import routes, { routerPush } from "../../lib/routes";
import { sumBy } from "lodash";
import { Typography, Row, Col, Input, Divider } from "antd";
import { FormattedMessage } from "react-intl";
import { FinanceOrderItemsTable } from "./FinanceOrderItemsTable";
import { AccountSelect } from "./AccountSelect";
import { CounterpartySelect } from "../counterparties";
import { formatLink, shouldUpdate } from "../../lib/formats";
import DocumentDateFormInput from "./DocumentDateFormInput";

interface FinanceOrderFormValues
  extends Omit<FinanceOrderDetailsFragment, "lineItems"> {
  counterpartyId?: string;
  paymentAccountId?: string;
  paymentMethodId?: string;
  cropCycleId?: string;
  activityId?: string;
  lineItems: Array<
    DestroyableEntityType<
      Omit<FinanceOrderLineItemFragment, "costCenters"> & {
        costCenters: Array<
          DestroyableEntityType<
            FinanceOrderLineItemCostCenterFragment & { costCenterId?: string }
          >
        >;
      }
    >
  >;
}

interface FinanceOrderFormProps {
  order: FinanceOrderFormValues;
  onSave(values: FinanceOrderFormValues): SaveIDFunction;
}

export function FinanceOrderForm({ order, onSave }: FinanceOrderFormProps) {
  const disabled = order.status !== FinanceOrderStatus.Open;
  const { paymentMethodOptions, paymentMethods } = usePaymentMethodOptions();
  const [form] = Form.useForm();
  const [commentForm] = Form.useForm();

  const { currentTenant, currentLocalityIds: localityId } = useCurrentUser();

  const isPaymentMethodDebit = () => {
    const paymentMethodId = form.getFieldValue("paymentMethodId");
    return (
      paymentMethods.find((m) => m.id == paymentMethodId)?.paymentMethodType ===
      PaymentMethodType.Debit
    );
  };

  return (
    <>
      <Form
        preventLeaving
        layout="vertical"
        initialValues={order}
        form={form}
        formatError={(error) =>
          formatLink(error, routes.finance.expenseOrders.details)
        }
        onSubmit={(values, { setSubmitting, showErrors }) => {
          const promise = onSave(values);
          if (!promise) return;

          promise.then((order) => {
            if (!order) return;

            if (order.result) {
              commentForm.setFieldsValue({ commentableId: order.result.id });
              commentForm.submit();

              routerPush(order.result.id, routes.finance.expenseOrders.details);
            } else if (order.errors) {
              showErrors(order.errors);
              setSubmitting(false);
            }
          });
        }}
      >
        <Typography.Title level={4}>
          <FormattedMessage id="basicInfo" defaultMessage="basicInfo" />
        </Typography.Title>

        <Row gutter={32}>
          <Col xs={24} md={6}>
            <DocumentDateFormInput disabled={disabled} />
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name="counterpartyId"
              label={
                <FormattedMessage
                  id="expenseOrders.counterpartyQuestion"
                  defaultMessage="Who are you paying?"
                />
              }
              rules={[Rules.required]}
            >
              <CounterpartySelect
                disabled={disabled}
                allowAddNew
                placeholder={
                  <FormattedMessage
                    id="select.supplier"
                    defaultMessage="select.supplier"
                  />
                }
                onChangeCounterparty={(counterparty) => {
                  if (!counterparty) return;

                  if (counterparty.vendorPaymentMethod) {
                    form.setFields([
                      {
                        name: "paymentMethodId",
                        value: counterparty.vendorPaymentMethod.id,
                      },
                    ]);
                  }

                  form.setFieldValue(
                    "taxRate",
                    (counterparty.taxRate || 0) * 100.0
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name="currencyCode"
              label={
                <FormattedMessage
                  id="expenseOrders.purchaseCurrency"
                  defaultMessage="purchaseCurrency"
                />
              }
              required
              rules={[Rules.required]}
            >
              <SelectField
                optionsHook={useCurrencyOptions}
                disabled={disabled}
                placeholder={
                  <FormattedMessage
                    id="select.currency"
                    defaultMessage="select.currency"
                  />
                }
                onChange={(val) => {
                  form.setFields([
                    { name: "currency", value: { isoCode: val } },
                  ]);
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate("currency", "documentDate")}
            >
              {({ getFieldValue }) => (
                <ExchangeRateInput
                  name="exchangeRate"
                  from={getFieldValue("currency")}
                  to={currentTenant.currency}
                  date={getFieldValue("documentDate")}
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} md={6}>
            <Form.Item
              name="paymentMethodId"
              label={
                <FormattedMessage
                  id="paymentMethods.question"
                  defaultMessage="How are you paying?"
                />
              }
              rules={[Rules.required]}
            >
              <SelectField
                options={paymentMethodOptions}
                disabled={disabled}
                showGroups
                placeholder={
                  <FormattedMessage
                    id="select.paymentMethod"
                    defaultMessage="select.paymentMethod"
                  />
                }
                onChange={() =>
                  form.setFields([{ name: "paymentAccountId", value: null }])
                }
              />
            </Form.Item>
          </Col>

          {!currentTenant.financeEntriesDisabled && (
            <Form.Item noStyle shouldUpdate={shouldUpdate("paymentMethodId")}>
              {() =>
                isPaymentMethodDebit() && (
                  <Col xs={24} md={6}>
                    <Form.Item
                      name="paymentAccountId"
                      label={
                        <FormattedMessage id="expenseOrders.paymentAccount" />
                      }
                      rules={[Rules.required]}
                    >
                      <AccountSelect
                        disabled={disabled}
                        placeholder={<FormattedMessage id="select.account" />}
                        mode="ledger"
                        kindKey={["cash"]}
                      />
                    </Form.Item>
                  </Col>
                )
              }
            </Form.Item>
          )}

          <Col xs={24} md={6}>
            <Form.Item
              required
              name="cropCycleId"
              label={<FormattedMessage id="cropCycles.entityName" />}
              rules={[Rules.required]}
            >
              <SelectField
                optionsHook={useCropCycleOptions}
                optionsHookParams={{
                  variables: {
                    filter: {
                      localityId,
                      closedAt: null,
                    },
                  },
                }}
                disabled={disabled}
              />
            </Form.Item>
          </Col>

          <Form.Item noStyle shouldUpdate={shouldUpdate("activityId")}>
            {() => {
              const trackCycles =
                form.getFieldValue("trackCycles") || order.cycleNumber != null;

              return (
                <>
                  <Col xs={24} md={trackCycles ? 4 : 6}>
                    <Form.Item
                      name="activityId"
                      label={<FormattedMessage id="activities.entityName" />}
                    >
                      <SelectField
                        optionsHook={useActivityOptions}
                        showGroups
                        disabled={disabled}
                        onChange={(val, opts) => {
                          form.setFieldValue(
                            "trackCycles",
                            opts.find((o: any) => o.key == val)?.activity
                              ?.trackCycles
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {trackCycles && (
                    <Col xs={24} md={2}>
                      <Form.Item
                        name="cycleNumber"
                        label={<FormattedMessage id="workOrders.cycleNumber" />}
                        rules={[Rules.required]}
                      >
                        <SelectField optionsHook={useCycleNumberOptions} />
                      </Form.Item>
                    </Col>
                  )}
                </>
              );
            }}
          </Form.Item>

          <Col xs={24} md={6}>
            <Form.Item
              name="internalId"
              label={
                <FormattedMessage
                  id="documentNumber"
                  defaultMessage="Document #"
                />
              }
            >
              <Input disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>

        <Divider dashed />

        <Typography.Title level={4}>
          <FormattedMessage
            id="expenseOrders.details"
            defaultMessage="details"
          />
        </Typography.Title>

        <Form.Item
          shouldUpdate={(prev, next) => prev.currency != next.currency}
        >
          {({ getFieldsValue }) => (
            <FinanceOrderItemsTable
              order={getFieldsValue(true) as FinanceOrderFormValues}
              disabled={disabled}
            />
          )}
        </Form.Item>

        <Row justify="space-between" gutter={[16, 16]}>
          <Col xs={24} md={6} lg={7} xl={5}></Col>
          <Col xs={24} md={16} lg={12}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const order = getFieldsValue(
                  true
                ) as FinanceOrderDetailsFragment;

                const subtotal = sumBy(order.lineItems, "amount");

                return (
                  <OrderSummary
                    order={order}
                    subtotal={subtotal || 0}
                    taxValues={[]}
                  />
                );
              }}
            </Form.Item>
          </Col>
        </Row>

        {!disabled && (
          <BottomPanel
            sticky
            buttons={[
              BottomPanel.CancelButton({
                route: routes.finance.expenseOrders.index,
              }),
              BottomPanel.SubmitButton(),
            ]}
          />
        )}
      </Form>

      <CommentList
        form={commentForm}
        commentableType={CommentableType.FinanceOrder}
        commentableId={order.id}
      />
    </>
  );
}
