import React from "react";
import { ItemForm, ItemFormProps } from "./ItemForm";
import {
  SaveMutationResult,
  UpdateMutationVariables,
  MutationProps,
  WithIDType,
} from "../../../lib/graphql";
import { useCurrentLocale } from "../../../lib/hooks";
import { useIntl, defineMessages } from "react-intl";
import { SidebarHeader } from "../Sidebar";

interface EditFormProps<T, TFormValues, TMutation, TMutationVariables>
  extends MutationProps<TMutation, TMutationVariables, SaveMutationResult<T>>,
    Pick<
      ItemFormProps<T, TFormValues>,
      "fields" | "formProps" | "onCancel" | "initBag" | "hideFooter"
    > {
  item: T;
  entityName: string;
  formValues(item: T): TFormValues;
  onSuccess?(result: T): void;
  showHeader?: boolean;
  processValues?(value: TFormValues): TFormValues;
}

const messages = defineMessages({
  edit: { id: "edit.header", defaultMessage: "edit" },
  editSuccess: { id: "edit.success", defaultMessage: "success" },
});

export function EditForm<T extends WithIDType, TChanges, TUpdateMutation>({
  fields,
  entityName,
  item,
  formValues,
  mutation,
  onMutate,
  mutationOptions,
  onCancel,
  onSuccess,
  showHeader = true,
  formProps,
  processValues = (v) => v,
  ...itemFormProps
}: EditFormProps<
  T,
  TChanges,
  TUpdateMutation,
  UpdateMutationVariables<TChanges>
>) {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();
  const [update] = mutation({ ignoreResults: true, ...mutationOptions });

  return (
    <>
      {showHeader && (
        <SidebarHeader
          back
          title={intl.formatMessage(messages.edit, { entityName })}
          onClose={onCancel}
        />
      )}

      <ItemForm
        entityName={entityName}
        fields={fields}
        item={item}
        initialValues={formValues(item)}
        onCancel={onCancel}
        formProps={formProps}
        onSave={(values) => {
          return update({
            variables: { id: item.id, changes: processValues(values) },
          })
            .then((result) => {
              if (!result.data) return null;

              return onMutate(result.data);
            })
            .then((result) => {
              if (!result) return null;
              if (result.result) {
                showSuccess(
                  intl.formatMessage(messages.editSuccess, {
                    entityName,
                    id: item.id,
                  })
                );
                onSuccess && onSuccess(result.result);
              }
              return result;
            });
        }}
        {...itemFormProps}
      />
    </>
  );
}
