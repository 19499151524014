import React, { ReactNode, useState } from "react";
import {
  FormattedMessage,
  FormattedPlural,
  defineMessages,
  useIntl,
} from "react-intl";
import { ConfirmSidebar, ConfirmSidebarProps } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Spin, Typography } from "antd";
import { rtfValues } from "../../../lib/formats";
import { useBulkDiscard } from "../../../lib/hooks/basic/bulkMutations";
import {
  BulkOperationResult,
  DiscardableType,
  DiscardableEntityType,
} from "../../../lib/graphql";
import { groupBy } from "lodash";

export interface BulkDiscardProps<T> {
  entityName: string;
  pluralEntityName: string;
  discardableType: DiscardableType;
  items: T[];
  disable?: boolean;
  mutationOptions?: Parameters<typeof useBulkDiscard>[0];
  labelKey?: keyof T | ((item: T) => ReactNode);
  onCancel(): void;
  onSuccess(): void;
}

const messages = defineMessages({
  discard: { id: "discard.header", defaultMessage: "Disable" },
  undiscard: { id: "undiscard.header", defaultMessage: "Enable" },
  discardSuccess: { id: "discard.success", defaultMessage: "success" },
  restoreSuccess: { id: "restore.success", defaultMessage: "success" },
});

export function BulkDiscard<T extends DiscardableEntityType>({
  entityName,
  pluralEntityName,
  items,
  discardableType,
  mutationOptions,
  labelKey,
  disable = true,
  onCancel,
  onSuccess,
}: BulkDiscardProps<T>) {
  const intl = useIntl();
  const [mutationResult, setMutationResult] = useState<BulkOperationResult[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const [discard] = useBulkDiscard({
    ignoreResults: true,
    ...mutationOptions,
  });

  const ids = items.filter((i) => disable === !i.discardedAt).map((i) => i.id);

  useEffectOnce(() => {
    discard({
      variables: {
        discardableType,
        ids,
        dryRun: true,
        discard: disable,
      },
    }).then((result) => {
      if (!result.data) return;

      setLoading(false);

      setMutationResult(result.data.bulkDiscard?.result || []);
    });
  });

  const groupedResults = groupBy(mutationResult, "result");

  const hasItemsToProcess = !!groupedResults["true"];

  const onDiscard: ConfirmSidebarProps["onOk"] = ({ showSuccess }) => {
    if (!hasItemsToProcess) return;

    setLoading(true);
    discard({
      variables: {
        ids: groupedResults["true"].map((r) => r.id),
        discardableType,
        discard: disable,
      },
    })
      .then((result) => {
        setLoading(false);
        if (!result.data) return;

        return result.data.bulkDiscard;
      })
      .then(() => {
        showSuccess(
          intl.formatMessage(
            disable ? messages.discardSuccess : messages.restoreSuccess,
            {
              entityName,
              ...rtfValues,
            }
          )
        );

        onSuccess();
      });
  };

  const labelFormatter = (i: T) =>
    labelKey
      ? typeof labelKey === "function"
        ? labelKey(i)
        : (i[labelKey] as string)
      : i.id;

  return (
    <ConfirmSidebar
      title={intl.formatMessage(
        disable ? messages.discard : messages.undiscard,
        {
          entityName,
        }
      )}
      onCancel={onCancel}
      okButtonProps={{
        danger: disable,
        loading,
        hidden: !hasItemsToProcess,
      }}
      okText={
        disable ? (
          <FormattedMessage id="discard" />
        ) : (
          <FormattedMessage id="undiscard" />
        )
      }
      onOk={onDiscard}
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          {hasItemsToProcess ? (
            <>
              <Typography.Title level={5}>
                {disable ? (
                  <FormattedMessage
                    id="discard.bulk.listToDiscard"
                    defaultMessage="Following items will be disabled:"
                    values={{
                      entityName: (
                        <FormattedPlural
                          value={groupedResults["true"].length}
                          one={entityName}
                          other={pluralEntityName}
                        >
                          {(text: any) => text.toLowerCase()}
                        </FormattedPlural>
                      ),
                      count: groupedResults["true"].length,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="restore.bulk.listToRestore"
                    defaultMessage="Following items will be restored:"
                    values={{
                      entityName: (
                        <FormattedPlural
                          value={groupedResults["true"].length}
                          one={entityName}
                          other={pluralEntityName}
                        >
                          {(text: any) => text.toLowerCase()}
                        </FormattedPlural>
                      ),
                      count: groupedResults["true"].length,
                    }}
                  />
                )}
              </Typography.Title>

              <ol style={{ paddingLeft: "1rem" }}>
                {groupedResults["true"].map((r) => {
                  const item = items.find((i) => i.id === r.id);

                  return (
                    <li key={r.id}>{item ? labelFormatter(item) : r.id}</li>
                  );
                })}
              </ol>
            </>
          ) : (
            !groupedResults["false"] &&
            (disable ? (
              <Typography.Title level={5}>
                <FormattedMessage
                  id="discard.bulk.noItems"
                  defaultMessage="No {entityName} to disable"
                  values={{ entityName: pluralEntityName.toLowerCase() }}
                />
              </Typography.Title>
            ) : (
              <Typography.Title level={5}>
                <FormattedMessage
                  id="restore.bulk.noItems"
                  defaultMessage="No {entityName} to restore"
                  values={{ entityName: pluralEntityName.toLowerCase() }}
                />
              </Typography.Title>
            ))
          )}

          {groupedResults["false"] && (
            <>
              {groupedResults["true"]?.length && <hr />}

              <Typography.Title level={5}>
                {disable ? (
                  <FormattedMessage
                    id="discard.bulk.listCantDiscard"
                    defaultMessage="Following items couldn't be disabled:"
                    values={{
                      entityName: (
                        <FormattedPlural
                          value={groupedResults["false"].length}
                          one={entityName}
                          other={pluralEntityName}
                        >
                          {(text: any) => text.toLowerCase()}
                        </FormattedPlural>
                      ),
                      count: groupedResults["false"].length,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="restore.bulk.listCantRestore"
                    defaultMessage="Following items couldn't be restored:"
                    values={{
                      entityName: (
                        <FormattedPlural
                          value={groupedResults["false"].length}
                          one={entityName}
                          other={pluralEntityName}
                        >
                          {(text: any) => text.toLowerCase()}
                        </FormattedPlural>
                      ),
                      count: groupedResults["false"].length,
                    }}
                  />
                )}
              </Typography.Title>

              <ol style={{ paddingLeft: "1rem" }}>
                {groupedResults["false"].map((r) => {
                  const item = items.find((i) => i.id === r.id);

                  return (
                    <li key={r.id}>{item ? labelFormatter(item) : r.id}</li>
                  );
                })}
              </ol>
            </>
          )}
        </>
      )}
    </ConfirmSidebar>
  );
}
