import { TreeSelect } from "antd";
import { useState, ReactNode, useEffect, useCallback, Key } from "react";
import { DataNode } from "./types";
import { WithIDType, QueryLazyOptions } from "../../lib/graphql";
import { unionBy, debounce, union, compact } from "lodash";
import { TreeSelectProps } from "antd/lib/tree-select";

export interface TreeSelectFieldProps<T>
  extends Omit<TreeSelectProps<any>, "onSearch"> {
  buildTreeNode: (item: T) => {
    title: ReactNode;
    isLeaf: boolean;
    pId?: string | null;
  };
  queryHook: (params?: any) => {
    load?: (options?: QueryLazyOptions<any>) => void;
    search?: (value?: string) => void;
    items?: T[];
    loading?: boolean;
  };
  queryHookParams(parentId: string | null): any;
  onChange?: (val: any, options: any, extra: any) => void;
  loadNode?: (node: DataNode) => Promise<any[]>;
  searchNode?: (searchValue?: string) => Promise<any[]>;
  autoExpand?: boolean;
}

export function TreeSelectField<T extends WithIDType>({
  buildTreeNode,
  onChange,
  queryHook,
  queryHookParams,
  loadNode,
  searchNode,
  autoExpand = true,
  ...restProps
}: TreeSelectFieldProps<T>) {
  const [treeItems, setTreeItems] = useState<DataNode[]>([]);
  const [rootNodesFetched, setRootNodesFetched] = useState(false);
  const { load, search, items, loading } = queryHook(queryHookParams(null));
  const [expandedKeys, setExpandedKeys] = useState<Key[]>();

  const createTreeNode = useCallback(
    (item: T) => ({
      id: item.id,
      key: item.id,
      value: item.id,
      ...buildTreeNode(item),
    }),
    [buildTreeNode]
  );

  const fetchRootNodes = useCallback(() => {
    // provide any variables to refetch data from network instead of fetching from cache
    // https://github.com/apollographql/apollo-client/blob/main/src/core/ObservableQuery.ts#L809
    load &&
      load(
        queryHookParams(null) || { variables: { filter: { id: undefined } } }
      );
    setRootNodesFetched(true);
  }, [load, queryHookParams]);

  const addItemsToTree = useCallback(
    (newItems?: T[]) => {
      if (!newItems || !newItems.length) return;

      setTreeItems((items) => {
        const newNodes = newItems.map(createTreeNode);

        // TODO: consider replacing by search fn check
        if (autoExpand)
          setExpandedKeys((keys) =>
            union(keys, compact(newNodes.map((i) => i.pId)))
          );
        return unionBy(items, newNodes, "id");
      });
    },
    [autoExpand, createTreeNode]
  );

  const loadData = useCallback(
    (node: DataNode) =>
      new Promise<void>((resolve) => {
        const parentKey = node.id?.toString();

        if (parentKey && load) {
          load(queryHookParams(parentKey));
        }

        if (loadNode) {
          return loadNode(node)
            .then((newItems) => addItemsToTree(newItems))
            .then(resolve);
        }
        resolve();
      }),
    [addItemsToTree, load, loadNode, queryHookParams]
  );

  const searchData = useCallback(
    (searchValue?: string) => {
      search && search(searchValue);

      if (searchNode && searchValue) {
        searchNode(searchValue).then((newItems) => addItemsToTree(newItems));
      }

      if (!searchValue) setExpandedKeys([]);
    },
    [addItemsToTree, search, searchNode]
  );

  const debouncedSearch = search && debounce(searchData, 500);

  useEffect(() => addItemsToTree(items), [items, addItemsToTree]);

  useEffect(() => {
    if (!restProps.value || !load || restProps.labelInValue) return;

    // load passed value nodes
    const values = Array.isArray(restProps.value)
      ? restProps.value
      : [restProps.value];

    if (values.every((v) => treeItems.some((n) => n.id == v))) return;

    // TODO: hide loaded nodes in promise result
    load({ variables: { filter: { id: restProps.value } } });
  }, [load, restProps.value, restProps.labelInValue, treeItems]);

  return (
    <TreeSelect
      treeData={treeItems}
      showSearch
      allowClear
      treeDataSimpleMode
      loading={loading}
      loadData={loadData}
      dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
      filterTreeNode={true}
      treeNodeFilterProp="title"
      onSearch={debouncedSearch}
      onDropdownVisibleChange={(open) => {
        if (open && !rootNodesFetched) {
          fetchRootNodes();
        }
      }}
      onClear={() => debouncedSearch && debouncedSearch("")}
      onChange={(val) => {
        const value =
          restProps.multiple && val && !Array.isArray(val)
            ? [val]
            : val || null;
        onChange && onChange(value, treeItems, {});
      }}
      treeExpandedKeys={expandedKeys}
      onTreeExpand={setExpandedKeys}
      {...restProps}
    />
  );
}
