import { sumBy } from "lodash";
import { WorkOrderBuilder } from ".";
import { distributeProportionally, roundUnit } from "../../../../lib/formats";
import {
  StockFragment,
  WorkOrderVariantFragment,
  WorkOrderOutputFragment,
  WorkOrderInfoFragment,
  WorkOrderVariantSourceFragment,
  DestroyableEntityType,
} from "../../../../lib/graphql";
import { WorkOrderBaseBuilder } from "./base";
import { WorkOrderVariant } from "./tools";
import { OutputMetricBuilder } from "./metrics/outputMetrics";
import { Form } from "../../../form";

export interface WorkOrderOutput
  extends DestroyableEntityType<Omit<WorkOrderOutputFragment, "sources">> {
  percent?: number;
  stock?: Pick<StockFragment, "onHand"> | null;
  workOrderVariant?: WorkOrderVariant;
  workOrder?: WorkOrderInfoFragment;
  sources?: Array<DestroyableEntityType<WorkOrderVariantSourceFragment>>;
}

export class WorkOrderOutputBuilder extends WorkOrderBaseBuilder<WorkOrderOutput> {
  private totalHarvested: number;
  public distributed: boolean;
  private distributionLoaded = false;
  metrics: OutputMetricBuilder;

  constructor(builder: WorkOrderBuilder) {
    super(builder);

    if (builder.isProcessing) {
      this.builder.workOrder.processingInputs = this.builder.workOrder.outputs
        .filter((o) => o.returnedAmount != null)
        .map((o) => ({
          ...o,
          sourceWarehouseId: o.sourceWarehouse?.id,
        }));
      this.builder.workOrder.processingOutputs = this.builder.workOrder.outputs
        .filter((o) => o.returnedAmount == null)
        .map((o) => ({ ...o, returnWarehouseId: o.returnWarehouse?.id }));
      this.builder.workOrder.outputs = [];
    } else {
      this.builder.workOrder.outputs = this.builder.workOrder.outputs.map(
        (o) => ({
          ...o,
          returnWarehouseId: o.returnWarehouse?.id,
        })
      );
    }

    this.totalHarvested = sumBy(builder.workOrder.outputs, "totalAmount");
    this.distributed = false;
    this.metrics = new OutputMetricBuilder(builder);
  }

  protected get field() {
    return "outputs";
  }

  sorter(a: WorkOrderOutput, b: WorkOrderOutput) {
    return a.variant.name.localeCompare(b.variant.name);
  }

  get allowDistribution() {
    return this.get().length > 1 || this.getTotalHarvested() == 0;
  }

  initOutputs(readonly: boolean) {
    if (!readonly) {
      const costCenters = this.getCostCenters();
      const outputs = this.get();

      if (outputs.length === 0) return;

      outputs.forEach((o) => {
        costCenters.forEach(({ variants }) => {
          const cfv = variants.find((v) => v.variantId === o.variant.id);

          if (!cfv) {
            variants.push({
              id: "",
              variantId: o.variant.id,
              amount: 0,
            });

            this.totalHarvested = 0;
          }
        });
      });
    }

    this.calculatePercentage();
    this.loadOutputDistribution();

    if (!readonly) this.recalculateOutputTotalAmounts();
  }

  getTotalHarvested() {
    this.loadOutputDistribution();

    return this.builder.costCenters.getProgressToDistribute();
  }

  getTotalAmount() {
    const outputs = this.get();
    return roundUnit(sumBy(outputs, (o) => o.totalAmount || 0));
  }

  getTotalPercentage() {
    return sumBy(this.get(), (o) => o.percent || 0) / 100;
  }

  getPercent(index: number) {
    return this.get(false)[index].percent || 0;
  }

  // todo: move progress to cc employee progress and kill it
  loadOutputDistribution() {
    if (this.distributionLoaded) return;

    this.distributionLoaded = true;

    if (!this.hasItems) return;

    const outputVariants = this.get().map((o) => o.variant.id);
    if (outputVariants.length == 0) return;

    const costCenters = this.getCostCenters();
    costCenters.forEach((cc) => {
      const progress = sumBy(
        cc.variants.filter((ccv) => outputVariants.includes(ccv.variantId)),
        "amount"
      );

      cc.totalProgress = roundUnit(progress);
    });
  }

  recalculateOutputTotalAmounts(force = false) {
    const harvested = this.getTotalHarvested();

    if (!force && this.totalHarvested === harvested) return;

    const outputs = this.get();
    if (outputs.length === 1) {
      outputs[0].totalAmount = harvested;
    }

    this.calculatePercentage();

    this.recalculateVariants(false);

    this.totalHarvested = harvested;
  }

  recalculateVariants(updateState = true) {
    const outputs = this.get();
    const costCenters = this.getCostCenters();

    outputs.forEach((output) => {
      const distribution = distributeProportionally(
        output.totalAmount,
        costCenters.map((cf) => cf.totalProgress)
      );

      costCenters.forEach((cc, ccIndex) => {
        const ccvIndex = cc.variants.findIndex(
          (ccv) => ccv.variantId === output.variant.id
        );

        const amount = distribution[ccIndex];

        if (updateState) {
          this.form.setFields([
            {
              name: ["costCenters", ccIndex, "variants", ccvIndex, "amount"],
              value: amount,
            },
          ]);
        } else {
          cc.variants[ccvIndex].amount = amount;
        }
      });
    });
  }

  calculatePercentage() {
    const outputs = this.get();

    const distribution = distributeProportionally(
      100,
      outputs.map((o) => o.totalAmount)
    );

    outputs.forEach((output, index) => {
      output.percent = distribution[index] || 0;
    });
  }

  onPercentChanged(index: number) {
    const output = this.get(false)[index];
    const totalHarvested = this.getTotalHarvested();

    this.form.setFields([
      {
        name: ["outputs", index, "totalAmount"],
        value: roundUnit((totalHarvested * (output.percent || 0)) / 100),
      },
    ]);

    this.recalculateVariants(false);
  }

  onAmountChanged(index: number) {
    const output = this.get(false)[index];
    const totalHarvested = this.getTotalHarvested();

    this.form.setFields([
      {
        name: ["outputs", index, "percent"],
        value: roundUnit((output.totalAmount * 100) / totalHarvested),
      },
    ]);

    this.recalculateVariants(true);
  }

  onRemove(input: WorkOrderVariantFragment) {
    this.builder.inputs.onRemove(input as WorkOrderVariant);

    this.recalculateOutputTotalAmounts(true);
  }

  getProcessingInputs() {
    return this.form.getFieldValue("processingInputs") as WorkOrderOutput[];
  }

  getProcessingOutputs() {
    return this.form.getFieldValue("processingOutputs") as WorkOrderOutput[];
  }

  removeProcessingOutputs() {
    [...this.getProcessingInputs(), ...this.getProcessingOutputs()].forEach(
      (i) => this.onRemove(i)
    );
  }

  addProcessingInputs(added: WorkOrderOutput[]) {
    const inputs = this.getProcessingInputs();
    inputs.forEach((i, index) => {
      added
        .filter((a) => a.variant.id == i.variant.id)
        .forEach((toAdd) => {
          if (!toAdd.workOrderVariant) return;
          if (!toAdd.workOrder) return;

          if (
            i.sources &&
            !i.sources.find(
              (s) => s.sourceWorkOrderVariant.id == toAdd.workOrderVariant?.id
            )
          ) {
            i.totalAmount = i.totalAmount + toAdd.workOrderVariant.totalAmount;

            i.sources.push({
              id: "",
              amount: toAdd.workOrderVariant.totalAmount,
              sourceWorkOrderVariant: {
                id: toAdd.workOrderVariant.id || "",
                workOrder: toAdd.workOrder,
              },
            });
          }
        });

      this.form.setFields([
        {
          name: ["processingInputs", index, "sources"],
          value: [...(i.sources || [])],
        },
        {
          name: ["processingInputs", index, "totalAmount"],
          value: i.totalAmount,
        },
      ]);
    });
  }

  recalculateProcessingInput(index: number) {
    const input = this.getProcessingInputs()[index];
    this.form.setFieldValue(
      ["processingInputs", index, "totalAmount"],
      sumBy(input.sources?.filter(Form.undestroyed), "amount")
    );
  }
}
