import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Typography, Table } from "antd";
import { sumBy, uniqBy } from "lodash";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  formatCropField,
  formatPer,
  formatPlaceholder,
  formatUnitValue,
  formatUnitValueConversion,
  shouldUpdate,
} from "../../../lib/formats";
import {
  CropFieldHarvestEstimateFragment,
  CropStageType,
  HarvestEstimateChanges,
  UnitType,
  DestroyableEntityType,
  SaveIDFunction,
} from "../../../lib/graphql";
import {
  useCropCycleOptions,
  useCropFieldOptions,
  useCurrentUser,
} from "../../../lib/hooks";
import routes, { routerPush } from "../../../lib/routes";
import { Form, InputNumber, Rules, SelectField } from "../../form";
import { BottomPanel, TableInput } from "../../shared";
import { UnitSelect } from "../../units";

interface HarvestEstimateFormValue
  extends Omit<HarvestEstimateChanges, "cropFieldEstimatesAttributes"> {
  id?: string;
  cropCycleId?: string;
  cropFieldEstimates: Array<
    DestroyableEntityType<CropFieldHarvestEstimateFragment>
  >;
}

interface HarvestEstimateFormProps {
  value: HarvestEstimateFormValue;
  onSave(values: HarvestEstimateFormValue): SaveIDFunction;
}

export function HarvestEstimateForm({
  value,
  onSave,
}: HarvestEstimateFormProps) {
  const [form] = Form.useForm();
  const { currentLocalityIds: localityId, currentLocality } = useCurrentUser();
  const areaUnit = currentLocality?.areaUnit;

  const { load: loadProductionCropFields, options: productionCropFields } =
    useCropFieldOptions();

  // if all production fields loaded
  useEffect(() => {
    if (productionCropFields.length) {
      form.setFields([
        {
          name: "cropFieldEstimates",
          value: productionCropFields.map(({ cropField }) => ({
            cropField,
            effectiveArea: cropField.effectiveArea,
          })),
        },
      ]);
    }
  }, [productionCropFields, form]);

  const checkSameUnits = () => {
    const isSameUnits =
      form.getFieldValue("harvestUnitId") ==
      form.getFieldValue("estimateUnitId");

    if (isSameUnits) {
      form.setFields([{ name: "conversionFactor", value: 1 }]);
    }
  };

  return (
    <Form
      preventLeaving
      layout="vertical"
      initialValues={value}
      form={form}
      onSubmit={(values, { setSubmitting, showErrors }) => {
        if (values.harvestUnitId == values.estimateUnitId) {
          values.conversionFactor = 1;
        }

        const promise = onSave(values);
        if (!promise) return;

        promise.then((e) => {
          if (!e) return;

          if (e.result) {
            routerPush(e.result.id, routes.agro.harvestEstimates.details);
          } else if (e.errors) {
            showErrors(e.errors);
            setSubmitting(false);
          }
        });
      }}
    >
      <Typography.Title level={4}>
        <FormattedMessage id="basicInfo" defaultMessage="basicInfo" />
      </Typography.Title>

      <Row gutter={32}>
        <Col xs={24} md={6}>
          <Form.Item
            name="cropCycleId"
            label={<FormattedMessage id="cropCycles.entityName" />}
            rules={[Rules.required]}
            tooltip={{
              title: (
                <FormattedMessage
                  id="harvestEstimates.cropCycle.tooltip"
                  defaultMessage="You may only create one Harvest Estimate per Crop Cycle."
                />
              ),
              icon: <InfoCircleOutlined />,
            }}
          >
            <SelectField
              disabled={!!value.id}
              optionsHook={useCropCycleOptions}
              optionsHookParams={{
                variables: { filter: { localityId, closedAt: null } },
              }}
              placeholder={formatPlaceholder({ id: "cropCycles.entityName" })}
              onChange={(val, options) => {
                const cropCycle = options.find(
                  (o: any) => o.key == val
                )?.cropCycle;
                form.setFields([
                  {
                    name: "cropCycle",
                    value: cropCycle,
                  },
                ]);

                if (!cropCycle) return;

                loadProductionCropFields({
                  variables: {
                    filter: {
                      localityId,
                      cropId: cropCycle?.crop?.id,
                      cropStageType: CropStageType.Production,
                    },
                    pageSize: 500,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name="estimateUnitId"
            label={
              <FormattedMessage
                id="harvestEstimates.estimateUnit"
                defaultMessage="Estimate U/M"
              />
            }
            rules={[Rules.required]}
            tooltip={{
              title: (
                <FormattedMessage
                  id="harvestEstimates.estimateUnit.tooltip"
                  defaultMessage="Select the unit of measure in which you’ll estimate your harvest."
                />
              ),
              icon: <InfoCircleOutlined />,
            }}
          >
            <UnitSelect
              unitTypes={[UnitType.Weight, UnitType.Unitary]}
              showGroups
              abbrLabel={false}
              placeholder={formatPlaceholder({
                id: "harvestEstimates.estimateUnit",
              })}
              onUnitChange={(value) => {
                checkSameUnits();
                form.setFields([
                  {
                    name: "estimateUnit",
                    value,
                  },
                ]);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name="harvestUnitId"
            label={
              <FormattedMessage
                id="harvestEstimates.harvestUnit"
                defaultMessage="Harvest U/M"
              />
            }
            rules={[Rules.required]}
            tooltip={{
              title: (
                <FormattedMessage
                  id="harvestEstimates.harvestUnit.tooltip"
                  defaultMessage="Choose the same unit of measure used in your Harvest Type Activities."
                />
              ),
              icon: <InfoCircleOutlined />,
            }}
          >
            <UnitSelect
              unitTypes={[UnitType.Weight, UnitType.Unitary]}
              abbrLabel={false}
              showGroups
              placeholder={formatPlaceholder({
                id: "harvestEstimates.harvestUnit",
              })}
              onUnitChange={(value) => {
                checkSameUnits();
                form.setFields([
                  {
                    name: "harvestUnit",
                    value,
                  },
                ]);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            noStyle
            shouldUpdate={shouldUpdate("harvestUnit", "estimateUnit")}
          >
            {({ getFieldValue }) => {
              const harvestUnit = getFieldValue("harvestUnit");
              const estimateUnit = getFieldValue("estimateUnit");

              return (
                <Form.Item
                  name="conversionFactor"
                  label={
                    <FormattedMessage
                      id="harvestEstimates.conversionFactor"
                      defaultMessage="Conversion"
                    />
                  }
                  rules={[Rules.required]}
                >
                  <InputNumber
                    min={0}
                    disabled={harvestUnit?.id == estimateUnit?.id}
                    addonBefore={<>{formatUnitValue(1, estimateUnit)} =</>}
                    addonAfter={harvestUnit?.abbr}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Divider dashed />

      <Typography.Title level={4}>
        <FormattedMessage
          id="harvestEstimates.cropFields"
          defaultMessage="Crop Fields to Estimate"
        />
      </Typography.Title>

      <Form.Item
        noStyle
        shouldUpdate={shouldUpdate(
          "cropCycle",
          "estimateUnit",
          "harvestUnit",
          "conversionFactor"
        )}
      >
        {({ getFieldValue }) => {
          const cropCycle = getFieldValue("cropCycle");
          const estimateUnit = getFieldValue("estimateUnit");
          const harvestUnit = getFieldValue("harvestUnit");
          const conversionFactor = getFieldValue("conversionFactor");

          const amountSuffix = formatPer({
            unit: estimateUnit?.abbr,
            per: areaUnit?.abbr,
          });

          return (
            <TableInput
              name="cropFieldEstimates"
              disabled={!cropCycle || !estimateUnit}
              dataSource={value.cropFieldEstimates}
              rowKey={(e) => e.cropField.id}
              rules={[Rules.required]}
              tableSelectProps={{
                mode: "multiple",
                showGroups: true,
                placeholder: (
                  <FormattedMessage
                    id="select.cropField"
                    defaultMessage="cropFields"
                  />
                ),
                optionsHook: useCropFieldOptions,
                optionsHookParams: {
                  variables: {
                    filter: {
                      cropId: cropCycle?.crop?.id,
                      localityId,
                    },
                  },
                },
                entityById: (_, { cropField }) => {
                  if (cropField) {
                    return {
                      cropField,
                      effectiveArea: cropField.effectiveArea,
                    } as CropFieldHarvestEstimateFragment;
                  }
                },
              }}
              allowBulkRemove
              bulkUpdateFields={[
                {
                  type: "number",
                  name: "amount",
                  numberProps: { addonAfter: amountSuffix },
                  label: (
                    <FormattedMessage
                      id="harvestEstimates.amount"
                      defaultMessage="Estimation Yield"
                    />
                  ),
                },
              ]}
              rowSelectors={(source) =>
                uniqBy(
                  source.map((e) => e.cropField.cropStage),
                  "id"
                ).map((stage) => ({
                  key: stage.id,
                  text: (
                    <FormattedMessage
                      id="select.name"
                      defaultMessage="Select {name}"
                      values={{ name: stage.name }}
                    />
                  ),
                  onSelect: (currentKeys) => [
                    ...currentKeys,
                    ...source
                      .filter((e) => e.cropField.cropStage.id == stage.id)
                      .map((e) => e.cropField.id),
                  ],
                }))
              }
              columns={[
                {
                  title: <FormattedMessage id="cropFields.entityName" />,
                  render: (_, e) => formatCropField(e.cropField),
                },
                {
                  title: <FormattedMessage id="cropFields.effectiveArea" />,
                  align: "center",
                  render: (_, e) =>
                    formatUnitValue(e.cropField.effectiveArea, areaUnit),
                },
                {
                  title: (
                    <FormattedMessage
                      id="harvestEstimates.amount"
                      defaultMessage="Estimation Yield"
                    />
                  ),
                  width: 200,
                  render: (_, _u, index) => {
                    return (
                      <Form.Item
                        name={["cropFieldEstimates", index, "amount"]}
                        compact
                        rules={[Rules.gtZero]}
                      >
                        <InputNumber addonAfter={amountSuffix} />
                      </Form.Item>
                    );
                  },
                },
                {
                  title: (
                    <FormattedMessage
                      id="harvestEstimates.estimatedHarvest"
                      defaultMessage="Estimated Harvest"
                    />
                  ),
                  align: "right",
                  width: 300,
                  render: (_, e, index) => (
                    <Form.Item
                      noStyle
                      shouldUpdate={shouldUpdate([
                        "cropFieldEstimates",
                        index,
                        "amount",
                      ])}
                    >
                      {({ getFieldValue }) => {
                        const amount = getFieldValue([
                          "cropFieldEstimates",
                          index,
                          "amount",
                        ]);

                        return formatUnitValueConversion(
                          amount * e.effectiveArea,
                          {
                            unit: estimateUnit,
                            conversionUnit: harvestUnit,
                            conversionFactor,
                          }
                        );
                      }}
                    </Form.Item>
                  ),
                },
              ]}
              tableProps={{
                fixed: true,
                summary: () => {
                  return (
                    <Table.Summary fixed>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          const data = getFieldValue("cropFieldEstimates");

                          const totalEffectiveArea = sumBy(
                            data,
                            (e: any) => e.effectiveArea || 0
                          );
                          const totalHarvest = sumBy(
                            data,
                            (e: any) => e.amount * e.effectiveArea || 0
                          );

                          return (
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={2}>
                                <FormattedMessage id="total" />
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={1} align="right">
                                {formatUnitValue(totalEffectiveArea, areaUnit)}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={2} align="right">
                                {formatUnitValue(
                                  totalHarvest / totalEffectiveArea,
                                  estimateUnit,
                                  areaUnit?.abbr
                                )}
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={3} align="right">
                                {formatUnitValueConversion(totalHarvest, {
                                  unit: estimateUnit,
                                  conversionUnit: harvestUnit,
                                  conversionFactor,
                                })}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          );
                        }}
                      </Form.Item>
                    </Table.Summary>
                  );
                },
              }}
            />
          );
        }}
      </Form.Item>

      <br />

      <BottomPanel
        buttons={[
          BottomPanel.CancelButton({
            route: routes.agro.harvestEstimates.index,
          }),
          BottomPanel.SubmitButton(),
        ]}
      />
    </Form>
  );
}
