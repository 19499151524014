import { FormattedMessage } from "react-intl";
import { SidebarHeader, Space } from "../../../shared";
import { ReactNode, useContext } from "react";
import { ItemForm } from "../../../shared/ListView/ItemForm";
import { List, Button, Checkbox } from "antd";
import { formatEmployeeName, shouldUpdate } from "../../../../lib/formats";
import { Form } from "../../../form";
import { WorkOrderCostCenter } from "../builder/costCenters";
import { WorkOrderContext } from "../WorkOrderContext";

type Assignment = {
  id: string;
  index: number;
  title: ReactNode;
  assigned: boolean;
};

export function AssignSidebarItem({
  entityTitle,
  closeSidebar,
  assignments,
  onAssign,
}: {
  entityTitle: ReactNode;
  closeSidebar: () => void;
  assignments: Assignment[];
  onAssign: (assignments: Assignment[]) => void;
}) {
  return (
    <>
      <SidebarHeader
        back
        title={
          <>
            <FormattedMessage id="workOrders.assign" /> {entityTitle}
          </>
        }
        onClose={closeSidebar}
      />

      <ItemForm
        initialValues={{ assignments }}
        fields={[
          {
            type: "custom",
            render: () => (
              <div key="workers" style={{ paddingBottom: "30px" }}>
                <List
                  size="small"
                  dataSource={assignments}
                  renderItem={({ id, title }, index) => (
                    <Form.Item
                      shouldUpdate={shouldUpdate([
                        "assignments",
                        index,
                        "assigned",
                      ])}
                      noStyle
                    >
                      {({ getFieldValue, setFieldValue }) => {
                        const assigned = getFieldValue([
                          "assignments",
                          index,
                          "assigned",
                        ]);

                        return (
                          <List.Item
                            key={id}
                            style={{ padding: "4px 0" }}
                            className={assigned ? undefined : "row-disabled"}
                          >
                            <List.Item.Meta
                              className={assigned ? undefined : "row-disabled"}
                              title={title}
                            />

                            <Button
                              type="link"
                              onClick={() =>
                                setFieldValue(
                                  ["assignments", index, "assigned"],
                                  !assigned
                                )
                              }
                            >
                              <Space>
                                {assigned ? (
                                  <FormattedMessage
                                    id="workOrders.unassign"
                                    defaultMessage="Unassign"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="workOrder.assign"
                                    defaultMessage="Assign"
                                  />
                                )}
                                <Checkbox checked={assigned} />
                              </Space>
                            </Button>
                          </List.Item>
                        );
                      }}
                    </Form.Item>
                  )}
                />
              </div>
            ),
          },
        ]}
        onCancel={closeSidebar}
        onSave={({ assignments }) => {
          onAssign(assignments);

          closeSidebar();
          return Promise.resolve(null);
        }}
      />
    </>
  );
}

function AssignEmployee({
  costCenter,
  closeSidebar,
}: {
  costCenter: WorkOrderCostCenter;
  closeSidebar: () => void;
}) {
  const { builder } = useContext(WorkOrderContext);

  return (
    <AssignSidebarItem
      closeSidebar={closeSidebar}
      entityTitle={costCenter.cropField?.name || costCenter.profitableName}
      assignments={costCenter.employees.map((e, index) => ({
        id: e.id,
        index,
        title: formatEmployeeName(
          builder.employees.getByEmployeeId(e.employeeId)?.employee
        ),
        assigned: !e.unassigned,
      }))}
      onAssign={(assignments) => {
        const ccIndex = builder.costCenters.indexOf(costCenter);

        assignments.forEach(({ index, assigned }) => {
          builder.form.setFieldValue(
            ["costCenters", ccIndex, "employees", index, "unassigned"],
            !assigned
          );
        });

        builder.employees.initAssignments();

        if (builder.isMachinery) return;

        builder.progress.redistributeCostCentersProgress();
      }}
    />
  );
}

AssignSidebarItem.AssignEmployee = AssignEmployee;

function AssignMachine({
  costCenter,
  closeSidebar,
}: {
  costCenter: WorkOrderCostCenter;
  closeSidebar: () => void;
}) {
  const { builder } = useContext(WorkOrderContext);

  return (
    <AssignSidebarItem
      closeSidebar={closeSidebar}
      entityTitle={costCenter.cropField?.name || costCenter.profitableName}
      assignments={costCenter.machines.map((m, index) => ({
        id: m.id,
        index,
        title: builder.machines.getByMachineId(m.machineId)?.machine.name,
        assigned: !m.unassigned,
      }))}
      onAssign={(assignments) => {
        const ccIndex = builder.costCenters.indexOf(costCenter);

        assignments.forEach(({ index, assigned }) => {
          builder.form.setFieldValue(
            ["costCenters", ccIndex, "machines", index, "unassigned"],
            !assigned
          );
        });

        builder.machines.initAssignments();

        if (!builder.isMachinery) return;

        builder.progress.redistributeCostCentersProgress();
      }}
    />
  );
}

AssignSidebarItem.AssignMachine = AssignMachine;
