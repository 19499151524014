import {
  ExchangeRateQuery,
  ExchangeRateQueryVariables,
  ExchangeRateDocument,
  usePromiseQuery,
} from "../../graphql";

export function useExchangeRate() {
  return usePromiseQuery<ExchangeRateQuery, ExchangeRateQueryVariables>(
    ExchangeRateDocument
  );
}
