import { FormInstance } from "antd/lib/form";
import {
  DestroyableEntityType,
  WithConditionalIDType,
} from "../../../lib/graphql";
import { Form } from "../../form";

export class Builder<T extends WithConditionalIDType, TEntity> {
  protected form: FormInstance<TEntity>;
  protected entity: TEntity;

  constructor(form: FormInstance, entity: TEntity) {
    this.form = form;
    this.entity = entity;

    this.onInit();
  }

  protected get field() {
    return "";
  }

  onInit() {
    if (this.field) {
      (this.entity as any)[this.field] = (this.entity as any)[this.field].sort(
        (a: T, b: T) => this.sorter(a, b)
      );
    }
  }

  sorter(a: T, b: T) {
    return (a.id || "").localeCompare(b.id || "");
  }

  get(filterDestroyed = true): T[] {
    const items = this.form.getFieldValue(this.field) || [];
    return filterDestroyed ? items.filter(Form.undestroyed) : items;
  }

  indexOf(e: T) {
    return this.get(false).findIndex((s) => s == e || (e.id && s.id == e.id));
  }

  getBy(index: number) {
    return this.get(false)[index];
  }

  get hasItems() {
    return this.get().length > 0;
  }

  removeAll() {
    const items = this.get(false);
    items.filter(Form.undestroyed).forEach((item) => {
      this.onRemove(item);
    });

    this.form.setFields([
      {
        name: this.field,
        value: items.filter((i) => i.id),
      },
    ]);
  }

  onRemove(item: DestroyableEntityType<T>) {
    item._destroy = true;
  }

  update(index: number, newValues: T) {
    const item = this.get(false)[index];
    this.form.setFields([
      {
        name: [this.field, index],
        value: { ...item, ...newValues },
      },
    ]);
  }
}
