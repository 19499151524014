import { Key, useContext, useState } from "react";

import { WorkOrderContext } from "./WorkOrderContext";
import { Form, InputNumber, RuleBuilder, Rules } from "../../form";
import { HintTooltip, Space, TableInput } from "../../shared";
import { useCurrentUser, useMachineOptions } from "../../../lib/hooks";
import {
  CropFieldFragment,
  MachineCategory,
  WorkOrderMachineFragment,
} from "../../../lib/graphql";
import {
  formatCropField,
  formatCropFieldArea,
  formatCropFieldOption,
  formatMachine,
  formatMachineCapacity,
  formatPlaceholder,
  formatTags,
  formatUnitValue,
  shouldUpdate,
  translate,
  unaccentSearch,
} from "../../../lib/formats";
import { FormattedMessage, useIntl } from "react-intl";
import { Tag } from "antd";
import { filterArray, filterColumns } from "../../../lib/utils";
import { WorkOrderMachine } from "./builder/machines";
import { useWaterUsage } from "./WorkOrderSpraying";
import { cropFieldToEntity } from "./WorkOrderCropFields";
import { MachineWorkHours } from "./WorkOrderMachines";

export function WorkOrderIrrigation() {
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const localityId = [workOrder.locality.id];
  const [expandedRows, setExpandedRows] = useState<readonly Key[]>(
    workOrder.machines.map((m) => m.machine.id)
  );
  const { recalculate } = useWaterUsage();

  const h = intl.formatMessage({ id: "workUnits.abbr.hour" });
  const allCropFields = intl.formatMessage({ id: "cropFields.all" });

  const fieldHoursLabel = (
    <Space>
      {builder.isDataIntake ? (
        <FormattedMessage
          id="workOrders.irrigation.irrigatedHours"
          defaultMessage="Hours Irrigated"
        />
      ) : (
        <FormattedMessage
          id="workOrders.irrigation.hoursToIrrigate"
          defaultMessage="Hours to Irrigate"
        />
      )}
      <HintTooltip
        title={
          builder.isDataIntake ? (
            <FormattedMessage
              id="workOrders.irrigation.irrigatedHours.hint"
              defaultMessage="Enter the actual number of hours each field was irrigated"
            />
          ) : (
            <FormattedMessage
              id="workOrders.irrigation.hoursToIrrigate.hint"
              defaultMessage="Enter the number of hours the irrigation equipment should operate"
            />
          )
        }
      />
    </Space>
  );

  return (
    <TableInput
      name="machines"
      rules={[Rules.required]}
      dataSource={workOrder.machines}
      rowKey={(m) => m.machine.id}
      disabled={builder.isReadonly}
      addIndexColumn
      tableSelectProps={{
        mode: "multiple",
        showGroups: true,
        optionsHook: useMachineOptions,
        optionsHookParams: {
          variables: {
            filter: { localityId, category: [MachineCategory.Irrigation] },
          },
        },
        placeholder: formatPlaceholder({
          id: "machines.categories.irrigation",
        }),
        entityById: (_, { machine }) => {
          if (machine) {
            return {
              id: "",
              machine,
              operator: null,
              implement: null,
              workHours: 0,
            };
          }
        },
        afterAdd: (selected: WorkOrderMachineFragment[]) => {
          setExpandedRows(selected.map((m) => m.machine.id));
        },
      }}
      addSorter={(a, b) => builder.machines.sorter(a, b)}
      columns={filterColumns<WorkOrderMachine>([
        {
          title: translate("machines.categories.irrigation"),
          dataIndex: ["machine", "id"],
          render: (_, m) => (
            <Space>
              {formatMachine(m.machine)}
              <Tag color="green">{formatMachineCapacity(m.machine)}</Tag>
            </Space>
          ),
        },
        {
          title: (
            <Space>
              {builder.isDataIntake ? (
                <FormattedMessage
                  id="workOrders.irrigation.operatedHours"
                  defaultMessage="Hours Operated"
                />
              ) : (
                <FormattedMessage
                  id="workOrders.irrigation.scheduledHours"
                  defaultMessage="Scheduled Hours"
                />
              )}
              <HintTooltip
                title={
                  builder.isDataIntake ? (
                    <FormattedMessage
                      id="workOrders.irrigation.operatedHours.hint"
                      defaultMessage="Enter the number of hours the irrigation equipment was operated"
                    />
                  ) : (
                    <FormattedMessage
                      id="workOrders.irrigation.scheduledHours.hint"
                      defaultMessage="Enter the number of hours the irrigation equipment should operate"
                    />
                  )
                }
              />
            </Space>
          ),
          dataIndex: "workHours",
          width: "12rem",
          align: "center",
          render: (_, m, index) => {
            return builder.isReadonly ? (
              formatUnitValue(m.workHours, { abbr: h })
            ) : (
              <MachineWorkHours
                name={["machines", index]}
                hourAbbr={h}
                onChange={recalculate}
              />
            );
          },
        },
        {
          title: <FormattedMessage id="workOrders.spraying.waterUsed" />,
          width: "8rem",
          align: "center",
          render: (_, m, index) => (
            <Form.Item
              noStyle
              shouldUpdate={shouldUpdate(["waterUsage", "waterAmount"])}
            >
              {() =>
                formatUnitValue(
                  builder.machines.getCapacityUsed(index),
                  m.machine.capacityUnit
                )
              }
            </Form.Item>
          ),
        },
      ])}
      tableProps={{
        bordered: true,
        expandable: {
          rowExpandable: (m) => Form.undestroyed(m),
          expandedRowKeys: expandedRows,
          onExpandedRowsChange: setExpandedRows,
          expandedRowRender: (m, mIndex) => {
            const cropFields = m.machine.cropFieldMachines
              .filter(
                ({ cropField }) =>
                  workOrder.locality.id === cropField.locality.id
              )
              .map((cfm) => cfm.cropField);

            const options = [
              {
                key: "all",
                label: allCropFields,
                children: formatTags([allCropFields], "gold"),
                searchFields: [allCropFields],
                cropFields,
              },
              ...cropFields.map((cropField) => ({
                key: cropField.id,
                label: cropField.name,
                cropFields: [cropField],
                searchFields: [
                  cropField.name,
                  cropField.cropStage.name,
                  cropField.tags,
                  cropField.cropFieldVarieties.map((v) => v.cropVariety.name),
                ].flat(),
                children: formatCropFieldOption({ field: cropField }),
              })),
            ];

            return (
              <TableInput
                name="costCenters"
                dataSource={workOrder.costCenters}
                rules={[Rules.required]}
                tableProps={{
                  size: "small",
                  rowClassName: (cc) =>
                    builder.machines.getCropFieldMachine(m, cc) ? "" : "hide",
                }}
                tableSelectProps={{
                  mode: "multiple",
                  options,
                  filterOption: (input, option) => {
                    const searchFields =
                      options.find((o) => o.key === option?.key)
                        ?.searchFields || [];

                    return searchFields.some((f) => unaccentSearch(input, f));
                  },
                  placeholder: <FormattedMessage id="select.cropField" />,
                  entityById: (_, { cropFields }) => {
                    return cropFields.map((cf: CropFieldFragment) => ({
                      ...cropFieldToEntity(cf, builder),
                      machines: [],
                    }));
                  },
                  afterAdd: () => {
                    builder.machines.init();
                    recalculate();
                  },
                }}
                addIndexColumn
                allowBulkRemove
                disabled={builder.isReadonly}
                rowKey={(f) => f.costCenter.id + f.profitableId || f.id}
                onRemove={(cc) => {
                  builder.costCenters.onRemove(cc);
                  recalculate();
                }}
                bulkUpdateFields={[
                  {
                    label: fieldHoursLabel,
                    type: "number",
                    name: "machineHours",
                    numberProps: { addonAfter: h },
                  },
                ]}
                onBulkUpdate={(values) => {
                  builder.machines.onBulkUpdate(m, values.machineHours);
                  recalculate();
                }}
                columns={[
                  {
                    title: <FormattedMessage id="cropFields.entityName" />,
                    render: (_, cc) => (
                      <>
                        {formatCropField(cc.cropField)}
                        <Tag color="green">
                          {formatMachineCapacity({
                            ...m.machine,
                            capacityValue:
                              builder.machines.getCropFieldMachine(m, cc)
                                ?.capacityValue || m.machine.capacityValue,
                          })}
                        </Tag>
                      </>
                    ),
                  },
                  {
                    title: <FormattedMessage id="cropFields.totalArea" />,
                    width: "12rem",
                    align: "center",
                    render: (_, cfm) =>
                      formatCropFieldArea(
                        cfm.cropField,
                        workOrder.activity.useTotalArea,
                        currentTenant.features.customCropVarieties
                      ),
                  },
                  {
                    title: fieldHoursLabel,
                    width: "9rem",
                    align: "center",
                    render: (_, cc) => {
                      const field = builder.machines.getCostCenterMachineField(
                        cc,
                        m,
                        "hours"
                      );

                      if (builder.isReadonly) {
                        return formatUnitValue(
                          builder.form.getFieldValue(field),
                          { abbr: h }
                        );
                      }

                      return (
                        <Form.Item
                          compact
                          name={field}
                          rules={[
                            Rules.required,
                            Rules.onlyNumbers,
                            RuleBuilder.gtEq(0),
                          ]}
                        >
                          <InputNumber
                            step={1}
                            min={0}
                            max={24}
                            addonAfter={h}
                            onChange={recalculate}
                          />
                        </Form.Item>
                      );
                    },
                  },
                  {
                    title: (
                      <FormattedMessage id="workOrders.spraying.waterUsed" />
                    ),
                    width: "8rem",
                    align: "center",
                    render: (_, cc, ccIndex) => {
                      return (
                        <Form.Item
                          noStyle
                          shouldUpdate={shouldUpdate(
                            ...filterArray(
                              ["machines", mIndex, "workHours"],
                              builder.machines.getCostCenterMachineField(
                                cc,
                                m,
                                "hours"
                              )
                            )
                          )}
                        >
                          {() =>
                            formatUnitValue(
                              builder.machines.getCapacityUsed(mIndex, ccIndex),
                              m.machine.capacityUnit
                            )
                          }
                        </Form.Item>
                      );
                    },
                  },
                ]}
              />
            );
          },
        },
      }}
    />
  );
}
