import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import {
  useCurrentUser,
  useItemSidebarContext,
  useMediaXs,
} from "../../../lib/hooks";
import { useRouter } from "next/router";
import {
  formatAssetPath,
  formatPdfPath,
  PdfModule,
} from "../../../lib/formats";
import { ExportConfigs, ExportType } from "../../../lib/exports";
import { Dropdown } from "../Dropdown";
import {
  ActionButton,
  AgroprimeDownloadButton,
  ExactusDownloadButton,
} from "../ActionButton";
import { cloneDeep } from "lodash";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { SetCurrentAction } from "../ItemSidebar";

export function exportUrl(config: any, fileFormat: string): string {
  return formatAssetPath(
    `/exports/download.${fileFormat}?params=${JSON.stringify(config)}`
  ) as string;
}

interface ExportParams {
  filter: any;
  sort: any;
  export: ExportType;
  exactusName?: string;
  agroprimeName?: string;
  pdf?: boolean;
}

interface ExportButtonProps {
  exportType: ExportType;
  params?: any;
  pdfModule?: PdfModule;
  items?: (
    params: ExportParams,
    setCurrentAction: SetCurrentAction
  ) => ItemType[];
}

export function ExportButton({
  exportType,
  params = {},
  pdfModule,
  items,
}: ExportButtonProps) {
  const { currentLocalityIds, currentTenant } = useCurrentUser();
  const { setCurrentAction } = useItemSidebarContext();
  const router = useRouter();
  const isXs = useMediaXs();

  const exportParams = { filter: {}, ...cloneDeep(params) } as ExportParams;

  const exactusName = exportParams.exactusName;
  if (exactusName) delete exportParams.exactusName;

  const agroprimeName = exportParams.agroprimeName;
  if (agroprimeName) delete exportParams.agroprimeName;

  const pdf = exportParams.pdf;
  if (pdf) delete exportParams.pdf;

  const exportConfig: any = ExportConfigs[exportType] || {};

  exportParams.export = exportConfig.export || exportType;

  if (exportConfig.filter)
    exportParams.filter = { ...exportParams.filter, ...exportConfig.filter };

  // if list were filtered - use filters from query
  // otherwise include current locality if necessary
  if (router.query.filter && router.query.filter.toString() !== "") {
    const queryFilter = JSON.parse(router.query.filter.toString());
    exportParams.filter = { ...exportParams.filter, ...queryFilter };
  } else if (exportConfig.currentLocality) {
    exportParams.filter.localityId = currentLocalityIds;
  }

  if (Object.keys(exportParams.filter).length > 0)
    exportParams.filter = JSON.stringify(exportParams.filter);
  else delete exportParams.filter;

  if (exportConfig.sort && Object.keys(exportConfig.sort).length > 0)
    exportParams.sort = JSON.stringify(exportConfig.sort);

  return (
    <Dropdown
      menu={{
        triggerSubMenuAction: "click",
        items: [
          ActionButton.DownloadExcel({
            downloadLink: exportUrl(exportParams, "xlsx"),
          }),
          ActionButton.DownloadCSV({
            downloadLink: exportUrl(exportParams, "csv"),
          }),
          pdfModule || pdf
            ? ActionButton.DownloadPDF({
                downloadLink: pdf
                  ? exportUrl(exportParams, "pdf")
                  : formatPdfPath(pdfModule, params.id),
              })
            : null,
          currentTenant.features.exactus && exactusName
            ? ExactusDownloadButton(exactusName, exportParams)
            : null,
          currentTenant.features.agroprime && agroprimeName
            ? AgroprimeDownloadButton(agroprimeName, exportParams)
            : null,
          ...(items ? items(exportParams, setCurrentAction) : []),
        ],
      }}
    >
      <Button data-action="export-btn">
        <ExportOutlined />{" "}
        {!isXs && <FormattedMessage id="export" defaultMessage="Export" />}
      </Button>
    </Dropdown>
  );
}
