import {
  ApolloCache,
  DocumentNode,
  OperationVariables,
  useApolloClient,
} from "@apollo/client";
import { useCallback } from "react";

export const omitTypename = <T>(vars: T): Omit<T, "__typename"> => {
  return JSON.parse(JSON.stringify(vars), (key: string, value: T[keyof T]) =>
    key === "__typename" ? undefined : value
  );
};

/** Clears graphql cache by name. TODO: replace with cache.evict built-in method */
export const evictCache =
  (...names: string[]) =>
  (cache: ApolloCache<any>) => {
    names.forEach((name) => cache.evict({ id: "ROOT_QUERY", fieldName: name }));
  };

export function usePromiseQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(query: DocumentNode) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TData, TVariables>({
        query: query,
        variables: variables,
      }),
    [client, query]
  );
}
