import { Col, List, Row, Typography } from "antd";
import { sumBy } from "lodash";
import { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  formatCropFieldName,
  formatCostCenterName,
  formatUnitValue,
  formatEmployee,
  formatMachine,
} from "../../../../lib/formats";
import { Form, InputNumber, Rules, SelectField } from "../../../form";
import {
  ItemSidebar,
  SidebarHeader,
  useItemSidebarContext,
} from "../../../shared";
import { ItemForm } from "../../../shared/ListView/ItemForm";
import { WorkOrderContext } from "../WorkOrderContext";
import { MetricShortFragment } from "../../../../lib/graphql";
import { AssignSidebarItem } from "./AssignSidebarItem";

const sortOptions = [
  {
    key: "nameAsc",
    label: (
      <FormattedMessage
        id="sorting.alpha.asc"
        defaultMessage="Sort: Alphabetically (A-Z)"
      />
    ),
  },
  {
    key: "nameDesc",
    label: (
      <FormattedMessage
        id="sorting.alpha.desc"
        defaultMessage="Sort: Alphabetically (Z-A)"
      />
    ),
  },
  {
    key: "internalIdAsc",
    label: (
      <FormattedMessage
        id="sorting.internalId.asc"
        defaultMessage="Sort: Internal ID (A-Z)"
      />
    ),
  },
  {
    key: "internalIdDesc",
    label: (
      <FormattedMessage
        id="sorting.internalId.desc"
        defaultMessage="Sort: Internal ID (Z-A)"
      />
    ),
  },
];

export function CostCenterSidebar() {
  const intl = useIntl();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { currentState } = useItemSidebarContext();
  const [sorting, setSorting] = useState("nameAsc");

  return (
    <ItemSidebar
      item={currentState}
      sidebarActions={{
        assign: ({ item, closeSidebar }) => {
          const costCenter = builder.costCenters.getBy(item.ccIndex);
          return builder.isMachinery ? (
            <AssignSidebarItem.AssignMachine
              costCenter={costCenter}
              closeSidebar={closeSidebar}
            />
          ) : (
            <AssignSidebarItem.AssignEmployee
              costCenter={costCenter}
              closeSidebar={closeSidebar}
            />
          );
        },
        distributeMetric: ({ item, closeSidebar }) => {
          const costCenter = builder.costCenters.getBy(item.ccIndex);
          const metric: MetricShortFragment | null = item.metric;

          const source = builder.isMachinery
            ? builder.machines.progress
                .getAssignedWorkers(costCenter)
                .sort(builder.machines.sorter)
                .map((m) => ({
                  id: m.id,
                  title: formatMachine(m.machine),
                  name: metric
                    ? builder.machines.metrics
                        .metricValueFieldName(
                          item.ccIndex,
                          metric.id,
                          m.machine.id
                        )
                        .toSpliced(0, 2)
                    : [
                        "machines",
                        builder.machines.getCostCenterMachineIndex(
                          costCenter,
                          m
                        ),
                        "progress",
                      ],
                }))
            : builder.employees.progress
                .getAssignedWorkers(costCenter)
                .sort(
                  sorting.includes("name")
                    ? builder.employees.nameSorter
                    : builder.employees.internalIdSorter
                )
                .map((e) => ({
                  id: e.id,
                  title: formatEmployee(e.employee),
                  name: metric
                    ? builder.employees.metrics
                        .metricValueFieldName(
                          item.ccIndex,
                          metric.id,
                          e.employee.id
                        )
                        .toSpliced(0, 2)
                    : [
                        "employees",
                        builder.employees.getCostCenterEmployeeIndex(
                          costCenter,
                          e
                        ),
                        "progress",
                      ],
                }));

          if (sorting.includes("Desc")) source.reverse();

          return (
            <>
              <SidebarHeader
                back
                title={
                  <FormattedMessage
                    id="workOrders.progressCC"
                    defaultMessage="Edit {name} {metric}"
                    values={{
                      name: costCenter.cropField
                        ? formatCropFieldName(costCenter.cropField)
                        : formatCostCenterName(costCenter.costCenter),
                      metric: metric
                        ? metric.name
                        : intl.formatMessage({ id: "progress" }),
                    }}
                  />
                }
                onClose={closeSidebar}
              />
              <ItemForm
                initialValues={costCenter}
                fields={[
                  {
                    type: "custom",
                    render: () => (
                      <div key="metric" style={{ paddingBottom: "30px" }}>
                        <List
                          size="small"
                          dataSource={source}
                          header={
                            <div style={{ textAlign: "right" }}>
                              <SelectField
                                style={{ width: "60%", textAlign: "left" }}
                                allowClear={false}
                                showSearch={false}
                                defaultValue={sorting}
                                onChange={setSorting}
                                options={sortOptions}
                              />
                            </div>
                          }
                          renderItem={({ id, title, name }) => (
                            <List.Item key={id} style={{ padding: "4px 0" }}>
                              <List.Item.Meta title={title} />
                              <Form.Item
                                compact
                                name={name}
                                rules={[Rules.gtEqZero]}
                              >
                                <InputNumber
                                  step={0.1}
                                  min={0}
                                  addonAfter={
                                    metric
                                      ? metric.unit.abbr
                                      : workOrder.activity.progressUnit.abbr
                                  }
                                  style={{ width: 140 }}
                                />
                              </Form.Item>
                            </List.Item>
                          )}
                        />

                        <Row
                          style={{
                            position: "absolute",
                            bottom: "52px",
                            left: 0,
                            right: 0,
                            padding: "5px 24px",
                            background: "#fff",
                            height: "32px",
                            borderTop: "1px solid #e8e8e8",
                          }}
                        >
                          <Col flex={1}>
                            {costCenter.dayGoal && !metric ? (
                              <Typography.Text type="secondary" strong>
                                <FormattedMessage id="workOrders.dayGoal" />:{" "}
                                {formatUnitValue(
                                  costCenter.dayGoal,
                                  workOrder.activity.progressUnit
                                )}
                              </Typography.Text>
                            ) : undefined}
                          </Col>
                          <Col flex={1}>
                            <Typography.Text strong>
                              <FormattedMessage id="total" />:{" "}
                              <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue, getFieldsValue }) =>
                                  metric
                                    ? formatUnitValue(
                                        builder.isMachinery
                                          ? builder.machines.metrics.getTotalMetric(
                                              metric.id,
                                              getFieldsValue(true)
                                            )
                                          : builder.employees.metrics.getTotalMetric(
                                              metric.id,
                                              getFieldsValue(true)
                                            ),
                                        metric.unit
                                      )
                                    : formatUnitValue(
                                        sumBy(
                                          getFieldValue(
                                            builder.isMachinery
                                              ? "machines"
                                              : "employees"
                                          ),
                                          "progress"
                                        ),
                                        workOrder.activity.progressUnit
                                      )
                                }
                              </Form.Item>
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                    ),
                  },
                ]}
                onCancel={closeSidebar}
                onSave={(value) => {
                  builder.form.setFieldValue(
                    ["costCenters", item.ccIndex],
                    value
                  );

                  if (metric) {
                    builder.costCenters.metrics.recalculate(
                      metric.id,
                      item.ccIndex
                    );
                  } else {
                    builder.progress.recalculateCostCenterProgress(
                      item.ccIndex
                    );
                  }

                  closeSidebar();
                  return Promise.resolve(null);
                }}
              />
            </>
          );
        },
      }}
    />
  );
}
