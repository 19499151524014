import React, { useState, ReactNode, useContext } from "react";
import { merge } from "lodash";
import { FilterProps } from "./FilterPanel";
import {
  BulkUpdateMutationVariables,
  QueryResult,
  QueryVariables,
  WithIDType,
  QueryHookOptions,
  evictCache,
  RwdPermissionsInput,
} from "../../../lib/graphql";
import { useRouterFilter } from "../../../lib/hooks";
import {
  ItemSidebarProps,
  ItemSidebarContext,
  ItemSidebar,
} from "../ItemSidebar";
import { defaultPagination } from "../../../lib/formats";
import { queryFilter, FilterSidebar } from "./FilterSidebar";
import { DrawerProps } from "antd/lib/drawer";
import Table, { TableProps } from "../Table";
import { BulkDestroy, BulkDestroyProps } from "./BulkDestroy";
import { BulkDiscard, BulkDiscardProps } from "./BulkDiscard";
import { FormattedMessage } from "react-intl";
import { BulkUpdate, BulkUpdateProps } from "./BulkUpdate";
import { ColumnGroupType, ColumnType } from "antd/lib/table/interface";
import { filterFalse } from "../../../lib/utils";

type FalsyColumnsType<T> = (
  | ColumnGroupType<T>
  | ColumnType<T>
  | undefined
  | null
  | false
)[];

interface ListViewProps<
  T,
  TQuery,
  TFilter,
  TSort,
  THookOptions extends QueryHookOptions<TQuery, QueryVariables<TFilter, TSort>>,
  TFormValues,
  TBulkUpdateMutation,
  TBulkUpdateMutationVariables
> {
  query(options?: THookOptions): QueryResult<T>;
  queryOptions?: THookOptions;
  transform?(items: T[]): T[];
  columns: FalsyColumnsType<T> | ((items: T[]) => FalsyColumnsType<T>);
  filters: FilterProps<TFilter>[];
  sidebarActions?: Record<string, (props: ItemSidebarProps<T>) => ReactNode>;
  disabledRecord?(record: T): boolean;
  disablePagination?: boolean;
  tableProps?: Omit<
    TableProps<T>,
    "store" | "checkboxPropsCache" | "setCheckboxPropsCache"
  > & { fixedHeader?: boolean };
  hiddenColumns?(items: T[]): string[];
  drawerProps?: DrawerProps;
  pageSize?: number;
  entityName?: string;
  pluralEntityName?: string;
  permissions?: Pick<RwdPermissionsInput, "write" | "delete"> | null;
  labelKey?: keyof T | ((item: T) => ReactNode);
  bulkOptions?: Partial<Pick<BulkDestroyProps<T>, "destroyableType">> &
    Partial<Pick<BulkDiscardProps<T>, "discardableType">> & {
      resetQueryCache?: string[];
    } & {
      updateFields?: BulkUpdateProps<
        T,
        TFormValues,
        TBulkUpdateMutation,
        TBulkUpdateMutationVariables
      >["fields"];
      updateMutationHook?: BulkUpdateProps<
        T,
        TFormValues,
        TBulkUpdateMutation,
        BulkUpdateMutationVariables<TFormValues>
      >["mutation"];
    };
}

export function ListView<
  T extends object,
  TQuery,
  TFilter,
  TSort,
  THookOptions extends QueryHookOptions,
  TFormValues,
  TBulkUpdateMutation,
  TBulkUpdateMutationVariables
>(
  props: ListViewProps<
    T,
    TQuery,
    TFilter,
    TSort,
    THookOptions,
    TFormValues,
    TBulkUpdateMutation,
    TBulkUpdateMutationVariables
  >
) {
  const { router, filter } = useRouterFilter();
  const page = parseInt(router.query.page as any) || 1;
  const pageSize = parseInt(router.query.pageSize as any) || props.pageSize;
  let sort: any = undefined;

  const field = router.query.field;
  if (field) {
    sort = {
      [field.toString()]: router.query.order === "descend" ? "desc" : "asc",
    };
  }

  const [currentItem, setCurrentItem] = useState<T>({} as T);
  const { setCurrentAction } = useContext(ItemSidebarContext);

  // if page was filtered - removing filter values from initial query options if initial values for fields are set
  // it mutates queryOptions, but it should be fine since it changes filtered values anyway
  if (typeof router.query.filter !== "undefined")
    props.filters.forEach((f) => {
      if (f.initialValue)
        delete props.queryOptions?.variables?.filter?.[f.name];
    });

  const { loading, items, totalCount } = props.query(
    merge({}, props.queryOptions, {
      errorPolicy: "all",
      variables: {
        page,
        pageSize,
        sort,
        filter: filter && queryFilter(filter, props.filters).query,
      },
    })
  );

  const hiddenColumns =
    props.hiddenColumns && items && props.hiddenColumns(items);

  let columns = filterFalse(
    Array.isArray(props.columns) ? props.columns : props.columns(items || [])
  );

  columns = hiddenColumns
    ? columns.filter((c) => !hiddenColumns.includes(String(c.key)))
    : columns;

  if (props.tableProps?.fixedHeader) {
    columns = columns.map((c) => ({ ...c, width: c.width || "10rem" }));
  }

  columns = columns.map((c: ColumnType<T>) =>
    c.dataIndex === "name" ? { title: props?.entityName, ...c } : c
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const selectedItems = (items as WithIDType[])?.filter((i) =>
    selectedRowKeys.includes(i.id)
  );

  const allowBulkDestroy =
    props.permissions?.delete && props.bulkOptions?.destroyableType;
  const allowBulkDiscard =
    props.permissions?.delete && props.bulkOptions?.discardableType;
  const allowBulkRestore = filter?.showAll && allowBulkDiscard;
  const allowBulkUpdate =
    props.permissions?.write && props.bulkOptions?.updateFields;

  const customSelections = props.tableProps?.selections || [];

  if (allowBulkDiscard) {
    customSelections.push({
      key: "bulkDiscard",
      text: <FormattedMessage id="discard" defaultMessage="Disable" />,
      onSelect: () => {
        setCurrentAction("bulkDiscard");
      },
    });
  }

  if (allowBulkRestore) {
    customSelections.push({
      key: "bulkRestore",
      text: <FormattedMessage id="undiscard" defaultMessage="Enable" />,
      onSelect: () => {
        setCurrentAction("bulkRestore");
      },
    });
  }

  return (
    <>
      <div className="list">
        <Table
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          columns={columns}
          dataSource={props.transform && items ? props.transform(items) : items}
          rowKey="id"
          rowClassName={(record) =>
            props.disabledRecord && props.disabledRecord(record)
              ? "row-disabled"
              : ""
          }
          loading={loading}
          onChange={(
            { current, defaultCurrent, pageSize, defaultPageSize },
            {},
            sorter
          ) => {
            const { field, order } = Array.isArray(sorter) ? sorter[0] : sorter;
            const query: any = {
              ...router.query,
              page: current,
              pageSize,
              field,
              order,
            };
            // set only valuable query params
            if (current === defaultCurrent) delete query.page;
            if (pageSize === defaultPageSize) delete query.pageSize;
            if (!field || !order) delete query.field;
            if (!order) delete query.order;

            router.push({ pathname: router.pathname, query });
          }}
          pagination={
            !props.disablePagination &&
            defaultPagination({ page, pageSize, totalCount })
          }
          onRow={(record) => {
            return {
              onClick: () => {
                setCurrentItem(record);
                setCurrentAction("details");
              },
            };
          }}
          {...props.tableProps}
          onBulkRemove={
            allowBulkDestroy
              ? () => {
                  setCurrentAction("bulkDestroy");
                }
              : undefined
          }
          onBulkUpdate={
            allowBulkUpdate
              ? () => {
                  setCurrentAction("bulkUpdate");
                }
              : undefined
          }
          locale={{
            ...props.tableProps?.locale,
            emptyText: loading
              ? () => <div style={{ minHeight: "75vh" }}></div>
              : props.tableProps?.locale?.emptyText,
          }}
          selections={customSelections}
        />
      </div>

      <ItemSidebar
        item={currentItem}
        sidebarActions={{
          ...props.sidebarActions,
          filters: ({ closeSidebar }) => (
            <FilterSidebar
              entityName={props.entityName}
              filters={props.filters}
              onClose={closeSidebar}
            />
          ),
          bulkDestroy: ({ closeSidebar }) =>
            props.permissions?.delete &&
            props.bulkOptions?.destroyableType && (
              <BulkDestroy
                entityName={props.entityName || ""}
                pluralEntityName={props.pluralEntityName || ""}
                labelKey={props.labelKey as any} // there is a conflict between object and DiscardableType
                items={selectedItems}
                destroyableType={props.bulkOptions.destroyableType}
                mutationOptions={
                  props.bulkOptions.resetQueryCache
                    ? {
                        update: evictCache(
                          ...props.bulkOptions.resetQueryCache
                        ),
                      }
                    : undefined
                }
                onCancel={closeSidebar}
                onSuccess={() => {
                  closeSidebar();
                  setSelectedRowKeys([]);
                }}
              />
            ),
          bulkDiscard: ({ closeSidebar }) =>
            props.permissions?.delete &&
            props.bulkOptions?.discardableType && (
              <BulkDiscard
                entityName={props.entityName || ""}
                pluralEntityName={props.pluralEntityName || ""}
                labelKey={props.labelKey as any} // there is a conflict between object and DiscardableType
                items={selectedItems}
                discardableType={props.bulkOptions.discardableType}
                mutationOptions={
                  props.bulkOptions.resetQueryCache
                    ? {
                        update: evictCache(
                          ...props.bulkOptions.resetQueryCache
                        ),
                      }
                    : undefined
                }
                onCancel={closeSidebar}
                onSuccess={() => {
                  closeSidebar();
                  setSelectedRowKeys([]);
                }}
              />
            ),
          bulkRestore: ({ closeSidebar }) =>
            props.permissions?.delete &&
            props.bulkOptions?.discardableType && (
              <BulkDiscard
                disable={false}
                entityName={props.entityName || ""}
                pluralEntityName={props.pluralEntityName || ""}
                labelKey={props.labelKey as any} // there is a conflict between object and DiscardableType
                items={selectedItems}
                discardableType={props.bulkOptions.discardableType}
                mutationOptions={
                  props.bulkOptions.resetQueryCache
                    ? {
                        update: evictCache(
                          ...props.bulkOptions.resetQueryCache
                        ),
                      }
                    : undefined
                }
                onCancel={closeSidebar}
                onSuccess={() => {
                  closeSidebar();
                  setSelectedRowKeys([]);
                }}
              />
            ),
          bulkUpdate: ({ closeSidebar }) =>
            props.permissions?.write &&
            props.bulkOptions?.updateFields &&
            props.bulkOptions?.updateMutationHook && (
              <BulkUpdate
                entityName={props.entityName || ""}
                pluralEntityName={props.pluralEntityName || ""}
                ids={selectedItems.map((i) => i.id)}
                onCancel={closeSidebar}
                onSuccess={() => {
                  closeSidebar();
                  setSelectedRowKeys([]);
                }}
                fields={props.bulkOptions.updateFields}
                mutation={props.bulkOptions.updateMutationHook}
                mutationOptions={
                  props.bulkOptions.resetQueryCache
                    ? {
                        update: evictCache(
                          ...props.bulkOptions.resetQueryCache
                        ),
                      }
                    : undefined
                }
              />
            ),
        }}
        afterClose={() => setCurrentItem({} as T)}
        drawerProps={props.drawerProps}
      />
    </>
  );
}

export * from "./DestroyItem";
export * from "./DiscardItem";
export * from "./LockItem";
export * from "./EditForm";
export * from "./NewForm";
export * from "./ItemDetails";
export * from "./ListMenu";
