import { sum, sumBy } from "lodash";
import { WorkOrderBuilder } from ".";
import {
  MachineCategory,
  UnitFragment,
  WorkOrderCostCenterMachineFragment,
  WorkOrderMachineFragment,
  DestroyableEntityType,
} from "../../../../lib/graphql";
import { MachineCalibrations } from "../../../../lib/hooks";
import { WorkOrderBaseBuilder } from "./base";
import { MachineProgress } from "./progress/machineProgress";
import { MachineMetricBuilder } from "./metrics/machineMetrics";
import { getUnitsConversionFactor } from "../../../../lib/formats";
import { WorkOrderCostCenter } from "./costCenters";

export interface WorkOrderMachine
  extends DestroyableEntityType<WorkOrderMachineFragment> {
  totalProgress?: number;
  assignedCount?: number;
}

export class WorkOrderMachineBuilder extends WorkOrderBaseBuilder<
  DestroyableEntityType<WorkOrderMachine>
> {
  progress: MachineProgress;
  metrics: MachineMetricBuilder;

  constructor(builder: WorkOrderBuilder) {
    super(builder);

    this.progress = new MachineProgress(builder);
    this.metrics = new MachineMetricBuilder(builder);
  }

  protected get field() {
    return "machines";
  }

  sorter(a: WorkOrderMachine, b: WorkOrderMachine) {
    return a.machine.name.localeCompare(b.machine.name);
  }

  getTotalHours() {
    const machines = this.get();
    return sumBy(machines, (e) => e.workHours);
  }

  getTotalProgress() {
    const machines = this.get();
    return sumBy(machines, (m) => m.totalProgress || 0);
  }

  getCostCenterMachine(
    costCenter: WorkOrderCostCenter,
    machine: WorkOrderMachine
  ) {
    return costCenter.machines.find(
      (ccm) => ccm.machineId === machine.machine.id
    );
  }

  getByMachineId(machineId: string) {
    return this.get().find((m) => m.machine.id === machineId);
  }

  init() {
    this.metrics.initMetricFields();

    if (!this.builder.isMachinery && !this.builder.isIrrigation) return;

    this.progress.initTotalProgress(this.get());

    this.initAssignments();
  }

  initAssignments() {
    const machines = this.get(false);
    const costCenters = this.getCostCenters();

    machines.forEach((m, index) => {
      const count = costCenters.filter((cc) =>
        this.builder.machines.progress.isAssigned(cc, m)
      ).length;

      this.form.setFieldValue(["machines", index, "assignedCount"], count);
    });
  }

  getCostCenterMachineIndex(
    costCenter: WorkOrderCostCenter,
    machine: WorkOrderMachine
  ): number {
    return costCenter.machines.findIndex(
      (ccm) => ccm.machineId === machine.machine.id
    );
  }

  useOdometer(machine?: WorkOrderMachine) {
    const machines = machine ? [machine] : this.get();

    return machines.some(
      (m) =>
        m.machine.odometerUnit &&
        m.machine.odometerValue &&
        m.machine.kind.category !== MachineCategory.Portables
    );
  }

  useInstructions(machine?: WorkOrderMachine) {
    const machines = machine ? [machine] : this.get();

    return machines.some((m) =>
      m.machine.calibrations?.some((c) => ["speed", "gear", "rpm"].includes(c))
    );
  }

  useApplicator(machine?: WorkOrderMachine) {
    const machines = machine ? [machine] : this.get();

    return machines.some((m) =>
      m.machine.calibrations?.some((c) => ["pressure", "nozzle"].includes(c))
    );
  }

  onRemove(machine: WorkOrderMachine) {
    super.onRemove(machine);

    const costCenters = this.getCostCenters();
    costCenters.forEach(({ machines }) => {
      const index = machines.findIndex(
        (m) => m.machineId === machine.machine.id
      );
      if (index >= 0) machines.splice(index, 1);
    });

    this.progress.deboucedOnWorkerCountChanged();
  }

  get useCostCenterProgress() {
    if (!this.builder.isMachinery) return false;

    return this.get().length === 0;
  }

  showCalibration(
    machine: WorkOrderMachine,
    calibration: (typeof MachineCalibrations)[number]
  ) {
    return (
      machine[calibration] ||
      machine.machine.calibrations?.some((c) => c === calibration)
    );
  }

  getCostCenterMachineField(
    costCenter: WorkOrderCostCenter,
    machine: WorkOrderMachine,
    field: keyof WorkOrderCostCenterMachineFragment
  ) {
    return [
      "costCenters",
      this.builder.costCenters.indexOf(costCenter),
      "machines",
      this.builder.machines.getCostCenterMachineIndex(costCenter, machine),
      field,
    ];
  }

  getCropFieldMachine(
    machine: WorkOrderMachine,
    costCenter: WorkOrderCostCenter
  ) {
    return machine.machine.cropFieldMachines.find(
      (cfm) => cfm.cropField.id === costCenter.cropField?.id
    );
  }

  onBulkUpdate(machine: WorkOrderMachine, hours?: number | null) {
    this.getCostCenters().forEach((cc) => {
      if (!this.getCropFieldMachine(machine, cc)) return;

      const field = this.getCostCenterMachineField(cc, machine, "hours");
      this.form.setFieldValue(field, hours);
    });
  }

  getCapacityUsed(index: number, ccIndex?: number) {
    const machine = this.getBy(index);
    const costCenters =
      ccIndex != null
        ? [this.builder.costCenters.getBy(ccIndex)]
        : this.builder.costCenters
            .get()
            .filter((cc) => this.getCropFieldMachine(machine, cc));

    const used = costCenters.flatMap((cc) => {
      const hours = this.getCostCenterMachine(cc, machine)?.hours || 0;

      return (
        hours *
        (this.getCropFieldMachine(machine, cc)?.capacityValue ||
          machine.machine.capacityValue ||
          0)
      );
    });

    const conversionFactor =
      (machine.machine.capacityTime || 1) *
      (machine.machine.capacityTimeUnit?.conversionFactor || 1);

    return sum(used) / conversionFactor;
  }

  totalCapacityUsed(toUnit: Pick<UnitFragment, "id" | "conversionFactor">) {
    return sum(
      this.get().map((m, index) => {
        const capacityUsed = this.builder.machines.getCapacityUsed(index);
        const conversionFactor = m.machine.capacityUnit
          ? getUnitsConversionFactor(m.machine.capacityUnit, toUnit)
          : 1;

        return capacityUsed * conversionFactor;
      })
    );
  }
}
