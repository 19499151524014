import { LockOutlined } from "@ant-design/icons";
import { Row, Col, Form, Input, InputNumber, Spin } from "antd";
import dynamic from "next/dynamic";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { formatOptional, shouldUpdate } from "../../../lib/formats";
import { LocalityFragment } from "../../../lib/graphql";
import { useUnitOptions } from "../../../lib/hooks";
import { MapCoordinate } from "../../../lib/mapbox/utils";
import { Rules, SelectField } from "../../form";
import { HelpTooltip, Popover } from "../../shared";
import { MapValue } from "../../shared/MapInput/MapInput";

const MapInput = dynamic(() => import("../../shared/MapInput/MapInput"), {
  ssr: false,
  loading: () => <Spin />,
});

export function LocalityForm({
  locality,
  unitOptions,
}: {
  locality?: LocalityFragment;
  unitOptions: ReturnType<typeof useUnitOptions>["unitOptions"];
}) {
  const [prevUnitId, setPrevUnitId] = useState<string | undefined>(
    locality?.areaUnit?.id
  );

  const mapCenter: MapCoordinate | undefined =
    locality && locality.longitude && locality.latitude
      ? [locality.longitude, locality.latitude]
      : undefined;

  return (
    <Row gutter={32}>
      <Col xs={24} xl={12}>
        <Form.Item
          name="name"
          label={
            <FormattedMessage
              id="localities.form.name"
              defaultMessage="What's the name of your farm?"
            />
          }
          rules={[Rules.required]}
          required
        >
          <Input placeholder="Hacienda Buenos Aires, Finca San Antonio" />
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="localities.form.totalArea"
              defaultMessage="Whats the size of your farm?"
            />
          }
          required
        >
          <Input.Group compact>
            <Form.Item name="totalArea" rules={[Rules.gtZero]} noStyle>
              <InputNumber
                min={0}
                step={0.01}
                precision={2}
                max={99999999}
                style={{ minWidth: 120 }}
              />
            </Form.Item>

            <Form.Item noStyle shouldUpdate={shouldUpdate("totalArea")}>
              {({ getFieldValue, setFields }) => {
                const prevFactor =
                  unitOptions.find((u) => u.unit.id === prevUnitId)?.unit
                    ?.conversionFactor || 1;

                const prevArea =
                  Math.round(
                    (prevFactor > 1
                      ? getFieldValue("totalArea") * prevFactor
                      : getFieldValue("totalArea") / prevFactor) * 100
                  ) / 100;

                return (
                  <Popover
                    key="area-unit-id"
                    readonly={!locality || locality.mayUpdateAreaUnit}
                    content={
                      <FormattedMessage
                        id="localities.denyUnitUpdate"
                        defaultMessage="Can not update unit"
                      />
                    }
                  >
                    <HelpTooltip
                      title={
                        <FormattedMessage
                          id="localities.areaUnitHelp"
                          defaultMessage="areaUnitHelp"
                        />
                      }
                      trigger={locality ? undefined : "focus"}
                    >
                      <Form.Item
                        name="areaUnitId"
                        rules={[Rules.required]}
                        noStyle
                      >
                        <SelectField
                          allowClear={false}
                          disabled={locality && !locality.mayUpdateAreaUnit}
                          options={unitOptions}
                          suffixIcon={
                            locality && !locality.mayUpdateAreaUnit ? (
                              <LockOutlined />
                            ) : undefined
                          }
                          onChange={(value) => {
                            const factor =
                              unitOptions.find((u) => u.unit.id === value)?.unit
                                ?.conversionFactor || 1;

                            setPrevUnitId(value);

                            setFields([
                              {
                                name: "totalArea",
                                value:
                                  Math.round(
                                    (factor > 1
                                      ? prevArea / factor
                                      : prevArea * factor) * 100
                                  ) / 100,
                              },
                            ]);
                          }}
                        />
                      </Form.Item>
                    </HelpTooltip>
                  </Popover>
                );
              }}
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="internalId"
          label={formatOptional(<FormattedMessage id="internalId" />)}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} xl={12}>
        <Form.Item
          dependencies={["areaUnitId"]}
          label={
            <FormattedMessage id="cropFields.map" defaultMessage="location" />
          }
          tooltip={
            <FormattedMessage
              id="localities.map.tooltip"
              defaultMessage="Upload your farm's map or draw it directly on the map"
            />
          }
        >
          {({ getFieldValue, setFields }) => {
            const onAreaChange = (area: number) => {
              const areaUnit = unitOptions.find(
                (u) => u.key == getFieldValue("areaUnitId")
              )?.unit;

              const factor = areaUnit?.conversionFactor || 1;

              setFields([
                {
                  name: "totalArea",
                  value: Math.round((area / factor) * 100) / 100,
                },
              ]);
            };

            const mapOnChange = ({ value, area }: MapValue) => {
              setFields([{ name: "geoJson", value }]);
              onAreaChange(area);
            };

            return (
              <MapInput
                disablePoint
                defaultValue={locality?.geoJson}
                center={mapCenter}
                onChange={mapOnChange}
                uploadLabel={
                  <FormattedMessage
                    id="localities.map.upload"
                    defaultMessage="Upload Farm Boundary (.KMZ, .KML)"
                  />
                }
              />
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
}
