import { FieldData } from "rc-field-form/lib/interface";
import { FormattedMessage } from "react-intl";
import { useCropCycleOptions } from "../../../lib/hooks";
import { Form, NamePath, SelectField, SelectFieldProps } from "../../form";
import Text from "antd/lib/typography/Text";
import { shouldUpdate } from "../../../lib/formats";

export function CropCycleFormSelect({
  localityIds,
  ...props
}: SelectFieldProps & { localityIds?: string[] }) {
  return (
    <Form.Item
      label={<FormattedMessage id="cropCycles.entityName" />}
      shouldUpdate={shouldUpdate("localityId")}
    >
      {({ getFieldValue, setFields }) => {
        return (
          <CropCycleFormItem
            getFieldValue={getFieldValue}
            setFields={setFields}
            localityIds={getFieldValue("localityId") || localityIds}
            {...props}
          />
        );
      }}
    </Form.Item>
  );
}

function CropCycleFormItem({
  localityIds,
  getFieldValue,
  setFields,
  ...props
}: SelectFieldProps & {
  localityIds?: string[];
  getFieldValue: (name: NamePath) => any;
  setFields: (fields: FieldData[]) => void;
}) {
  return (
    <Form.Item noStyle name="cropCycleId">
      <SelectField
        showGroups
        optionsHook={useCropCycleOptions}
        optionsHookParams={{
          variables: {
            filter: {
              localityId: localityIds?.length ? localityIds : undefined,
            },
          },
        }}
        placeholder={
          <FormattedMessage
            id="cropCycles.all"
            defaultMessage="All crop cycles"
          />
        }
        formatOption={(label, option) =>
          option.cropCycle.closedAt ? (
            <Text type="secondary">{label}</Text>
          ) : (
            label
          )
        }
        {...props}
      />
    </Form.Item>
  );
}
