import { QueryHookOptions, useLazyQuery } from "@apollo/client";
import {
  VariantsQuery,
  VariantsQueryVariables,
  VariantsDocument,
  StockVariantsQuery,
  StockVariantsQueryVariables,
  StockVariantsDocument,
  SortDirection,
  VariantShortFragment,
  UnitShortFragment,
  UnitType,
  usePromiseQuery,
} from "../../graphql";
import { formatStockVariantOption } from "../../formats";
import { IntlShape, useIntl } from "react-intl";
import { merge } from "lodash";
import { useCurrentUser, useRecipeOptions, useUnits } from "..";
import { filterFalse } from "../../utils";

const variantMapper = (intl: IntlShape) => (v: VariantShortFragment) => ({
  key: v.id,
  label: `${v.name} - ${intl.formatNumber(v.variationValue)} ${
    v.variationUnit.abbr
  }`,
  children: formatStockVariantOption(v),
  variant: v,
});

export function useVariantOptions(
  queryOptions?: QueryHookOptions<VariantsQuery, VariantsQueryVariables>
) {
  const [load, { loading, data }] = useLazyQuery(
    VariantsDocument,
    merge({ variables: { sort: { name: SortDirection.Asc } } }, queryOptions)
  );

  const search = (val?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: {
            fullName: { match: val },
          },
        },
      })
    );
  };

  const intl = useIntl();
  const optionsFormatter = variantMapper(intl);
  const options = (data?.variants?.items || []).map(optionsFormatter);

  return { load, search, loading, options };
}

export function useStockVariantOptions(
  queryOptions?: QueryHookOptions<
    StockVariantsQuery,
    StockVariantsQueryVariables
  >
) {
  const { currentTenant } = useCurrentUser();
  const [load, { loading, data }] = useLazyQuery<
    StockVariantsQuery,
    StockVariantsQueryVariables
  >(
    StockVariantsDocument,
    merge(
      {
        variables: { sort: { name: SortDirection.Asc } },
      },
      queryOptions
    )
  );

  const search = (value?: string) => {
    load(
      merge({ variables: { sort: undefined } }, queryOptions, {
        variables: {
          filter: {
            fullName: { match: value },
          },
        },
      })
    );
  };

  const intl = useIntl();

  const optionsFormatter = variantMapper(intl);
  const formatter = currentTenant.features.inventoryStock
    ? optionsFormatter
    : (v: any) => optionsFormatter({ ...v, stock: null });

  const options = data?.variants?.items.map(formatter) || [];

  return { load, search, loading, options };
}

export function useStockVariantWithRecipesOptions(
  queryOptions?: QueryHookOptions<
    StockVariantsQuery,
    StockVariantsQueryVariables
  >
) {
  const intl = useIntl();

  const {
    options: variantOptions,
    loading: variantLoading,
    search: searchVariants,
    load: loadVariants,
  } = useStockVariantOptions(queryOptions);

  const {
    options: recipeOptions,
    loading: recipeLoading,
    search: searchRecipes,
    load: loadRecipes,
  } = useRecipeOptions();

  const options = [
    ...(recipeOptions || []).map((o) => ({
      ...o,
      group: intl.formatMessage({ id: "recipes" }),
    })),
    ...(variantOptions || []).map((o) => ({
      ...o,
      group: intl.formatMessage({ id: "itemVariants" }),
    })),
  ];

  return {
    options,
    loading: recipeLoading || variantLoading,
    search: (v?: string) => {
      searchVariants(v);
      searchRecipes(v);
    },
    load: () => {
      loadVariants();
      loadRecipes();
    },
  };
}

export function useApplicationUnitOptions({
  water,
  unit,
  plant,
}: {
  water?: boolean;
  unit?: UnitShortFragment;
  plant?: boolean;
}) {
  const intl = useIntl();
  const { items: units } = useUnits({
    variables: { filter: { abbr: { any: ["L", "gal", "hl", "pl"] } } },
  });
  const waterUnits = (units || []).filter(
    (u) => u.unitType === UnitType.Volume
  );
  const plantUnit = (units || []).find((u) => u.abbrEn === "pl");

  return filterFalse(
    [
      {
        key: "",
        label: intl.formatMessage({ id: "total" }),
      },
      water &&
        waterUnits.map((u) => ({
          key: u.id,
          label: intl.formatMessage(
            {
              id: "per",
            },
            { value: u.abbr }
          ),
        })),
      unit && {
        key: unit.id,
        label: intl.formatMessage(
          {
            id: "per",
          },
          { value: unit.abbr }
        ),
      },
      plant &&
        plantUnit && {
          key: plantUnit.id,
          label: intl.formatMessage(
            {
              id: "per",
            },
            { value: plantUnit.abbr }
          ),
        },
    ].flat()
  );
}

export function useStockVariants() {
  return usePromiseQuery<StockVariantsQuery, StockVariantsQueryVariables>(
    StockVariantsDocument
  );
}
