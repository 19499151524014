import React, { useContext, useState } from "react";
import { useItemSidebarContext } from "../../../lib/hooks";
import {
  CommentableType,
  WorkOrderDetailsFragment,
  WorkOrderStatus,
  WorkOrderType,
  SaveMutationResult,
} from "../../../lib/graphql";
import { Form } from "../../form";
import { WorkOrderContext } from "./WorkOrderContext";
import routes, { routerPush } from "../../../lib/routes";
import { BottomPanel, SectionWizard } from "../../shared";
import { FormattedMessage, useIntl } from "react-intl";
import { WorkOrderSubmitButton } from "./WorkOrderSubmitButton";
import { WorkOrderBuilder, WorkOrderFormValues } from "./builder";
import { useForm } from "antd/lib/form/Form";
import { CommentSection } from "../../shared/Wizard/CommentSection";
import { WorkOrderBasic } from "./WorkOrderBasic";
import { WorkOrderSidebar } from "./WorkOrderSidebar";
import { Button, Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

export interface WorkOrderFormProps {
  workOrderType?: WorkOrderType;
  refetch?: () => Promise<WorkOrderDetailsFragment | null | undefined>;
  onSave?(
    values: WorkOrderFormValues,
    builder: WorkOrderBuilder
  ): Promise<
    SaveMutationResult<WorkOrderDetailsFragment> | null | undefined
  > | void;
}

export function WorkOrderForm({
  workOrderType,
  onSave,
  refetch,
}: WorkOrderFormProps) {
  const { workOrder, form, builder, sections, rebuild } =
    useContext(WorkOrderContext);
  const [reloading, setReloading] = useState(false);
  const intl = useIntl();
  const entityName = intl.formatMessage({
    id: "workOrders.entityName",
  });
  const [commentForm] = useForm();

  const buttons = [];

  const { setCurrentAction } = useItemSidebarContext();

  if (builder.isReadonly) {
    buttons.push(
      BottomPanel.ReturnButton({ route: routes.agro.workOrders.index })
    );

    if (
      workOrder.status === WorkOrderStatus.Completed ||
      workOrder.status === WorkOrderStatus.Reverted
    ) {
      buttons.push(
        BottomPanel.DownloadPDFButton({
          onClick: () => setCurrentAction("pdf"),
        })
      );
    }
  } else {
    if (!builder.isNew) {
      buttons.push(
        <Tooltip
          title={
            <FormattedMessage id="refreshPage" defaultMessage="Refresh Page" />
          }
        >
          <Button
            loading={reloading}
            icon={<ReloadOutlined />}
            onClick={() => {
              if (!refetch) return;
              setReloading(true);
              refetch()
                .then((wo) => wo && rebuild(wo, true))
                .finally(() => setReloading(false));
            }}
          />
        </Tooltip>
      );
    }

    buttons.push(
      BottomPanel.CancelButton({
        route: routes.agro.workOrders.index,
      }),
      BottomPanel.SubmitButton({
        onClick: () => {
          form.setFields([
            { name: "nextStatus", value: null },
            { name: "successMessage", value: null },
          ]);
        },
      })
    );

    if (builder.canEdit) {
      buttons.push(<WorkOrderSubmitButton />);
    }
  }

  return (
    <>
      <Form
        preventLeaving
        form={form}
        style={{ marginTop: "16px" }}
        initialValues={workOrder}
        onSubmit={(
          values: WorkOrderFormValues,
          { setSubmitting, showErrors, showSuccess }
        ) => {
          if (!onSave) return;
          const promise = onSave(values, builder);
          if (!promise) return;

          promise
            .then((o) => {
              if (!o) return;

              if (o.result) {
                commentForm.submit();
                commentForm.setFieldsValue({ commentableId: o.result.id });

                if (values.successMessage) {
                  showSuccess(
                    intl.formatMessage(values.successMessage, {
                      id: o.result.id,
                    })
                  );
                }

                if (!values.id) {
                  routerPush(routes.agro.workOrders.details(o.result.id));
                } else {
                  if (!values.successMessage) {
                    showSuccess(
                      intl.formatMessage(
                        { id: "edit.success" },
                        {
                          entityName,
                          id: values.id,
                        }
                      )
                    );
                  }
                  rebuild(o.result, true);
                  setSubmitting(false);
                }
              } else if (o.errors) {
                showErrors(o.errors);
                setSubmitting(false);
              }
            })
            .catch(() => setSubmitting(false));
        }}
      >
        <WorkOrderBasic workOrderType={workOrderType} />

        <SectionWizard
          sections={sections}
          disabled={builder.noActivity}
          isReadonly={builder.isReadonly}
          hideHiddenSections={builder.isDataIntake}
          builder={builder.sections}
        />
        <BottomPanel sticky buttons={buttons} />
      </Form>

      {!builder.noActivity && (
        <CommentSection
          commentableType={CommentableType.AgroWorkOrder}
          commentableId={workOrder.id}
          form={commentForm}
        />
      )}

      <WorkOrderSidebar />

      <div style={{ marginBottom: "90px" }} />
    </>
  );
}
