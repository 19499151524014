import React, { ReactNode } from "react";
import { ItemForm, ItemFieldFormConfig } from "./ItemForm";
import {
  SaveMutationResult,
  CreateMutationVariables,
  MutationProps,
  WithIDType,
} from "../../../lib/graphql";
import { useCurrentLocale } from "../../../lib/hooks";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import { SidebarHeader } from "../Sidebar";
import { FormProps } from "../../form";

export interface NewFormProps<T, TFormValues, TMutation, TMutationVariables>
  extends MutationProps<TMutation, TMutationVariables, SaveMutationResult<T>> {
  entityName: ReactNode;
  fields: ItemFieldFormConfig<TFormValues>[];
  formValues(): TFormValues;
  onClose?: (result?: T) => void;
  showHeader?: boolean;
  formProps?: FormProps<TFormValues>;
  processValues?(value: TFormValues): TFormValues;
  hideFooter?: boolean;
}

const messages = defineMessages({
  new: { id: "new.header", defaultMessage: "new" },
  newSuccess: { id: "new.success", defaultMessage: "success" },
});

export function NewForm<T extends WithIDType, TFields, TCreateMutation>({
  fields,
  entityName,
  formValues,
  mutation,
  onMutate,
  mutationOptions,
  onClose,
  showHeader = true,
  formProps,
  hideFooter,
  processValues = (v) => v,
}: NewFormProps<
  T,
  TFields,
  TCreateMutation,
  CreateMutationVariables<TFields>
>) {
  const { showSuccess } = useCurrentLocale();
  const intl = useIntl();
  const [create] = mutation({ ignoreResults: true, ...mutationOptions });

  return (
    <>
      {showHeader && (
        <SidebarHeader
          title={intl.formatMessage(messages.new, { entityName })}
          onClose={onClose}
        />
      )}

      <ItemForm
        entityName={entityName}
        hideFooter={hideFooter}
        fields={fields}
        initialValues={formValues()}
        formProps={formProps}
        onCancel={onClose}
        saveText={<FormattedMessage id="create" />}
        onSave={(values) => {
          return create({
            variables: { fields: processValues(values) },
          })
            .then((result) => {
              if (!result.data) return null;

              return onMutate(result.data);
            })
            .then((result) => {
              if (result && result.result) {
                showSuccess(
                  intl.formatMessage(messages.newSuccess, {
                    entityName,
                  })
                );
                onClose && onClose(result.result);
              }
              return result;
            });
        }}
      />
    </>
  );
}
