import { FormattedMessage, useIntl } from "react-intl";
import {
  ItemSidebar,
  ItemSidebarContext,
  Popover,
  SidebarHeader,
  TableInput,
} from "../../shared";
import {
  useEmployeesAndGroupsOptions,
  useEmployeeGroupOptions,
  useCurrentUser,
  useAttendanceOptions,
  useBinaryAttendanceOptions,
  usePermissions,
  useMediaXs,
} from "../../../lib/hooks";
import {
  ActivityMetricScope,
  EmployeeGroupFragment,
  EmployeeShortWithWageFragment,
  LeaveTypeFragment,
  WorkOrderDetailsFragment,
  WorkOrderEmployeeFragment,
} from "../../../lib/graphql";
import {
  formatHours,
  shouldUpdate,
  formatEmployeeConflict,
  formatTimeRange,
  formatEmployeeName,
  formatAttendance,
  formatEmployee,
  formatWage,
  formatBinaryAttendance,
  formatUnitValue,
  formatInfoTooltip,
} from "../../../lib/formats";
import {
  SelectField,
  Form,
  Rules,
  InputNumber,
  RuleBuilder,
  TimePicker,
} from "../../form";
import { Table, Tag } from "antd";
import { WorkOrderContext } from "./WorkOrderContext";
import { ItemFieldFormConfig, ItemForm } from "../../shared/ListView/ItemForm";
import { useMemo, useContext, useState, ReactNode } from "react";
import {
  EditOutlined,
  LockOutlined,
  MoreOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { WorkerCount } from "./WorkOrderEmployees";
import { filterFalse } from "../../../lib/utils";
import { WorkOrderEmployee } from "./builder/employees";

export const employeeByIdBuilder = (workOrder: WorkOrderDetailsFragment) => {
  const buildWorkOrderEmployee = (
    employee: EmployeeShortWithWageFragment,
    group?: EmployeeGroupFragment
  ): WorkOrderEmployeeFragment => ({
    id: "",
    employee,
    employeeGroup: group,
    hours: workOrder.activity.workdayHours,
    overtimeHours: 0,
    wagePayment: employee.wagePayment,
    conflictUsage: employee.conflictUsage,
    startTime: workOrder.documentDate,
    attended: true,
    leaveType: { id: "" } as LeaveTypeFragment,
  });

  return (
    value: string,
    {
      employee,
      employeeGroup,
    }: {
      employee: EmployeeShortWithWageFragment;
      employeeGroup: EmployeeGroupFragment;
    }
  ) => {
    const [, type] = value.split(":");
    if (type === "group" && employeeGroup) {
      return employeeGroup.employees.map((e) =>
        buildWorkOrderEmployee(e, employeeGroup)
      );
    }

    if (employee) {
      return buildWorkOrderEmployee(employee);
    }
  };
};

export function AssignedCountTag({ index }: { index: number }) {
  const { builder } = useContext(WorkOrderContext);

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdate(["employees", index, "assignedCount"])}
    >
      {() => {
        const count = builder.employees.getBy(index).assignedCount || 0;
        if (count >= builder.costCenters.get().length) return;

        return (
          <Tag color="orange">
            {builder.isAgricultural ? (
              <FormattedMessage id="cropFields.plural" values={{ count }} />
            ) : (
              <FormattedMessage id="costCenters.plural" values={{ count }} />
            )}
          </Tag>
        );
      }}
    </Form.Item>
  );
}

export function EmployeeLink({
  employee,
  hideInternalId,
  index,
}: {
  employee: WorkOrderEmployeeFragment;
  hideInternalId?: boolean;
  index: number;
}) {
  return (
    <div>
      {formatEmployee(employee.employee, employee.employeeGroup, {
        hideInternalId,
        idx: index + 1,
        tags: <AssignedCountTag index={index} />,
      })}
      <div>{formatEmployeeConflict(employee.conflictUsage)}</div>
    </div>
  );
}

export function EmployeeAttendance({
  employee,
}: {
  employee: WorkOrderEmployeeFragment;
}) {
  const { currentTenant } = useCurrentUser();
  const { builder } = useContext(WorkOrderContext);

  const isBinaryAttendance =
    !currentTenant.features.leaveAssignments ||
    !!currentTenant.allowLeaveAssignments;
  const index = builder.employees.indexOf(employee);

  return builder.isReadonly ? (
    isBinaryAttendance ? (
      formatBinaryAttendance(employee.attended)
    ) : (
      formatAttendance(employee.leaveType)
    )
  ) : (
    <Form.Item
      name={
        isBinaryAttendance
          ? ["employees", index, "attended"]
          : ["employees", index, "leaveType", "id"]
      }
      noStyle
    >
      <SelectField
        dropdownMatchSelectWidth={160}
        showSearch={false}
        bordered={false}
        allowClear={false}
        optionsHook={
          isBinaryAttendance ? useBinaryAttendanceOptions : useAttendanceOptions
        }
        onChange={(
          val,
          options: Array<{
            key: string;
            leaveType: LeaveTypeFragment;
          }>
        ) => {
          if (!isBinaryAttendance) {
            const leaveType = options.find((t) => t.key == val)?.leaveType;

            builder.form.setFields([
              {
                name: ["employees", index, "leaveType"],
                value: leaveType || { id: "" },
              },
              {
                name: ["employees", index, "attended"],
                value: !leaveType,
              },
            ]);
          }

          builder.employees.onEmployeeAttendanceChanged(index);
        }}
      />
    </Form.Item>
  );
}

export function AttendanceFormItem() {
  const { currentTenant } = useCurrentUser();
  const isBinaryAttendance = !!currentTenant.allowLeaveAssignments;

  return (
    <Form.Item key="attendance" noStyle shouldUpdate>
      {({ setFields }) => (
        <Form.Item
          label={
            <FormattedMessage
              id="workOrders.attendance"
              defaultMessage="attendance"
            />
          }
          name={isBinaryAttendance ? "attended" : ["leaveType", "id"]}
        >
          <SelectField
            optionsHook={
              isBinaryAttendance
                ? useBinaryAttendanceOptions
                : useAttendanceOptions
            }
            showSearch={false}
            allowClear={false}
            onChange={(val) => {
              if (isBinaryAttendance) return;

              setFields([{ name: "attended", value: !val }]);
            }}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
}

function OnEmployeeUpdate({
  index,
  children,
}: {
  index: number;
  children: (employee: WorkOrderEmployee) => ReactNode;
}) {
  const { builder } = useContext(WorkOrderContext);

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate(["employees", index])}>
      {() => {
        const employee = builder.employees.getBy(index);
        if (builder.employees.isAbsent(employee)) return;

        return children(employee);
      }}
    </Form.Item>
  );
}

export function WorkOrderAttendance({ readonly }: { readonly: boolean }) {
  const intl = useIntl();
  const isXs = useMediaXs();

  const { currentTenant } = useCurrentUser();
  const { builder, workOrder } = useContext(WorkOrderContext);
  const { setCurrentAction } = useContext(ItemSidebarContext);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState(0);
  const showWage = usePermissions((p) => p.settings?.showWage);

  const employeeById = employeeByIdBuilder(workOrder);

  const editFields: ItemFieldFormConfig<WorkOrderEmployeeFragment>[] = useMemo(
    () =>
      filterFalse([
        currentTenant.attendanceEnabled && {
          type: "custom",
          render: () => <AttendanceFormItem key="attendance" />,
        },
        currentTenant.timeTrackingEnabled && {
          type: "custom",
          // TODO: DRY this? wait for hidden in Form.Item
          render: () => (
            <Form.Item
              noStyle
              key="hours"
              shouldUpdate={shouldUpdate("attended")}
            >
              {({ getFieldValue }) => {
                const attended = getFieldValue(["attended"]);
                if (attended === false) return null;

                return (
                  <Form.Item
                    name="hours"
                    label={<FormattedMessage id="hours" />}
                  >
                    <InputNumber step={0.25} precision={2} max={24} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          ),
        },
        currentTenant.overtimeEnabled && {
          type: "custom",
          render: () => (
            <Form.Item
              noStyle
              key="overtimeHours"
              shouldUpdate={shouldUpdate("attended")}
            >
              {({ getFieldValue }) => {
                const attended = getFieldValue(["attended"]);
                if (attended === false) return null;

                return (
                  <Form.Item
                    name="overtimeHours"
                    label={
                      <FormattedMessage
                        id="workOrders.overtimeHours"
                        defaultMessage="overtimeHours"
                      />
                    }
                  >
                    <InputNumber step={0.25} precision={2} max={24} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          ),
        },
        {
          type: "custom",
          render: () => (
            <Form.Item
              noStyle
              key="startTime"
              hidden={!workOrder.activity.trackTime}
              shouldUpdate={shouldUpdate("attended")}
            >
              {({ getFieldValue }) => {
                const attended = getFieldValue(["attended"]);
                if (attended === false) return null;

                return (
                  <Form.Item
                    label={
                      <FormattedMessage
                        id="workOrders.startTime"
                        defaultMessage="Start Time"
                      />
                    }
                    name="startTime"
                  >
                    <TimePicker />
                  </Form.Item>
                );
              }}
            </Form.Item>
          ),
        },
        {
          type: "select",
          key: "employeeGroupId",
          render: () => (
            <Form.Item
              noStyle
              key="employeeGroupId"
              shouldUpdate={shouldUpdate("attended")}
            >
              {({ getFieldValue, setFields }) => {
                const attended = getFieldValue(["attended"]);
                if (attended === false) return null;

                return (
                  <Form.Item
                    name={["employeeGroup", "id"]}
                    label={<FormattedMessage id="employeeGroups.entityName" />}
                  >
                    <SelectField
                      placeholder={
                        <FormattedMessage
                          id="select.employeeGroup"
                          defaultMessage="employeeGroup"
                        />
                      }
                      optionsHook={useEmployeeGroupOptions}
                      optionsHookParams={{
                        variables: {
                          filter: { localityId: workOrder.locality.id },
                        },
                      }}
                      onChange={(id, options) => {
                        const group = options.find((o: any) => o.key == id);
                        setFields([
                          {
                            name: ["employeeGroup", "name"],
                            value: group?.label,
                          },
                        ]);
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          ),
        },
        {
          type: "custom",
          render: () => (
            <Form.Item
              noStyle
              key="progress"
              shouldUpdate={shouldUpdate("attended")}
            >
              {({ getFieldValue }) => {
                const attended = getFieldValue(["attended"]);
                if (attended === false || !builder.progressByIndividual)
                  return null;

                return (
                  <Form.Item
                    name="totalProgress"
                    label={<FormattedMessage id="progress" />}
                  >
                    <InputNumber
                      tabIndex={3}
                      step={0.1}
                      min={0}
                      addonAfter={workOrder.activity.progressUnit.abbr}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          ),
        },
      ]),
    [
      workOrder.locality.id,
      workOrder.activity,
      builder.progressByIndividual,
      currentTenant.overtimeEnabled,
      currentTenant.attendanceEnabled,
      currentTenant.timeTrackingEnabled,
    ]
  );

  const hoursRule = (index: number) =>
    RuleBuilder.custom(() => {
      const employee = builder.form.getFieldValue(["employees", index]);
      const totalHours = builder.employees.getEmployeeTotalHours(index);

      if (totalHours > 24) {
        return (
          <FormattedMessage
            id="workOrders.attendance.exceedHours"
            defaultMessage="May not exceed 24 hrs"
          />
        );
      } else if (totalHours == 0 && !builder.employees.isAbsent(employee)) {
        return (
          <FormattedMessage
            id="workOrders.attendance.zeroHours"
            defaultMessage="Attended can't have 0"
          />
        );
      }

      return true;
    });

  const columns: ColumnsType<WorkOrderEmployeeFragment> = filterFalse([
    {
      title: <FormattedMessage id="employees.entityName" />,
      dataIndex: "employee",
      sorter: builder.employees.nameSorter,
      fixed: isXs ? undefined : "left",
      render: (_, e, index) => (
        <Form.Item
          name={["employees", builder.employees.indexOf(e), "id"]}
          compact
        >
          <>
            <EmployeeLink index={index} employee={e} />
          </>
        </Form.Item>
      ),
    },
    showWage && {
      key: "wage-col",
      title: <FormattedMessage id="wage" defaultMessage="wage" />,
      render: (_, e) =>
        e.wagePayment &&
        formatWage(
          e.wagePayment,
          currentTenant.currencyCode,
          workOrder.activity.progressUnit
        ),
    },
    currentTenant.attendanceEnabled && {
      title: (
        <FormattedMessage
          id="workOrders.attendance"
          defaultMessage="attendance"
        />
      ),
      width: "8rem",
      render: (_, e) => <EmployeeAttendance employee={e} />,
    },
    {
      title: <FormattedMessage id="hours" />,
      width: 110,
      render: (_, _e, index) => (
        <OnEmployeeUpdate index={index}>
          {(e) =>
            readonly ? (
              formatHours(e.hours)
            ) : (
              <Form.Item
                name={["employees", index, "hours"]}
                compact
                rules={[Rules.gtEqZero, hoursRule(index)]}
              >
                <InputNumber
                  tabIndex={1}
                  step={0.25}
                  precision={2}
                  max={24}
                  onChange={() => builder.employees.recalculateEndTime(index)}
                />
              </Form.Item>
            )
          }
        </OnEmployeeUpdate>
      ),
    },
    currentTenant.overtimeEnabled && {
      title: (
        <FormattedMessage
          id="workOrders.overtimeHours"
          defaultMessage="overtimeHours"
        />
      ),
      width: 110,
      render: (_, _e, index) => (
        <OnEmployeeUpdate index={index}>
          {(e) =>
            readonly ? (
              formatHours(e.overtimeHours)
            ) : (
              <Form.Item
                name={["employees", index, "overtimeHours"]}
                compact
                rules={[Rules.gtEqZero]}
              >
                <InputNumber
                  tabIndex={2}
                  step={0.25}
                  precision={2}
                  max={24}
                  onChange={() => builder.employees.recalculateEndTime(index)}
                />
              </Form.Item>
            )
          }
        </OnEmployeeUpdate>
      ),
    },
    workOrder.activity.trackTime && {
      title: (
        <FormattedMessage
          id="workOrders.startEndTime"
          defaultMessage="Start Time & End Time"
        />
      ),
      align: "center",
      render: (_, _e, index) => (
        <OnEmployeeUpdate index={index}>
          {(e) => formatTimeRange(e.startTime, e.endTime)}
        </OnEmployeeUpdate>
      ),
    },
    builder.showEmployeeProgress && {
      title: <FormattedMessage id="progress" />,
      width: "10rem",
      render: (_, _e, index) => (
        <OnEmployeeUpdate index={index}>
          {(e) => {
            if (builder.employees.isLocked(e)) {
              return (
                <div style={{ textAlign: "center" }}>
                  <Popover
                    title={
                      <FormattedMessage
                        id="workOrders.lockedProgress"
                        defaultMessage="lockedProgress"
                        values={{
                          position: e.employee.position.name,
                        }}
                      />
                    }
                    content={
                      <FormattedMessage
                        id="workOrders.lockedProgress.hint"
                        defaultMessage="hint"
                        values={{
                          icon: <MoreOutlined />,
                        }}
                      />
                    }
                  >
                    <LockOutlined />
                  </Popover>
                </div>
              );
            }

            const name = [
              "employees",
              builder.employees.indexOf(e),
              "totalProgress",
            ];

            return readonly || builder.progressByGroup ? (
              <Form.Item noStyle shouldUpdate={shouldUpdate(name)}>
                {() =>
                  formatUnitValue(
                    builder.form.getFieldValue(name),
                    workOrder.activity.progressUnit
                  )
                }
              </Form.Item>
            ) : (
              <Form.Item compact name={name} rules={[Rules.gtEqZero]}>
                <InputNumber
                  tabIndex={3}
                  step={0.1}
                  min={0}
                  addonAfter={workOrder.activity.progressUnit.abbr}
                  onChange={(val) =>
                    builder.employees.progress.debouncedDistributeTotalProgress(
                      e,
                      val
                    )
                  }
                />
              </Form.Item>
            );
          }}
        </OnEmployeeUpdate>
      ),
    },
  ]);

  const metricCols: ColumnsType<WorkOrderEmployeeFragment> =
    workOrder.activity.activityMetrics
      .filter((m) => m.scope == ActivityMetricScope.Employee)
      .map((m) => ({
        title: (
          <>
            {m.metric.name} {formatInfoTooltip(m.metric.description)}
          </>
        ),
        width: "12rem",
        render: (_, e) => {
          const name = ["employees", builder.employees.indexOf(e), m.metric.id];

          if (builder.isReadonly) {
            return formatUnitValue(
              builder.form.getFieldValue(name),
              m.metric.unit
            );
          }

          return (
            <Form.Item name={name} rules={[Rules.gtEqZero]} compact>
              <InputNumber
                step={0.1}
                min={0}
                onChange={(val) =>
                  builder.employees.metrics.debouncedDistributeIndividualMetrics(
                    m.metric.id,
                    e.employee.id,
                    val
                  )
                }
                addonAfter={m.metric.unit.abbr}
              />
            </Form.Item>
          );
        },
      }));

  columns.push(...metricCols);

  // CC change, Em add/destroy, Attend change, Lock change
  return (
    <>
      {builder.workerCountMode && (
        <div style={{ marginTop: 16 }}>
          <WorkerCount />
        </div>
      )}

      {currentTenant.features.employees && (
        <>
          <TableInput
            allowBulkRemove
            name="employees"
            tableProps={{
              fixed: true,
              bordered: true,
              summary: (employees) => {
                if (employees.length == 0) return;

                const wageColIndex = Math.max(
                  columns.findIndex((col) => col?.key === "wage-col"),
                  0
                );

                return (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={wageColIndex + 2}>
                      <FormattedMessage id="total" defaultMessage="total" />
                    </Table.Summary.Cell>

                    <Form.Item
                      noStyle
                      shouldUpdate={shouldUpdate("employees", "costCenters")}
                    >
                      {() => {
                        const hours = builder.employees.getHours();
                        const overtimeHours =
                          builder.employees.getOvertimeHours();
                        return (
                          <>
                            <Table.Summary.Cell index={1}>
                              {formatHours(hours)}
                            </Table.Summary.Cell>
                            {currentTenant.overtimeEnabled && (
                              <Table.Summary.Cell index={2}>
                                {formatHours(overtimeHours)}
                              </Table.Summary.Cell>
                            )}
                            {workOrder.activity.trackTime && (
                              <Table.Summary.Cell index={4} />
                            )}
                            {builder.showEmployeeProgress && (
                              <Table.Summary.Cell index={5}>
                                {formatUnitValue(
                                  builder.employees.getTotalProgress(),
                                  workOrder.activity.progressUnit
                                )}
                              </Table.Summary.Cell>
                            )}
                            {workOrder.activity.activityMetrics
                              .filter(
                                (m) => m.scope == ActivityMetricScope.Employee
                              )
                              .map((m) => (
                                <Table.Summary.Cell index={6} key={m.id}>
                                  {formatUnitValue(
                                    builder.employees.metrics.getTotalMetric(
                                      m.metric.id
                                    ),
                                    m.metric.unit
                                  )}
                                </Table.Summary.Cell>
                              ))}
                          </>
                        );
                      }}
                    </Form.Item>

                    {!readonly && <Table.Summary.Cell index={2} />}
                  </Table.Summary.Row>
                );
              },
            }}
            dataSource={workOrder.employees}
            rowKey={(e) => e.employee.id}
            disabled={readonly}
            onRemove={(e) => builder.employees.onRemove(e)}
            entityName={intl.formatMessage({
              id: "employees",
              defaultMessage: "employees",
            })}
            bulkUpdateFields={editFields}
            onBulkUpdate={(values: any) => {
              builder.employees.recalculateEndTimes();
              if (values.totalProgress)
                builder.employees.progress.redistributeTotalProgress();
            }}
            tableSelectProps={{
              mode: "multiple",
              showGroups: true,
              groupsSorter: () => 0,
              optionsHook: useEmployeesAndGroupsOptions,
              optionsHookParams: {
                localityId: workOrder.locality.id,
                activityId: workOrder.activity.id,
                cropIds: workOrder.cropCycle?.crops.map((c) => c.id),
                documentDate: workOrder.documentDate,
              },
              entityById: employeeById,
              afterAdd: () => builder.employees.init(),
            }}
            menuItems={(e, index) => {
              const items = [
                {
                  key: "edit-employee",
                  onClick: () => {
                    setCurrentEmployeeIndex(index);
                    setCurrentAction("editEmployee");
                  },
                  icon: <EditOutlined />,
                  label: <FormattedMessage id="edit" />,
                },
              ];

              if (!builder.employees.allowProgress(e)) {
                const unlockedField = ["employees", index, "unlocked"];
                items.push({
                  key: "toggleLock",
                  onClick: () => {
                    builder.form.setFieldValue(
                      unlockedField,
                      !builder.form.getFieldValue(unlockedField)
                    );

                    builder.employees.onEmployeeAttendanceChanged(index);
                  },
                  icon: (
                    <OnEmployeeUpdate index={index}>
                      {(e) =>
                        e.unlocked ? (
                          <LockOutlined className="ant-dropdown-menu-item-icon" />
                        ) : (
                          <UnlockOutlined className="ant-dropdown-menu-item-icon" />
                        )
                      }
                    </OnEmployeeUpdate>
                  ),
                  label: (
                    <OnEmployeeUpdate index={index}>
                      {(e) =>
                        e.unlocked ? (
                          <FormattedMessage
                            id="workOrders.relockProgress"
                            defaultMessage="relockProgress"
                          />
                        ) : (
                          <FormattedMessage
                            id="workOrders.unlockProgress"
                            defaultMessage="unlockProgress"
                          />
                        )
                      }
                    </OnEmployeeUpdate>
                  ),
                });
              }

              return items;
            }}
            addSorter={(a, b) => builder.employees.sorter(a, b)}
            columns={columns}
          />

          <ItemSidebar
            item={currentEmployeeIndex}
            sidebarActions={{
              editEmployee: ({ item, closeSidebar }) => {
                const employee = builder.employees.get(false)[item];
                return (
                  <>
                    <SidebarHeader
                      back
                      title={
                        <FormattedMessage
                          id="edit.header"
                          values={{
                            entityName: formatEmployeeName(employee.employee),
                          }}
                        />
                      }
                      onClose={closeSidebar}
                    />
                    <ItemForm
                      initialValues={employee}
                      fields={editFields}
                      onCancel={closeSidebar}
                      onSave={(values) => {
                        builder.employees.update(item, values);
                        builder.employees.recalculateEndTime(item);
                        closeSidebar();
                        return Promise.resolve(null);
                      }}
                    />
                  </>
                );
              },
            }}
          />
        </>
      )}
    </>
  );
}
