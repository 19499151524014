import { useIntl } from "react-intl";
import {
  ActivityFields,
  ActivityLaborForceType,
  ActivityProgressMeasureType,
  WorkOrderType,
  evictCache,
} from "../../../lib/graphql";
import { useActivityCreate } from "../../../lib/hooks";
import { NewForm } from "../../shared";
import { ActivityBasicForm } from "./ActivityBasicForm";

export function NewActivitySidebar({ onClose }: { onClose: () => void }) {
  const intl = useIntl();

  return (
    <NewForm
      entityName={intl.formatMessage({ id: "activities.entityName" })}
      mutation={useActivityCreate}
      onMutate={(result) => result.activityCreate}
      mutationOptions={{ update: evictCache("activities") }}
      onClose={onClose}
      formValues={() =>
        ({
          workOrderType: WorkOrderType.FieldWork,
          workdayHours: 8,
          laborForceType: ActivityLaborForceType.Laborer,
          progressMeasureType: ActivityProgressMeasureType.ByGroup,
        } as ActivityFields)
      }
      fields={[
        {
          type: "custom",
          render: () => <ActivityBasicForm key="activity-form" />,
        },
      ]}
    />
  );
}
