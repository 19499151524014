import React from "react";
import { FormattedPlural, defineMessages, useIntl } from "react-intl";
import { rtfValues } from "../../../lib/formats";
import {
  BulkUpdateMutationVariables,
  MutationProps,
  SaveMutationResult,
  WithIDType,
} from "../../../lib/graphql";
import { useCurrentLocale } from "../../../lib/hooks";
import {
  BulkUpdateSidebar,
  BulkUpdateSidebarProps,
} from "../Table/BulkUpdateSidebar";

export interface BulkUpdateProps<T, TFormValues, TMutation, TMutationVariables>
  extends Omit<BulkUpdateSidebarProps<T, TFormValues>, "onSave">,
    Omit<
      MutationProps<TMutation, TMutationVariables, SaveMutationResult<T>>,
      "onMutate"
    > {
  entityName: string;
  pluralEntityName: string;
  ids: string[];
  onSuccess(): void;
}

const messages = defineMessages({
  updateSuccess: { id: "update.bulk.success", defaultMessage: "success" },
  noChanges: { id: "noChanges", defaultMessage: "No changes" },
});

export function BulkUpdate<T extends WithIDType, TChanges, TMutation>({
  entityName,
  pluralEntityName,
  ids,
  onSuccess,
  mutation,
  mutationOptions,
  fields,
}: BulkUpdateProps<
  T,
  TChanges,
  TMutation,
  BulkUpdateMutationVariables<TChanges>
>) {
  const { showError, showSuccess } = useCurrentLocale();
  const intl = useIntl();

  const [update] = mutation(mutationOptions);

  const onSubmit = (values: TChanges) => {
    if (!values || Object.keys(values).length === 0) {
      showError(intl.formatMessage(messages.noChanges));
      return Promise.reject();
    }

    return update({
      variables: {
        ids,
        changes: values,
      },
    }).then((result) => {
      if (!result.data) return;

      showSuccess(
        intl.formatMessage(messages.updateSuccess, {
          entityName: (
            <FormattedPlural
              value={ids.length}
              one={entityName}
              other={pluralEntityName}
            >
              {(text: any) => text.toLowerCase()}
            </FormattedPlural>
          ),
          count: ids.length,
          ...rtfValues,
        })
      );

      onSuccess();

      return result;
    });
  };

  return (
    <BulkUpdateSidebar
      entityName={entityName}
      onSave={onSubmit as any} // TODO: Fix type
      fields={fields}
    />
  );
}
