import {
  CommentableType,
  JournalEntryDetailsFragment,
  JournalEntryLineFragment,
  DestroyableEntityType,
  SaveIDFunction,
} from "../../lib/graphql";
import { Form, Rules, InputNumber, RuleBuilder } from "../form";
import { useCurrentUser } from "../../lib/hooks";
import routes, { routerPush } from "../../lib/routes";
import {
  Typography,
  Row,
  Col,
  Input,
  Divider,
  Table,
  FormInstance,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import {
  TableInput,
  BottomPanel,
  CommentList,
  exportUrl,
  TableInputProps,
} from "../shared";
import {
  formatAccountName,
  formatCostCenterName,
  formatDetailsLink,
  formatMoneyAmount,
  roundUnit,
} from "../../lib/formats";
import { AccountSelect } from "./AccountSelect";
import { CostCenterProfitableSelect } from "./CostCenterSelect";
import { sumBy } from "lodash";
import DocumentDateFormInput from "./DocumentDateFormInput";
import { useState } from "react";

interface JournalEntryFormValues
  extends Omit<
    JournalEntryDetailsFragment,
    "entryLines" | "createdAt" | "updatedAt"
  > {
  entryLines: Array<
    DestroyableEntityType<
      JournalEntryLineFragment & {
        creditAmount?: number;
        debitAmount?: number;
        costCenterId?: string;
      }
    >
  >;
}

interface JournalEntryFormProps {
  disabled?: boolean;
  entry: JournalEntryFormValues;
  onSave?(values: JournalEntryFormValues): SaveIDFunction;
}

export function JournalEntryForm({
  entry,
  disabled,
  onSave,
}: JournalEntryFormProps) {
  const { currentTenant } = useCurrentUser();
  const [form] = Form.useForm();
  const [commentForm] = Form.useForm();
  const { importProps, loading } = useImportProps(form);

  const amountRule = (index: number) =>
    RuleBuilder.custom((form) => {
      const debit = form.getFieldValue(["entryLines", index, "debitAmount"]);
      const credit = form.getFieldValue(["entryLines", index, "creditAmount"]);

      if (!debit && !credit) {
        return <FormattedMessage id="form.validation.presence" />;
      }

      if (debit && credit) {
        return (
          <FormattedMessage
            id="journalEntries.validation"
            defaultMessage="Can be debit or credit"
          />
        );
      }
      return true;
    });

  return (
    <>
      <Form
        preventLeaving
        layout="vertical"
        initialValues={entry}
        form={form}
        onSubmit={(values, { setSubmitting, showErrors }) => {
          if (!onSave) return;

          const promise = onSave(values as JournalEntryFormValues);
          if (!promise) return;

          promise.then((entry) => {
            if (!entry) return;

            if (entry.result) {
              commentForm.setFieldsValue({ commentableId: entry.result.id });
              commentForm.submit();

              routerPush(
                entry.result.id,
                routes.finance.journalEntries.details
              );
            } else if (entry.errors) {
              showErrors(entry.errors);
              setSubmitting(false);
            }
          });
        }}
      >
        {!disabled && (
          <>
            <Typography.Title level={4}>
              <FormattedMessage id="basicInfo" defaultMessage="basicInfo" />
            </Typography.Title>
            <Row gutter={32}>
              <Col xs={24} sm={8} md={6}>
                <DocumentDateFormInput disabled={disabled} />
              </Col>
              <Col xs={24} sm={8} md={6}>
                <Form.Item
                  name="internalId"
                  label={
                    <FormattedMessage
                      id="internalId"
                      defaultMessage="internalId"
                    />
                  }
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>

            <Divider dashed />
          </>
        )}

        <Typography.Title level={4}>
          <FormattedMessage
            id="journalEntries.accountingEntries"
            defaultMessage="accountingEntries"
          />
        </Typography.Title>

        <Form.Item
          name="entryLines"
          style={{ flexWrap: "initial" }}
          rules={[Rules.required]}
        >
          <TableInput
            name="entryLines"
            dataSource={entry.entryLines}
            disabled={disabled}
            rowKey={(e) =>
              [e.account.id, e.costCenterId, e.profitableId].join("-")
            }
            tableSelectProps={{
              render: () => (
                <Form.Item name="newAccounts" style={{ width: "100%" }}>
                  <AccountSelect
                    mode="ledger"
                    multiple
                    labelInValue
                    placeholder={
                      <FormattedMessage
                        id="select.account"
                        defaultMessage="accounts"
                      />
                    }
                  />
                </Form.Item>
              ),
              onAdd: () => {
                const accounts = form.getFieldValue("newAccounts");
                if (!accounts) return;

                const entryLines = form.getFieldValue("entryLines");

                const newAccounts = accounts
                  .filter(
                    (a: any) =>
                      !entryLines.find((l: any) => l.account.id === a.value)
                  )
                  .map((a: any) => ({
                    account: { id: a.value, name: a.label },
                  }));

                if (newAccounts.length === 0) return;

                form.setFields([
                  {
                    name: "entryLines",
                    value: [...entryLines, ...newAccounts],
                  },
                  {
                    name: "newAccounts",
                    value: [],
                  },
                ]);
              },
            }}
            tableProps={{
              summary: (accounts) =>
                accounts.length > 0 && (
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const accs = accounts.filter(Form.undestroyed);
                      const debits = roundUnit(
                        sumBy(accs, (a) => a.debitAmount || 0)
                      );
                      const credits = roundUnit(
                        sumBy(accs, (a) => a.creditAmount || 0)
                      );
                      const textType = debits != credits ? "danger" : undefined;

                      return (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3}>
                            <strong>
                              <FormattedMessage id="total" />
                            </strong>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            <Typography.Text strong type={textType}>
                              {formatMoneyAmount(
                                debits,
                                currentTenant.currencyCode
                              )}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>
                            <Typography.Text strong type={textType}>
                              {formatMoneyAmount(
                                credits,
                                currentTenant.currencyCode
                              )}
                            </Typography.Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={3}
                            colSpan={2}
                          ></Table.Summary.Cell>
                        </Table.Summary.Row>
                      );
                    }}
                  </Form.Item>
                ),
              loading,
            }}
            importProps={importProps}
            columns={[
              {
                dataIndex: "id",
                width: 50,
                render: (_, _e, index) => index + 1,
              },
              {
                title: (
                  <FormattedMessage
                    id="accounts.entityName"
                    defaultMessage="account"
                  />
                ),
                dataIndex: ["account", "name"],
                render: (_, l) =>
                  formatDetailsLink({
                    id: l.account.id,
                    route: routes.finance.accounts.details,
                    title: formatAccountName(l.account),
                  }),
              },
              {
                title: (
                  <FormattedMessage
                    id="description"
                    defaultMessage="description"
                  />
                ),
                dataIndex: "description",
                render: (_, l, index) => {
                  if (disabled) return l.description;

                  return (
                    <Form.Item
                      name={["entryLines", index, "description"]}
                      compact
                    >
                      <Input />
                    </Form.Item>
                  );
                },
              },
              {
                title: <FormattedMessage id="debit" defaultMessage="debit" />,
                width: 190,
                render: (_, l, index) => {
                  if (disabled)
                    return (
                      l.amount &&
                      l.debit &&
                      formatMoneyAmount(l.amount, currentTenant.currencyCode)
                    );

                  return (
                    <Form.Item
                      name={["entryLines", index, "debitAmount"]}
                      rules={[amountRule(index)]}
                      compact
                    >
                      <InputNumber
                        step={0.01}
                        addonBefore={currentTenant.currency.symbol}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: <FormattedMessage id="credit" defaultMessage="credit" />,
                width: 190,
                render: (_, l, index) => {
                  if (disabled)
                    return (
                      l.amount &&
                      !l.debit &&
                      formatMoneyAmount(l.amount, currentTenant.currencyCode)
                    );

                  return (
                    <Form.Item
                      name={["entryLines", index, "creditAmount"]}
                      rules={[amountRule(index)]}
                      compact
                    >
                      <InputNumber
                        step={0.01}
                        addonBefore={currentTenant.currency.symbol}
                      />
                    </Form.Item>
                  );
                },
              },
              {
                title: <FormattedMessage id="costCenters.entityName" />,
                width: 300,
                render: (_, l, index) => {
                  if (disabled)
                    return formatCostCenterName(l.costCenter, l.profitableName);

                  return (
                    <CostCenterProfitableSelect
                      name={["entryLines", index]}
                      required={false}
                    />
                  );
                },
              },
            ]}
          />
        </Form.Item>

        {!disabled && (
          <BottomPanel
            sticky
            buttons={[
              BottomPanel.CancelButton({
                route: routes.finance.journalEntries.index,
              }),
              BottomPanel.SubmitButton(),
            ]}
          />
        )}
      </Form>

      <CommentList
        form={commentForm}
        commentableType={CommentableType.FinanceJournalEntry}
        commentableId={entry.id}
      />
    </>
  );
}

function useImportProps(form: FormInstance): {
  loading: boolean;
  importProps: TableInputProps<any>["importProps"];
} {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const headers = {
    id: intl.formatMessage({ id: "id" }),
    accountNumber: intl.formatMessage({ id: "accounts.accountNumber" }),
    account: intl.formatMessage({ id: "accounts.entityName" }),
    description: intl.formatMessage({ id: "description" }),
    debit: intl.formatMessage({ id: "debit" }),
    credit: intl.formatMessage({ id: "credit" }),
  };

  return {
    loading,
    importProps: {
      templateUrl: exportUrl(
        {
          export: "Finance::Templates::JournalEntries",
          headers,
        },
        "xlsx"
      ),
      headers: {},
      onUpload(data) {
        setLoading(true);

        if (data) {
          const formLines = form.getFieldValue(
            "entryLines"
          ) as DestroyableEntityType<JournalEntryLineFragment>[];

          const newLines = data
            .filter(
              (d) =>
                d[headers.id] && (d[headers.debit] > 0 || d[headers.credit] > 0)
            )
            .map((d) => {
              return {
                id: "",
                account: { id: d[headers.id], name: d[headers.account] },
                description: d[headers.description],
                debitAmount: d[headers.debit] > 0 ? d[headers.debit] : null,
                creditAmount: d[headers.credit] > 0 ? d[headers.credit] : null,
                costCenterId: "",
                profitableId: "",
              };
            });

          formLines.forEach((formLine) => {
            const newLine = newLines.find(
              (l) => l.account.id == formLine.account.id
            );
            if (newLine) {
              newLine.id = formLine.id;
            } else {
              formLine._destroy = true;
              newLines.push(formLine as any);
            }
          });

          form.setFieldValue("entryLines", newLines);
          form.validateFields();
        }

        setLoading(false);
      },
    },
  };
}
