import React, { ReactNode, useState } from "react";
import {
  MutationProps,
  DiscardableEntityType,
  DestroyMutationVariables,
  SaveMutationResult,
} from "../../../lib/graphql";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ConfirmSidebar } from "../Sidebar";
import { formatEntityWithName, rtfValues } from "../../../lib/formats";
import { useEffectOnce } from "react-use";
import { Spin } from "antd";

interface DiscardItemConfig<T, TMutation, TMutationVariables>
  extends MutationProps<TMutation, TMutationVariables, SaveMutationResult<T>> {
  entityName: string;
  item: T;
  onCancel(): void;
  onSuccess(): void;
  dryRunMessage?: ReactNode;
}

const messages = defineMessages({
  discard: { id: "discard.header", defaultMessage: "discard" },
  undiscard: { id: "undiscard.header", defaultMessage: "undiscard" },
  discardSuccess: { id: "discard.success", defaultMessage: "success" },
  undiscardSuccess: {
    id: "undiscard.success",
    defaultMessage: "success",
  },
});

export function DiscardItem<T extends DiscardableEntityType, TDiscardMutation>({
  item,
  entityName,
  mutation,
  onMutate,
  mutationOptions,
  onCancel,
  onSuccess,
  dryRunMessage,
}: DiscardItemConfig<T, TDiscardMutation, DestroyMutationVariables>) {
  const intl = useIntl();
  const entityWithName = formatEntityWithName(entityName, item);
  const [mayDiscard, setMayDiscard] = useState(dryRunMessage ? false : true);

  const [discard, { loading }] = mutation(mutationOptions);

  useEffectOnce(() => {
    if (dryRunMessage) {
      discard({ variables: { id: item.id, dryRun: true } }).then((result) => {
        if (!result.data) return;

        setMayDiscard(onMutate(result.data)?.errors.length == 0);
      });
    }
  });

  return (
    <ConfirmSidebar
      title={intl.formatMessage(
        item.discardedAt ? messages.undiscard : messages.discard,
        { entityName }
      )}
      onCancel={onCancel}
      onOk={({ setLoading, showErrors, showSuccess }) => {
        if (!mayDiscard) return;

        discard({ variables: { id: item.id, dryRun: false } })
          .then((result) => {
            if (!result.data) return;

            return onMutate && onMutate(result?.data);
          })
          .then((result) => {
            if (result && result.result) {
              showSuccess(
                intl.formatMessage(
                  item.discardedAt
                    ? messages.undiscardSuccess
                    : messages.discardSuccess,
                  {
                    entityName: entityWithName,
                    ...rtfValues,
                  }
                )
              );
              onSuccess();
            } else if (result) {
              showErrors(result.errors);
              setLoading(false);
            }
          });
      }}
      okButtonProps={{ danger: !item.discardedAt, disabled: !mayDiscard }}
      okText={
        item.discardedAt ? (
          <FormattedMessage id="undiscard" defaultMessage="undiscard" />
        ) : (
          <FormattedMessage id="discard" defaultMessage="discard" />
        )
      }
    >
      {mayDiscard ? (
        item.discardedAt ? (
          <FormattedMessage
            id="undiscard.confirm"
            defaultMessage="confirm"
            values={{ entityName: entityWithName, ...rtfValues }}
          />
        ) : (
          <FormattedMessage
            id="discard.confirm"
            defaultMessage="confirm"
            values={{ entityName: entityWithName, ...rtfValues }}
          />
        )
      ) : loading ? (
        <Spin />
      ) : (
        dryRunMessage
      )}
    </ConfirmSidebar>
  );
}
