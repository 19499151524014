import {
  useQuery,
  QueryHookOptions,
  useMutation,
  MutationHookOptions,
  useLazyQuery,
} from "@apollo/client";
import { merge } from "lodash";

import {
  CropCyclesDocument,
  CropCyclesQuery,
  CropCyclesQueryVariables,
  CropCycleUpdateMutation,
  CropCycleUpdateMutationVariables,
  CropCycleUpdateDocument,
  CropCycleCreateMutation,
  CropCycleCreateMutationVariables,
  CropCycleCreateDocument,
  CropCycleDiscardMutation,
  CropCycleDiscardMutationVariables,
  CropCycleDiscardDocument,
  CropCycleDocument,
  CropCycleQuery,
  CropCycleQueryVariables,
  SortDirection,
  CropCycleDestroyMutation,
  CropCycleDestroyMutationVariables,
  CropCycleDestroyDocument,
  CropCycleCloseMutation,
  CropCycleCloseMutationVariables,
  CropCycleCloseDocument,
  CropCycleReopenMutation,
  CropCycleReopenMutationVariables,
  CropCycleReopenDocument,
} from "../../graphql";
import { formatCropCycleCrops } from "../../formats";
import { filterFalse } from "../../utils";

export function useCropCycleOptions(
  queryOptions?: QueryHookOptions<CropCyclesQuery, CropCyclesQueryVariables> & {
    hideLocality?: boolean;
  }
) {
  const { hideLocality, ...restOptions } = queryOptions || {};
  const [load, { loading, data }] = useLazyQuery(
    CropCyclesDocument,
    merge(
      { variables: { sort: { startedAt: SortDirection.Desc } } },
      restOptions
    )
  );

  const options = data?.cropCycles?.items?.map((c) => ({
    key: c.id,
    label: filterFalse([c.name, !hideLocality && c.locality.name]).join(" - "),
    group: formatCropCycleCrops(c),
    cropCycle: c,
  }));

  return {
    load,
    loading,
    options,
  };
}

export function useCropCycles(
  options?: QueryHookOptions<CropCyclesQuery, CropCyclesQueryVariables>
) {
  const { loading, data, refetch } = useQuery(CropCyclesDocument, options);

  if (!data || !data.cropCycles || !data.cropCycles.items) {
    return { loading };
  }

  const items = data.cropCycles.items;
  const totalCount = data.cropCycles.totalCount;

  return { loading, items, totalCount, refetch };
}

export function useCropCycle(id: string, withHarvestEstimate = false) {
  const skip = !id;

  const { data } = useQuery<CropCycleQuery, CropCycleQueryVariables>(
    CropCycleDocument,
    {
      variables: { id, withHarvestEstimate },
      // TODO: Workaround for a known bug in Apollo client that causes `skip` to be ignored
      // https://github.com/apollographql/react-apollo/issues/3492#issuecomment-622573677
      fetchPolicy: skip ? "cache-only" : "cache-first",
      skip,
    }
  );

  return data?.cropCycle;
}

export function useCropCycleUpdate(
  options?: MutationHookOptions<
    CropCycleUpdateMutation,
    CropCycleUpdateMutationVariables
  >
) {
  return useMutation(CropCycleUpdateDocument, options);
}

export function useCropCycleCreate(
  options?: MutationHookOptions<
    CropCycleCreateMutation,
    CropCycleCreateMutationVariables
  >
) {
  return useMutation(CropCycleCreateDocument, options);
}

export function useCropCycleDiscard(
  options?: MutationHookOptions<
    CropCycleDiscardMutation,
    CropCycleDiscardMutationVariables
  >
) {
  return useMutation(CropCycleDiscardDocument, options);
}

export function useCropCycleDestroy(
  options?: MutationHookOptions<
    CropCycleDestroyMutation,
    CropCycleDestroyMutationVariables
  >
) {
  return useMutation(CropCycleDestroyDocument, options);
}

export function useCropCycleClose(
  options?: MutationHookOptions<
    CropCycleCloseMutation,
    CropCycleCloseMutationVariables
  >
) {
  return useMutation(CropCycleCloseDocument, options);
}

export function useCropCycleReopen(
  options?: MutationHookOptions<
    CropCycleReopenMutation,
    CropCycleReopenMutationVariables
  >
) {
  return useMutation(CropCycleReopenDocument, options);
}
