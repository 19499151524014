import {
  ActivityDetailsFragment,
  MetricFields,
  evictCache,
} from "../../../lib/graphql";
import { ItemSidebar, NewForm } from "../../shared";
import {
  useMetricCreate,
  useTranslate,
  useUnitOptions,
} from "../../../lib/hooks";
import { FormattedMessage } from "react-intl";
import { Rules } from "../../form";

export function ActivityFormSidebar({
  activity,
}: {
  activity?: ActivityDetailsFragment;
}) {
  const translate = useTranslate();
  const { unitOptions } = useUnitOptions();

  return (
    <ItemSidebar
      item={activity}
      sidebarActions={{
        newMetric: ({ closeSidebar }) => (
          <NewForm
            entityName={translate("metrics.entityName")}
            mutation={useMetricCreate}
            onMutate={(result) => result.metricCreate}
            mutationOptions={{ update: evictCache("metrics") }}
            formValues={() => ({} as MetricFields)}
            onClose={closeSidebar}
            fields={[
              {
                name: "name",
                type: "string",
                rules: [Rules.required],
              },
              {
                label: <FormattedMessage id="units.entityName" />,
                name: "unitId",
                type: "select",
                options: unitOptions,
                showGroups: true,
                rules: [Rules.required],
              },
              {
                label: <FormattedMessage id="description" />,
                name: "description",
                type: "text",
              },
            ]}
          />
        ),
      }}
    />
  );
}
