import { Button, Typography, Divider, Table } from "antd";
import { useContext } from "react";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import {
  shouldUpdate,
  formatUnitValue,
  formatPercent,
} from "../../../../lib/formats";
import { Rules, RuleBuilder, Form, InputNumber } from "../../../form";
import { ItemSidebarContext } from "../../../shared";
import { WorkOrderContext } from "../WorkOrderContext";
import { CostCenterTableInput } from "./CostCenterTableInput";
import { WorkOrderCostCentersProps } from "./WorkOrderCostCenters";
import { ActivityMetricScope } from "../../../../lib/graphql";

const messages = defineMessages({
  progressEmployee: {
    id: "workOrders.progressEmployee",
    defaultMessage: "Total Progress per Employee",
  },
  progressMachine: {
    id: "workOrders.progressMachine",
    defaultMessage: "Progress per Machine per Crop Field",
  },
});

export function DistributeButton() {}

export function DistributeProgress({
  tableProps,
  tableSelectProps,
  columns,
  byPercentage,
  ...props
}: WorkOrderCostCentersProps) {
  const intl = useIntl();
  const { workOrder, builder } = useContext(WorkOrderContext);
  const { setCurrentAction } = useContext(ItemSidebarContext);

  const allColumns = [...columns];

  allColumns.push({
    title: <FormattedMessage id="totalProgress" />,
    width: "14rem",
    render: (_, _cc, index) =>
      builder.isReadonly || byPercentage ? (
        <Form.Item noStyle shouldUpdate={shouldUpdate(["costCenters", index])}>
          {() =>
            formatUnitValue(
              builder.costCenters.getBy(index).totalProgress || 0,
              workOrder.activity.progressUnit
            )
          }
        </Form.Item>
      ) : (
        <Form.Item
          compact
          name={builder.costCenters.fieldName(index, "totalProgress")}
          rules={[Rules.gtEqZero]}
        >
          <InputNumber
            step={0.1}
            min={0}
            addonBefore={
              builder.progressByGroup && builder.employees.hasItems ? (
                <Button
                  title={intl.formatMessage(
                    builder.isMachinery
                      ? messages.progressMachine
                      : messages.progressEmployee
                  )}
                  type="link"
                  size="small"
                  style={{ padding: 0 }}
                  onClick={() => {
                    setCurrentAction("distributeMetric", { ccIndex: index });
                  }}
                >
                  {builder.isMachinery ? "🚜" : "👨🏻‍🌾"}
                </Button>
              ) : undefined
            }
            addonAfter={workOrder.activity.progressUnit.abbr}
            onChange={(val) =>
              builder.costCenters.debouncedOnTotalProgressChanged(index, val)
            }
          />
        </Form.Item>
      ),
  });

  if (builder.distributeProgress) {
    allColumns.push({
      title: <FormattedMessage id="percent" defaultMessage="percent" />,
      width: 150,
      render: (_, _cc, index) =>
        builder.isReadonly || builder.outputs.distributed || !byPercentage ? (
          <Form.Item
            noStyle
            shouldUpdate={shouldUpdate([
              "costCenters",
              index,
              "progressPercent",
            ])}
          >
            {({ getFieldValue }) =>
              formatPercent(
                getFieldValue(
                  builder.costCenters.fieldName(index, "progressPercent")
                ) / 100.0
              )
            }
          </Form.Item>
        ) : (
          <Form.Item
            name={builder.costCenters.fieldName(index, "progressPercent")}
            compact
          >
            <InputNumber
              step={1}
              min={0}
              precision={2}
              addonAfter="%"
              onChange={() =>
                builder.costCenters.recalculateCropFieldTotalProgress()
              }
            />
          </Form.Item>
        ),
    });
  }

  return (
    <>
      <DistributeProgressStatus progressByPercentage={byPercentage} />

      <CostCenterTableInput
        tableProps={{
          ...tableProps,
          summary: () => <ProgressSummary columns={columns.length} />,
        }}
        columns={allColumns}
        tableSelectProps={{
          afterAdd: () => builder.costCenters.onAdd(),
          ...tableSelectProps,
        }}
        {...props}
      />
    </>
  );
}

function DistributeProgressStatus({
  progressByPercentage,
}: {
  progressByPercentage?: boolean;
}) {
  const { workOrder, builder } = useContext(WorkOrderContext);

  if (builder.isReadonly || !builder.distributeProgress) return null;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate("costCenters")}>
      {() => {
        const toDistribute = builder.costCenters.getProgressToDistribute();
        const distributed = builder.costCenters.getTotalProgress();
        const totalPercent = builder.costCenters.getTotalPercentage();

        return (
          <Typography.Paragraph>
            <FormattedMessage id="totalProgress" />:{" "}
            <strong>
              {formatUnitValue(toDistribute, workOrder.activity.progressUnit)}
            </strong>
            <Divider type="vertical" />
            <FormattedMessage
              id="workOrders.leftToDistribute"
              defaultMessage="leftToDistribute"
            />
            :{" "}
            <Typography.Text
              strong
              type={toDistribute === distributed ? "success" : "danger"}
            >
              {!progressByPercentage
                ? formatUnitValue(
                    toDistribute - distributed,
                    workOrder.activity.progressUnit
                  )
                : formatPercent(1 - totalPercent)}
            </Typography.Text>
          </Typography.Paragraph>
        );
      }}
    </Form.Item>
  );
}

function ProgressSummary({ columns }: { columns: number }) {
  const { workOrder, builder } = useContext(WorkOrderContext);

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate("costCenters")}>
      {() => {
        const costCenters = builder.costCenters.get();
        if (!costCenters.length) return;

        const toDistribute = builder.costCenters.getProgressToDistribute();
        const distributed = builder.costCenters.getTotalProgress();
        const totalPercent = builder.costCenters.getTotalPercentage();

        return (
          <Table.Summary.Row>
            <Table.Summary.Cell
              index={0}
              colSpan={columns + (builder.isReadonly ? 1 : 2)}
            >
              <strong>
                <FormattedMessage id="total" />
              </strong>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              <Form.Item
                name="totalProgress"
                compact
                dependencies={[
                  ["costCenters", costCenters.length - 1, "totalProgress"],
                ]}
                rules={[
                  RuleBuilder.custom(() => {
                    if (toDistribute == distributed) return true;

                    return (
                      <FormattedMessage
                        id="form.validation.mustSumTo"
                        defaultMessage="mustSumTo"
                        values={{
                          max: toDistribute,
                          now: distributed,
                        }}
                      />
                    );
                  }),
                ]}
              >
                <Typography.Text
                  type={distributed != toDistribute ? "danger" : undefined}
                >
                  {formatUnitValue(
                    distributed,
                    workOrder.activity.progressUnit
                  )}
                </Typography.Text>
              </Form.Item>
            </Table.Summary.Cell>

            {builder.progressByIndividual && (
              <Table.Summary.Cell index={1}>
                <Typography.Text
                  type={
                    toDistribute > 0 && totalPercent !== 1
                      ? "danger"
                      : undefined
                  }
                >
                  {formatPercent(totalPercent)}
                </Typography.Text>
              </Table.Summary.Cell>
            )}
            {workOrder.activity.activityMetrics
              .filter((m) => m.scope == ActivityMetricScope.CostCenter)
              .map((m) => (
                <Table.Summary.Cell index={1} key={m.id}>
                  {builder.isReadonly ? (
                    formatUnitValue(
                      builder.costCenters.metrics.getTotalMetric(m.metric.id),
                      m.metric.unit
                    )
                  ) : (
                    <InputNumber
                      step={0.1}
                      min={0}
                      value={builder.costCenters.metrics.getTotalMetric(
                        m.metric.id
                      )}
                      addonAfter={m.metric.unit.abbr}
                      onChange={(value) =>
                        builder.costCenters.metrics.distributeMetric(
                          m.metric.id,
                          value
                        )
                      }
                    />
                  )}
                </Table.Summary.Cell>
              ))}
            {!builder.isReadonly && <Table.Summary.Cell index={1} />}
          </Table.Summary.Row>
        );
      }}
    </Form.Item>
  );
}
