import { WithIDType, Scalars, Maybe } from "../../graphql";

interface WithParentIDType extends WithIDType {
  parentId?: Maybe<Scalars["ID"]>;
}

export type Tree<T> = T & { children?: Array<Tree<T>> };

export function buildTree<T extends WithParentIDType>(items?: Array<T>) {
  if (!items) return [];

  const records: Record<string, Tree<T>> = {};
  items.forEach((record) => (records[record.id] = { ...record }));

  return Object.values(records).reduce((tree, record) => {
    if (!record.parentId) {
      tree.push(record);
    } else {
      if (!records[record.parentId].children)
        records[record.parentId].children = [];
      records[record.parentId].children?.push(record);
    }
    return tree;
  }, [] as Array<Tree<T>>);
}
