import { ActionButton, StatusPanel } from "../../shared";
import { useContext } from "react";
import { WorkOrderContext } from "./WorkOrderContext";
import { InventoryStatus } from "../../../lib/graphql";
import { formatInventoryStatus, formatPdfPath } from "../../../lib/formats";
import { Divider, Space, Typography } from "antd";
import { FormattedMessage } from "react-intl";
import { usePermissions, useItemSidebarContext } from "../../../lib/hooks";
import {
  CheckOutlined,
  CopyOutlined,
  PlusOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import routes, { routerPush } from "../../../lib/routes";
import { blue } from "@ant-design/colors";
import { ItemType } from "antd/lib/menu/hooks/useItems";

export function WorkOrderHeader() {
  const { workOrder, builder, sections } = useContext(WorkOrderContext);
  const canRevert = usePermissions((p) => p.workOrder?.delete);
  const { setCurrentAction } = useItemSidebarContext();

  const actions: ItemType[] = [
    {
      key: "clone",
      icon: (
        <Typography.Text style={{ color: blue.primary }}>
          <CopyOutlined />
        </Typography.Text>
      ),
      onClick: () => setCurrentAction("clone"),
      label: (
        <Typography.Text style={{ color: blue.primary }}>
          <FormattedMessage
            id="cloneOrder"
            defaultMessage="Copy & Clone Order"
          />
        </Typography.Text>
      ),
    },
  ];

  if (builder.isScheduled || builder.isInProgress) {
    actions.splice(
      0,
      0,
      {
        key: "reopen",
        icon: <RedoOutlined />,
        onClick: () => setCurrentAction("reopen"),
        label: <FormattedMessage id="workOrders.reopen" />,
      },
      { type: "divider" }
    );
  }

  if (builder.canRevert) {
    if (canRevert) {
      actions.push(
        { type: "divider" },
        ActionButton.Revert(() => setCurrentAction("revert"))
      );
    }
  } else if (builder.canCancel) {
    actions.push(
      { type: "divider" },
      ActionButton.Cancel(() => setCurrentAction("cancel"))
    );
  }

  return (
    <StatusPanel
      status={workOrder.id ? workOrder.status : "new"}
      extra={
        workOrder.inventoryStatus !== InventoryStatus.NotRequested &&
        formatInventoryStatus(workOrder.inventoryStatus)
      }
    >
      {workOrder.id && (
        <Space wrap>
          {!builder.isReadonly && (
            <ActionButton
              disabled={builder.noActivity}
              menuItems={sections.map((s) => ({
                key: s.key,
                disabled: s.visible,
                onClick: () => builder.sections.onShowSection(s),
                label: s.name,
                itemIcon: s.visible && (
                  <CheckOutlined
                    style={{ color: "#08c", float: "right", margin: "5px" }}
                  />
                ),
              }))}
              title={
                <Space>
                  <PlusOutlined />
                  <span className="hide-xs">
                    <FormattedMessage
                      id="workOrders.addSection"
                      defaultMessage="addSection"
                    />
                  </span>
                </Space>
              }
            />
          )}

          <ActionButton.Button
            {...ActionButton.DownloadPDF({
              onClick: builder.isReadonly
                ? () => routerPush(formatPdfPath("work_order", workOrder.id))
                : () => setCurrentAction("pdf"),
              children: (
                <span className="hide-xs">
                  <FormattedMessage
                    id="workOrders.downloadTemplate"
                    defaultMessage="Download Field Report"
                  />
                </span>
              ),
            })}
          />

          <ActionButton.Button
            {...ActionButton.CopyLinkMenuItem(
              routes.agro.workOrders.details(workOrder.id)
            )}
          />

          <ActionButton.Button
            {...ActionButton.ChangeLog(() => setCurrentAction("changeLog"))}
          />

          <Divider type="vertical" />

          <ActionButton menuItems={actions} />
        </Space>
      )}
    </StatusPanel>
  );
}
