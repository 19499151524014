import { FormInstance, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { sumBy } from "lodash";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  AddButton,
  BLANK_SYMBOL,
  formatDate,
  formatDateFromNow,
  formatDetailsLink,
  formatPercent,
  formatUnitValue,
  roundUnit,
  rtfValues,
  shouldUpdate,
  tableAddEditDiscardColumn,
} from "../../../lib/formats";
import {
  CropFieldVarietyFields,
  CropFieldVarietyFragment,
  CropVarietyFields,
  evictCache,
} from "../../../lib/graphql";
import {
  useCropVarietyCreate,
  useCropVarietyOptions,
  useCurrentUser,
  useItemSidebarContext,
} from "../../../lib/hooks";
import routes from "../../../lib/routes";
import { Form, InputNumber, Rules } from "../../form";
import {
  ConfirmSidebar,
  ItemSidebar,
  NewForm,
  SidebarHeader,
  Space,
  Table,
} from "../../shared";
import { ItemForm } from "../../shared/ListView/ItemForm";

interface CropFieldVarietiesProps {
  varieties: CropFieldVarietyFragment[];
  readonly?: boolean;
  totalArea?: number;
}

export function CropFieldVarieties({
  varieties,
  readonly,
  totalArea,
}: CropFieldVarietiesProps) {
  const intl = useIntl();
  const { currentLocality } = useCurrentUser();
  const plantsAbbr = intl.formatMessage({ id: "plants.abbr" });
  const areaAbbr = currentLocality?.areaUnit.abbr || "";
  const { setCurrentAction } = useItemSidebarContext();
  const [currentVariety, setCurrentVariety] =
    useState<CropFieldVarietyFragment>();
  const entityName = intl.formatMessage({ id: "cropVarieties.entityName" });

  const plantDensityRender = () => (
    <InputNumber
      key="plantDensity"
      min={0}
      max={99999999}
      precision={0}
      addonAfter={`${plantsAbbr} x ${areaAbbr}`}
    />
  );

  const effectiveAreaRender = () => (
    <Form.Item
      key="effectiveArea"
      label={<FormattedMessage id="cropFields.effectiveArea" />}
      shouldUpdate={shouldUpdate("plantDensity", "effectivePlants")}
    >
      {({ getFieldValue }) => {
        const plantDensity = getFieldValue("plantDensity");
        const effectivePlants = getFieldValue("effectivePlants");

        return formatUnitValue(effectivePlants / plantDensity || 0, {
          abbr: areaAbbr,
        });
      }}
    </Form.Item>
  );

  const estimatedPlantsRender = () => (
    <Form.Item
      key="estimatedPlants"
      label={<FormattedMessage id="cropFields.estimatedPlants" />}
      shouldUpdate={shouldUpdate("plantDensity")}
    >
      {({ getFieldValue }) => {
        if (!totalArea) {
          return BLANK_SYMBOL;
        }

        const plantDensity = getFieldValue("plantDensity");

        return formatUnitValue(roundUnit(plantDensity * totalArea, 1) || 0, {
          abbr: plantsAbbr,
        });
      }}
    </Form.Item>
  );

  const totalEffectiveArea = sumBy(
    varieties,
    (v) => v.effectivePlants / v.plantDensity
  );

  const columns: ColumnsType<CropFieldVarietyFragment> = [
    {
      title: <FormattedMessage id="cropVarieties.entityName" />,
      render: (_, v) =>
        formatDetailsLink({
          id: v.cropVariety.id,
          route: routes.agro.cropVarieties.details,
          title: v.cropVariety.name,
        }),
    },
    {
      title: <FormattedMessage id="cropFields.plantedAt" />,
      render: (_, v) => formatDate(v.plantedAt),
    },
    {
      title: <FormattedMessage id="cropFields.age" defaultMessage="Age" />,
      render: (_, v) => formatDateFromNow(v.plantedAt, true),
    },
    {
      title: <FormattedMessage id="cropFields.plantDensity" />,
      render: (_, v) =>
        formatUnitValue(v.plantDensity, { abbr: plantsAbbr }, areaAbbr),
    },
    {
      title: <FormattedMessage id="cropFields.effectivePlants" />,
      render: (_, v) =>
        formatUnitValue(v.effectivePlants, { abbr: plantsAbbr }),
    },
    {
      title: <FormattedMessage id="cropFields.effectiveArea" />,
      render: (_, v) => (
        <span>
          {formatUnitValue(v.effectivePlants / v.plantDensity, {
            abbr: areaAbbr,
          })}{" "}
          <Typography.Text type="secondary" style={{ fontSize: "75%" }}>
            {formatPercent(
              v.effectivePlants / v.plantDensity / totalEffectiveArea
            )}
          </Typography.Text>
        </span>
      ),
    },
  ];

  if (readonly) {
    return (
      <Table
        dataSource={varieties}
        rowKey={(v) => v.id}
        pagination={false}
        scroll={{ x: true }}
        columns={columns}
      />
    );
  }

  columns.push(
    tableAddEditDiscardColumn<CropFieldVarietyFragment>({
      onEdit: (v) => {
        setCurrentVariety(v);
        setCurrentAction("editVariety");
      },
      onDiscard: (v) => {
        setCurrentVariety(v);
        setCurrentAction("discardVariety");
      },
    })
  );

  const newItemProps = {
    entityName: <FormattedMessage id="cropVarieties.entityName" />,
    onClick: () => {
      setCurrentAction("newCropVariety");
    },
  };

  const onCropVarietyChange = (
    val: any,
    _: any,
    options: any,
    form: FormInstance
  ) => {
    const variety = options.find((o: any) => o.key == val);
    form.setFields([
      {
        name: "cropVariety",
        value: variety?.cropVariety,
      },
    ]);
  };

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate("cropId")}>
      {({ getFieldValue, setFields }) => {
        const cropId = getFieldValue("cropId");

        return (
          <Space vertical>
            <AddButton
              onNew={() => setCurrentAction("newVariety")}
              data-testid="crop-variety/new-btn"
            />

            <Table
              dataSource={varieties}
              rowKey={(v) => v.id || v.cropVariety.id + v.plantedAt}
              pagination={false}
              rowClassName={(v) => (v.discardedAt ? "row-disabled" : "")}
              columns={columns}
            />

            <ItemSidebar
              item={currentVariety}
              sidebarActions={{
                newVariety: ({ closeSidebar }) => (
                  <>
                    <SidebarHeader
                      title={intl.formatMessage(
                        { id: "new.header" },
                        { entityName }
                      )}
                      onClose={closeSidebar}
                    />
                    <ItemForm
                      initialValues={{} as CropFieldVarietyFields}
                      fields={[
                        {
                          label: (
                            <FormattedMessage id="cropVarieties.entityName" />
                          ),
                          name: "cropVarietyId",
                          type: "select",
                          optionsHook: useCropVarietyOptions,
                          optionsHookParams: {
                            variables: { filter: { cropId } },
                          },
                          rules: [Rules.required],
                          onChange: onCropVarietyChange,
                          newItemProps,
                          helpTooltipTitle: (
                            <FormattedMessage
                              id="cropFieldVarieties.varietyHelp"
                              defaultMessage="varietyHelp"
                            />
                          ),
                        },
                        {
                          label: <FormattedMessage id="cropFields.plantedAt" />,
                          name: "plantedAt",
                          type: "date",
                          rules: [Rules.required],
                          helpTooltipTitle: (
                            <FormattedMessage
                              id="cropFieldVarieties.plantedAtHelp"
                              defaultMessage="plantedAtHelp"
                            />
                          ),
                        },
                        {
                          label: (
                            <FormattedMessage id="cropFields.plantDensity" />
                          ),
                          name: "plantDensity",
                          type: "custom",
                          rules: [Rules.gtZero],
                          render: plantDensityRender,
                          helpTooltipTitle: (
                            <FormattedMessage
                              id="cropFieldVarieties.plantDensityHelp"
                              defaultMessage="plantDensityHelp"
                            />
                          ),
                        },
                        {
                          label: (
                            <FormattedMessage id="cropFields.effectivePlants" />
                          ),
                          name: "effectivePlants",
                          type: "number",
                          rules: [Rules.gtZero, Rules.onlyIntegers],
                          numberProps: { addonAfter: plantsAbbr },
                          helpTooltipTitle: (
                            <FormattedMessage
                              id="cropFieldVarieties.effectivePlantsHelp"
                              defaultMessage="effectivePlantsHelp"
                            />
                          ),
                        },
                      ]}
                      onCancel={closeSidebar}
                      saveText={<FormattedMessage id="create" />}
                      onSave={(v, form) =>
                        Promise.resolve().then(() => {
                          setFields([
                            {
                              name: "cropFieldVarieties",
                              value: [...varieties, v],
                            },
                          ]);

                          closeSidebar();
                          form.resetFields();

                          return { result: v, errors: [] };
                        })
                      }
                    />
                  </>
                ),
                editVariety: ({ item, closeSidebar }) => (
                  <>
                    <SidebarHeader
                      title={intl.formatMessage(
                        { id: "edit.header" },
                        { entityName }
                      )}
                      onClose={closeSidebar}
                    />
                    <ItemForm
                      initialValues={{
                        cropVarietyId: item?.cropVariety.id,
                        plantDensity: item?.plantDensity,
                        effectivePlants: item?.effectivePlants,
                        plantedAt: item?.plantedAt,
                      }}
                      fields={[
                        {
                          label: (
                            <FormattedMessage id="cropVarieties.entityName" />
                          ),
                          name: "cropVarietyId",
                          type: "select",
                          optionsHook: useCropVarietyOptions,
                          optionsHookParams: {
                            variables: { filter: { cropId } },
                          },
                          rules: [Rules.required],
                          onChange: onCropVarietyChange,
                          newItemProps,
                        },
                        {
                          label: <FormattedMessage id="cropFields.plantedAt" />,
                          name: "plantedAt",
                          type: "date",
                          rules: [Rules.required],
                        },
                        {
                          label: (
                            <FormattedMessage id="cropFields.plantDensity" />
                          ),
                          name: "plantDensity",
                          type: "custom",
                          rules: [Rules.gtZero],
                          render: plantDensityRender,
                        },
                        {
                          label: (
                            <FormattedMessage id="cropFields.effectivePlants" />
                          ),
                          name: "effectivePlants",
                          type: "number",
                          rules: [Rules.gtZero, Rules.onlyIntegers],
                          numberProps: { addonAfter: plantsAbbr },
                        },
                        {
                          type: "custom",
                          render: estimatedPlantsRender,
                        },
                        {
                          type: "custom",
                          render: effectiveAreaRender,
                        },
                      ]}
                      onCancel={closeSidebar}
                      onSave={(v) =>
                        Promise.resolve().then(() => {
                          const index = varieties.findIndex((v) => v == item);

                          setFields([
                            {
                              name: ["cropFieldVarieties", index],
                              value: { ...item, ...v },
                            },
                          ]);

                          closeSidebar();

                          return { result: v, errors: [] };
                        })
                      }
                    />
                  </>
                ),
                discardVariety: ({ item: v, closeSidebar }) =>
                  v && (
                    <ConfirmSidebar
                      title={
                        v.discardedAt ? (
                          <FormattedMessage id="undiscard" />
                        ) : (
                          <FormattedMessage id="discard" />
                        )
                      }
                      onCancel={closeSidebar}
                      onOk={() => {
                        if (!v.id) {
                          setFields([
                            {
                              name: "cropFieldVarieties",
                              value: varieties.filter((iv) => iv != v),
                            },
                          ]);
                        } else {
                          const index = varieties.indexOf(v);
                          setFields([
                            {
                              name: [
                                "cropFieldVarieties",
                                index,
                                "discardedAt",
                              ],
                              value: v.discardedAt ? null : new Date(),
                            },
                          ]);
                        }

                        closeSidebar();
                      }}
                      okButtonProps={{
                        danger: !v.discardedAt,
                      }}
                      okText={
                        v.discardedAt ? (
                          <FormattedMessage id="undiscard" />
                        ) : (
                          <FormattedMessage id="discard" />
                        )
                      }
                    >
                      {v.discardedAt ? (
                        <FormattedMessage
                          id="undiscard.confirm"
                          values={{
                            entityName: entityName,
                            ...rtfValues,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id="discard.confirm"
                          values={{
                            entityName: entityName,
                            ...rtfValues,
                          }}
                        />
                      )}
                    </ConfirmSidebar>
                  ),
                newCropVariety: ({ closeSidebar }) => (
                  <NewForm
                    entityName={entityName}
                    mutation={useCropVarietyCreate}
                    onMutate={(result) => result.cropVarietyCreate}
                    mutationOptions={{ update: evictCache("cropVarieties") }}
                    formValues={() => ({ cropId } as CropVarietyFields)}
                    onClose={closeSidebar}
                    fields={[
                      {
                        name: "name",
                        type: "string",
                        rules: [Rules.required],
                        helpTooltipTitle: (
                          <FormattedMessage
                            id="cropVariety.nameHelp"
                            defaultMessage="nameHelp"
                          />
                        ),
                      },
                      {
                        label: (
                          <FormattedMessage id="cropVarieties.scientificName" />
                        ),
                        name: "scientificName",
                        type: "string",
                        helpTooltipTitle: (
                          <FormattedMessage
                            id="cropVariety.scientificNameHelp"
                            defaultMessage="scientificNameHelp"
                          />
                        ),
                      },
                    ]}
                  />
                ),
              }}
            />
          </Space>
        );
      }}
    </Form.Item>
  );
}
