import React, { ReactNode, useState } from "react";
import {
  FormattedMessage,
  FormattedPlural,
  defineMessages,
  useIntl,
} from "react-intl";
import { ConfirmSidebar, ConfirmSidebarProps } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Spin, Typography } from "antd";
import { rtfValues } from "../../../lib/formats";
import { useBulkDestroy } from "../../../lib/hooks/basic/bulkMutations";
import {
  DestroyableType,
  BulkOperationResult,
  DiscardableEntityType,
} from "../../../lib/graphql";
import { groupBy } from "lodash";

export interface BulkDestroyProps<T> {
  entityName: string;
  pluralEntityName: string;
  destroyableType: DestroyableType;
  items: T[];
  mutationOptions?: Parameters<typeof useBulkDestroy>[0];
  labelKey?: keyof T | ((item: T) => ReactNode);
  onCancel(): void;
  onSuccess(): void;
}

const messages = defineMessages({
  destroy: { id: "destroy.header", defaultMessage: "destroy" },
  destroySuccess: { id: "destroy.success", defaultMessage: "success" },
});

export function BulkDestroy<T extends DiscardableEntityType>({
  entityName,
  pluralEntityName,
  items,
  destroyableType,
  mutationOptions,
  labelKey,
  onCancel,
  onSuccess,
}: BulkDestroyProps<T>) {
  const intl = useIntl();
  const [mutationResult, setMutationResult] = useState<BulkOperationResult[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const [destroy] = useBulkDestroy({
    ignoreResults: true,
    ...mutationOptions,
  });

  const ids = items.map((i) => i.id);

  useEffectOnce(() => {
    destroy({
      variables: {
        destroyableType,
        ids,
        dryRun: true,
      },
    }).then((result) => {
      if (!result.data) return;

      setLoading(false);

      setMutationResult(result.data.bulkDestroy?.result || []);
    });
  });

  const groupedResults = groupBy(mutationResult, "result");

  const canDestroy = !!groupedResults["true"];

  const onDestroy: ConfirmSidebarProps["onOk"] = ({ showSuccess }) => {
    if (!canDestroy) return;

    setLoading(true);
    destroy({
      variables: {
        ids: groupedResults["true"].map((r) => r.id),
        destroyableType,
      },
    })
      .then((result) => {
        setLoading(false);
        if (!result.data) return;

        return result.data.bulkDestroy;
      })
      .then(() => {
        showSuccess(
          intl.formatMessage(messages.destroySuccess, {
            entityName,
            ...rtfValues,
          })
        );

        onSuccess();
      });
  };

  const labelFormatter = (i: T) =>
    labelKey
      ? typeof labelKey === "function"
        ? labelKey(i)
        : (i[labelKey] as string)
      : i.id;

  return (
    <ConfirmSidebar
      title={intl.formatMessage(messages.destroy, {
        entityName: pluralEntityName,
      })}
      onCancel={onCancel}
      okButtonProps={{
        danger: true,
        loading,
        hidden: !canDestroy,
      }}
      okText={<FormattedMessage id="destroy" defaultMessage="destroy" />}
      onOk={onDestroy}
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          {canDestroy && (
            <>
              <Typography.Title level={5}>
                <FormattedMessage
                  id="destroy.bulk.listToDestroy"
                  defaultMessage="Following items will be destroyed:"
                  values={{
                    entityName: (
                      <FormattedPlural
                        value={groupedResults["true"].length}
                        one={entityName}
                        other={pluralEntityName}
                      >
                        {(text: any) => text.toLowerCase()}
                      </FormattedPlural>
                    ),
                    count: groupedResults["true"].length,
                  }}
                />
              </Typography.Title>

              <ol style={{ paddingLeft: "1rem" }}>
                {groupedResults["true"].map((r) => {
                  const item = items.find((i) => i.id === r.id);
                  return (
                    <li key={r.id}>{item ? labelFormatter(item) : r.id}</li>
                  );
                })}
              </ol>
            </>
          )}

          {groupedResults["false"] && (
            <>
              {canDestroy && <hr />}

              <Typography.Title level={5}>
                <FormattedMessage
                  id="destroy.bulk.listCantDestroy"
                  defaultMessage="Following items couldn't be destroyed:"
                  values={{
                    entityName: (
                      <FormattedPlural
                        value={groupedResults["false"].length}
                        one={entityName}
                        other={pluralEntityName}
                      >
                        {(text: any) => text.toLowerCase()}
                      </FormattedPlural>
                    ),
                    count: groupedResults["false"].length,
                  }}
                />
              </Typography.Title>

              <ol style={{ paddingLeft: "1rem" }}>
                {groupedResults["false"].map((r) => {
                  const item = items.find((i) => i.id === r.id);
                  return (
                    <li key={r.id}>{item ? labelFormatter(item) : r.id}</li>
                  );
                })}
              </ol>
            </>
          )}
        </>
      )}
    </ConfirmSidebar>
  );
}
