import React from "react";
import {
  MutationProps,
  SaveMutationResult,
  LockableType,
  IDMutationVariables,
} from "../../../lib/graphql";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ConfirmSidebar } from "../Sidebar";

interface LockItemConfig<T, TMutation, TMutationVariables>
  extends MutationProps<TMutation, TMutationVariables, SaveMutationResult<T>> {
  entityName: string;
  item: T;
  onCancel(): void;
  onSuccess(item: T): void;
}

const messages = defineMessages({
  lock: { id: "lock.header", defaultMessage: "lock" },
  unlock: { id: "unlock.header", defaultMessage: "unlock" },
});

export function LockItem<T extends LockableType, TUpdateMutation>({
  item,
  entityName,
  mutation,
  onMutate,
  mutationOptions,
  onCancel,
  onSuccess,
}: LockItemConfig<T, TUpdateMutation, IDMutationVariables>) {
  const intl = useIntl();
  const [update] = mutation({ ignoreResults: true, ...mutationOptions });

  const onUpdate = () => {
    update({
      variables: { id: item.id },
    })
      .then((result) => {
        if (!result.data) return;

        return onMutate(result.data);
      })
      .then((result) => {
        if (result && result.result) {
          onSuccess(result.result);
        }
      });
  };

  return (
    <ConfirmSidebar
      title={intl.formatMessage(item.locked ? messages.unlock : messages.lock, {
        entityName,
      })}
      onCancel={onCancel}
      okText={
        item.locked ? (
          <FormattedMessage id="unlock" defaultMessage="unlock" />
        ) : (
          <FormattedMessage id="lock" defaultMessage="lock" />
        )
      }
      onOk={onUpdate}
    >
      {item.locked ? (
        <FormattedMessage id="unlock.confirm" defaultMessage="confirm" />
      ) : (
        <FormattedMessage id="lock.confirm" defaultMessage="confirm" />
      )}
    </ConfirmSidebar>
  );
}
