import { useQuery } from "@apollo/client";
import {
  AgroprimeExportsQuery,
  AgroprimeExportsQueryVariables,
  AgroprimeExportsDocument,
} from "../../graphql";

export function useAgroprimeExports(
  kind: "input" | "machine",
  localityId?: string
) {
  const { data, refetch } = useQuery<
    AgroprimeExportsQuery,
    AgroprimeExportsQueryVariables
  >(AgroprimeExportsDocument);

  return {
    lastExport: data?.agroprimeExports?.items?.find(
      (i) => i.kind === kind && i.locality.id === localityId
    ),
    refetchExport: refetch,
  };
}
